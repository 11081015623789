import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { clearStorage, getUserId } from '../utils/Storage';
import { toast } from 'react-toastify';
import { getSingleUser } from '../api/user';
import { acceptFollower, getRequestFollowersList } from '../api/follow';
import { getFilterNotificationList } from '../api/notification';
import { timeCal } from '../utils/dateformat';
import { s3Url } from '../utils/FileUpload';

function Header(props) {
    const [user, setUser] = useState()
    const [friends, setFriends] = useState([]);
    const [notification, setNotification] = useState([])
    const [count,setCount]=useState('')

    const logout = () => {
        clearStorage();
        toast.success('You have logged out successfully.')
    }
    useEffect(() => {
        userDetails()
        getFriendRequest()
        getNotificationList()
    }, [])

    const userDetails = () => {
        const userId = getUserId()
        getSingleUser(userId).then(res => {
            setUser(res?.data?.result)
        }).catch(err => {
            console.log(err);
        })
    }

    const getFriendRequest = () => {
        const data = { id: getUserId() };
        getRequestFollowersList(data)
            .then((res) => {
                setFriends(res?.data?.result);
            })
            .catch((err) => {
                console.log(err);
            });
    };

    const acceptFrientRequest = (id) => {
        const data = { _id: id, isConnect: true };
        acceptFollower(data)
            .then((res) => {
                toast.success("Accepting the friend request successfully.");
                getFriendRequest();
            })
            .catch((err) => {
                console.log(err);
            });
    };

    const removeFrientRequest = (id) => {
        const data = { _id: id, isConnect: false };
        acceptFollower(data)
            .then((res) => {
                toast.success("The friend request was successfully removed.");
                getFriendRequest();
            })
            .catch((err) => {
                console.log(err);
            });
    };

    const getNotificationList = () => {
        const data = {
            limit: 5,
            toUser: getUserId()
        };
        getFilterNotificationList(data).then(res => {
            setNotification(res?.data?.result?.notificationList);
            setCount(res?.data?.result?.notificationCount)
        }).catch(err => console.log(err))
    }

    const handleSearch =(event)=>{
props.onChildValueChange(event?.target?.value);
    }

    return (
        <>

            <div className="navbar-area">
                <div className="main-navbar">
                    <nav className="navbar navbar-expand-lg navbar-light">
                        <Link to="/Home" className="navbar-brand d-flex align-items-center">
                            <img src="../../assets/images/logo.svg" alt="logo" />
                        </Link>

                        <div className="collapse navbar-collapse" id="navbarSupportedContent">
                            <div className="search-box m-auto">
                                <Link  to="/SearchPeople">
                                    <input type="text" onKeyUp={handleSearch} className="input-search" placeholder="Search Users..." />
                                    <button type="submit"><i className="ri-search-line"></i></button>
                                </Link>
                            </div>

                            <div className="others-options d-flex align-items-center">
                                <div className="option-item">
                                    <div className="dropdown friend-requests-nav-item">
                                        <Link className="dropdown-bs-toggle" role="button" data-bs-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                                            <div className="friend-requests-btn">
                                                <i className="feather-users"></i>
                                                {/* <span>{friends?.length}</span> */}
                                            </div>
                                        </Link>

                                        <div className="dropdown-menu">
                                            <div className="friend-requests-header d-flex justify-content-between align-items-center">
                                                <h3>Friend Requests</h3>
                                            </div>

                                            {friends?.length > 0 ? <div className="friend-requests-body" data-simplebar>
                                                {friends?.slice(0, 5).map((data, index) =>
                                                    <div key={index} className="item d-flex align-items-center">
                                                        <div className="figure">
                                                            <Link to={{
                                                                pathname: "/Profile",
                                                                search: `id=${data?.follower?._id}`,
                                                            }} ><img src={s3Url+data?.follower?.key} className="rounded-circle" alt="user" /></Link>
                                                        </div>

                                                        <div className="content d-flex justify-content-between align-items-center">
                                                            <div className="text">
                                                                <h4><Link to={{
                                                                    pathname: "/Profile",
                                                                    search: `id=${data?.follower?._id}`,
                                                                }} >{data?.follower?.userName}</Link></h4>
                                                                {/* <span>26 Friends</span> */}
                                                            </div>
                                                            <div className="btn-box d-flex align-items-center">
                                                                <button onClick={() => acceptFrientRequest(data?._id)} className="delete-btn d-inline-block me-2" data-bs-toggle="tooltip" data-bs-placement="top" title="Add Friend" type="button">
                                                                    <i className="material-icons-round">done</i></button>

                                                                <button onClick={() => removeFrientRequest(data?._id)} className="confirm-btn d-inline-block" data-bs-toggle="tooltip" data-bs-placement="top" title="Remove" type="button">
                                                                    <i className="material-icons-round">close</i></button>
                                                            </div>
                                                        </div>
                                                    </div>)}
                                                <div className="view-all-requests-btn">
                                                    <Link to={{ pathname: '/Profile', search: '?item=Friends' }} className="default-btn">View All Requests</Link>
                                                </div>
                                            </div> :
                                                <div className='form-text text-danger text-center mb-4'>No Friend Request</div>}

                                        </div>
                                    </div>
                                </div>

                                <div className="option-item">
                                    <div className="dropdown notifications-nav-item">
                                        <Link  className="dropdown-bs-toggle" role="button" data-bs-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                                            <div className="notifications-btn">
                                                <i className="feather-bell"></i>
                                                {/* <span>{count}</span> */}
                                            </div>
                                        </Link>

                                        <div className="dropdown-menu">
                                            <div className="notifications-header d-flex justify-content-between align-items-center">
                                                <h3>Notifications</h3>
                                            </div>
                                            <div className="notifications-body" {...(notification.length>0 ? { "data-simplebar": true } : {})}>
                                                {notification?.map((data,index)=>
                                                <div key={index} className="item d-flex justify-content-between align-items-center">
                                                    <div className="figure">
                                                        <Link to={{ pathname: '/Profile', search: `id=${data?.fromUser?._id}` }} ><img src={s3Url+data?.fromUser?.key} className="rounded-circle" alt="user" /></Link>
                                                    </div>
                                                    <div className="text">
                                                        <h4><Link  to={{ pathname: '/Profile', search: `id=${data?.fromUser?._id}` }}>{data?.fromUser?.userName}</Link></h4>
                                                        <span>{data?.description}</span>
                                                        <span className="main-color">{timeCal(data?.createdOn)}</span>
                                                    </div>
                                                </div>)}
                                                <div className="view-all-notifications-btn">
                                                    <Link to="/Notifications" className="default-btn">View All Notifications</Link>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                                <div className="option-item">
                                    <div className="dropdown profile-nav-item">
                                        <Link className="dropdown-bs-toggle" role="button" data-bs-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                                            <div className="menu-profile">
                                                <img src={s3Url+user?.key??`${s3Url}empty_profile.png`} className="rounded-circle" alt="user" />
                                                <span className="name">{user?.userName}</span>
                                                <span className="status-online"></span>
                                            </div>
                                        </Link>

                                        <div className="dropdown-menu">
                                            <div className="profile-header">
                                                <h3>{user?.userName}</h3>
                                                <Link><span className="__cf_email__" >{user?.userId}</span></Link>
                                            </div>
                                            <ul className="profile-body">
                                                <li><i className="flaticon-user"></i> <Link to="/Profile">My Profile</Link></li>
                                                {/* <li><i className="flaticon-settings"></i> <Link to="#">Setting</Link></li> */}
                                                <li><i className="material-icons-outlined me-1">add_moderator</i><Link target='_blank' to="/privacypolicy">Privacy Policy</Link></li>
                                                <li><i className="material-icons-outlined me-1">note_alt</i> <Link target='_blank' to="/termsandconditions">Terms and Conditions</Link></li>
                                                <li><i className="material-icons-outlined me-1">monetization_on</i> <Link target='_blank' to="/coinpolicy">Pixalive Coin Policy</Link></li>
                                                <li><i className="material-icons-outlined me-1">document_scanner</i> <Link target='_blank' to="/aboutus">About Us</Link></li>
                                                <li><i className="material-icons-outlined me-1">contact_phone</i> <Link target='_blank' to="/contactus">Contact Us</Link></li>
                                                <li><i className="material-icons-outlined me-1">payments</i> <Link target='_blank' to="/returnpolicy">Return, Cancellation, and Refund Policy </Link></li>
                                                {/* <li><i className="flaticon-information"></i> <Link to="#">Help & Support</Link></li> */}
                                                <li><i className="flaticon-logout"></i> <Link onClick={logout} to='/'>Logout</Link></li>
                                            </ul>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </nav>
                </div>
            </div>


            {/* <div className="modal fade" id="loginModal" tabIndex="-1" role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true">
                <div className="modal-dialog modal-dialog-centered" role="document">
                    <div className="modal-content">
                        <div className="login-form">
                            <button type="button" className="close" data-dismiss="modal" aria-label="Close">
                                <span className="material-icons-round">close</span>
                            </button>
                            <h2>Login</h2>
                            <form>
                                <div className="form-group">
                                    <label>Phone Number</label>
                                    <input type="text" placeholder='Enter phone number' className="form-control" />
                                </div>
                                <div className="form-group">
                                    <label>Password</label>
                                    <input type="password" placeholder='Enter Password' className="form-control" />
                                </div>
                                <div className="remember-me-wrap d-flex justify-content-between align-items-center">
                                    <p>
                                        <input type="checkbox" id="test2" />
                                        <label htmlFor="test2">Remember me</label>
                                    </p>
                                    <div className="lost-your-password-wrap">
                                        <Link data-toggle="modal" data-target="#Forgotpassword" className="lost-your-password">Forgot password ?</Link>
                                    </div>
                                </div>
                                <button type="submit" className="default-btn">Login</button>
                                <div className="signup-text-info">Not a member yet? <a href="#a"> Sign Up</a>.</div>

                            </form>
                        </div>

                    </div>
                </div>
            </div>
            <div className="modal fade" id="Forgotpassword" tabIndex="-1" role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true">
                <div className="modal-dialog modal-dialog-centered" role="document">
                    <div className="modal-content">
                        <div className="login-form">
                            <button type="button" className="close" data-dismiss="modal" aria-label="Close">
                                <span className="material-icons-round">close</span>
                            </button>
                            <h2>Login</h2>
                            <form>
                                <div className="form-group">
                                    <label>Password</label>
                                    <input type="password" placeholder='Enter Password' className="form-control" />
                                </div>
                                <div className="remember-me-wrap d-flex justify-content-between align-items-center">
                                    <p>
                                        <input type="checkbox" id="test1" />
                                        <label htmlFor="test1">Remember me</label>
                                    </p>
                                    <div className="lost-your-password-wrap">
                                        <a href="#" className="lost-your-password">Forgot password ?</a>
                                    </div>
                                </div>
                                <button type="submit" className="default-btn">Login</button>
                                <div className="signup-text-info">Not a member yet? <a href="#a"> Sign Up</a>.</div>

                            </form>
                        </div>

                    </div>
                </div>
            </div> */}



        </>
    );
}

export default Header;