import { useEffect, useState } from "react";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { isAuthenticated } from "../../utils/Auth";
import { getUserId, saveToken } from "../../utils/Storage";
import { getSingleUserForShare } from "../../api/user";
import { Checkbox, Dialog, DialogContent, DialogTitle, IconButton } from "@mui/material";
import { isValidPhone } from "../../utils/validation";
import { loginUser, resendOTP, sinupUser, verifyOTP } from "../../api/login";
import { toast } from "react-toastify";
import HeaderLogin from "../../components/HeaderLogin";
import SideBarLogin from "../../components/SideBarLogin";
import SideBarRightLogin from "../../components/SideBarRightLogin";
import { s3Url } from "../../utils/FileUpload";


function ProfileShare() {

    const navigate = useNavigate()
    const location = useLocation()
    const id = new URLSearchParams(location.search).get('id');
    const [user, setUser] = useState({})
    const initialState = {
        _id: "",
        phone: "",
        otp: "",
        userName: "",
    }
    const initialStateErrors = {
        phone: { required: false, valid: false },
        otp: { required: false },
        userName: { required: false }
    }
    const [open, setOpen] = useState(false);
    const [inputs, setInputs] = useState(initialState)
    const [errors, setErrors] = useState(initialStateErrors)
    const [submitted, setSubmitted] = useState(false);
    const [openOTP, setOpenOTP] = useState(false);
    const [openSinup, setOpenSinup] = useState(false);
    const [back, setBack] = useState(false);

    useEffect(() => {
        if (isAuthenticated()) { navigate(`/Profile?id=${id}`) }
        else { getProfileUserDetails() }
    }, [])


    const getProfileUserDetails = () => {
        getSingleUserForShare(id)
            .then((res) => {
                setUser(res?.data?.result)
            })
            .catch((err) => {
                console.log(err);
            });
    }


    const openPopup = (event) => {
        event?.preventDefault()
        setOpen(true);
        setBack(false);
        setSubmitted(false);
        setErrors(initialStateErrors);
    };
    const closePopup = () => {
        setOpen(false);
    };

    const openSinupPopup = () => {
        setOpenSinup(true);
        setSubmitted(false);
        setBack(true);
        closePopup();
        setErrors(initialStateErrors);
    };
    const closeSinupPopup = () => {
        setOpenSinup(false);
    };


    const openOTPPopup = () => {
        setOpenOTP(true);
        setSubmitted(false);
        const otp = { required: false }
        setErrors({ ...errors, otp });
    };
    const closeOTPPopup = () => {
        setOpenOTP(false);
    };

    const handleValidation = (data) => {
        let error = initialStateErrors;
        if (data.phone === "") {
            error.phone.required = true;
        }
        if (data.otp === "") {
            error.otp.required = true;
        }
        if (data.userName === "") {
            error.userName.required = true;
        }
        if (!isValidPhone(data.phone)) {
            error.phone.valid = true;
        }
        return error
    }

    const handleInput = (event) => {
        setInputs({ ...inputs, [event.target.name]: event.target.value });
        if (submitted) {
            const newError = handleValidation({ ...inputs, [event.target.name]: event.target.value })
            setErrors(newError)
        }
    }

    const handleLogin = (event) => {
        event.preventDefault();
        const newError = handleValidation(inputs)
        setErrors(newError)
        setSubmitted(true)
        const allInputsValid = !newError?.phone?.required && !newError?.phone?.valid
        if (allInputsValid) {
            const data = { phone: inputs?.phone }
            loginUser(data)
                .then((res) => {
                    const _id = res?.data?.result?._id
                    setInputs({ ...inputs, _id })
                    closePopup()
                    openOTPPopup()
                    toast.success(res?.data?.message);
                })
                .catch((err) => {
                    toast.error(err?.response?.data?.message);
                });
        }
    };

    const handleRegistration = (event) => {
        event.preventDefault();
        const newError = handleValidation(inputs)
        setErrors(newError)
        setSubmitted(true)
        const allInputsValid = !newError?.phone?.required && !newError?.phone?.valid && !newError?.userName?.required
        if (allInputsValid) {
            const data = { phone: inputs?.phone, userName: inputs?.userName }
            sinupUser(data).then(res => {
                const _id = res?.data?.result?._id
                setInputs({ ...inputs, _id })
                closeSinupPopup()
                openOTPPopup()
                toast.success(res?.data?.message);
            })
                .catch((err) => {
                    toast.error(err?.response?.data?.message);
                });
        }
    }

    const handleSubmit = (event) => {
        event.preventDefault();
        const newError = handleValidation(inputs)
        setErrors(newError)
        setSubmitted(true)
        if (!newError?.otp?.required) {
            const data = { otp: inputs?.otp, _id: inputs?._id }
            verifyOTP(data)
                .then((res) => {
                    closeOTPPopup()
                    let token = res?.data?.result?.token;
                    let userId = res?.data?.result?._id;
                    let category = res?.data?.result?.category;
                    let data = {
                        token: token, _id: userId, category: category
                    }
                    saveToken(data);
                    if (isAuthenticated()) {
                        navigate("/Home");
                    }
                    toast.success(res?.data?.message);

                })
                .catch((err) => {
                    toast.error(err?.response?.data?.message);
                });
        }
    };

    const resendOtp = () => {
        const data = { _id: inputs?._id }
        resendOTP(data).then(res => {
            toast.success(res?.data?.message);
        })
            .catch((err) => {
                toast.error(err?.response?.data?.message);
            })
    }

    const handleBack = () => {
        closeOTPPopup();
        if (back) {
            openSinupPopup()
        }
        else {
            openPopup();
        }
    }




    return (
        <>
            <HeaderLogin onChildClick={openPopup} />
            <div className="content-page-box-area">
                <div className="row">
                    <SideBarLogin onChildClick={openPopup} />
                    <div className="col-lg-6 col-md-6">
                        <div className="card" style={{borderRadius:'12px'}}>
                            <div className="d-flex justify-content-center mt-5">
                                <div>
                                    <img width={175} style={{ borderRadius: '50%' }}
                                        src={
                                            s3Url+user?.key ??
                                            `${s3Url}empty_profile.png`
                                        }
                                        alt="user"
                                    />
                                </div>
                                <div className="mt-5 ms-2">
                                    <h3>{user?.userName}</h3>
                                    <span className="__cf_email__">{user?.userId}</span>
                                </div>
                            </div>
                            <div className="row m-3">
                            <div className="col-4 mb-3">
                                    <h6>Post Count</h6>
                                    <span>{user?.postCount}</span>
                                </div>
                                <div className="col-4 mb-3">
                                    <h6>Play Count</h6>
                                    <span>{user?.playCount}</span>
                                </div>
                                <div className="col-4 mb-3">
                                    <h6>Following Count</h6>
                                    <span>{user?.followingCount}</span>
                                </div>
                                <div className="col-4 mb-3">
                                    <h6>Followers Count</h6>
                                    <span>{user?.followersCount}</span>
                                </div>
                                <div className="col-4 mb-3">
                                    <h6>Name</h6>
                                    <span>{user?.userName}</span>
                                </div>
                                
                                <div className="col-4 mb-3">
                                    <h6>Phone</h6>
                                    <span>{user?.phone}</span>
                                </div>
                                <div className="col-4 mb-3">
                                    <h6>Date Of Birth</h6>
                                    <span>{user?.dob??'-'}</span>
                                </div>
                                <div className="col-4 mb-3">
                                    <h6>Gender</h6>
                                    <span>{user?.gender??'-'}</span>
                                </div>
                                <div className="col-4 mb-3">
                                    <h6>Private Account</h6>
                                    <span>{user?.isPrivate ? "Yes" : "No"}</span>
                                </div>
                                <div className="col-12 mb-3">
                                    <h6>Email Id</h6>
                                    <span>{user?.email??'-'}</span>
                                </div>

                                <div className="col-12 mb-3">
                                    <h6>About Me</h6>
                                    <span>{user?.aboutMe??'-'}</span>
                                </div>
                                
                            </div>
                        </div>
                    </div>
                    <SideBarRightLogin onChildClick={openPopup} />
                </div>
            </div>


            <Dialog open={open} fullWidth maxWidth="sm">
                <DialogTitle>
                    Login
                    <IconButton className="float-end" onClick={closePopup} >
                        <i className="material-icons-round">close</i>
                    </IconButton>
                </DialogTitle>
                <DialogContent className="login-form">
                    <form onSubmit={handleLogin}>
                        <div className="form-group">
                            <label className="mb-2">Phone Number</label>
                            <input type="text" name="phone" onChange={handleInput} placeholder='Enter phone number' className="form-control" />
                            {errors.phone.required ? (
                                <span className="text-danger form-text">
                                    This field is required.
                                </span>
                            ) : errors.phone.valid ? (
                                <span className="text-danger form-text">
                                    Enter valid Phone Number.
                                </span>
                            ) : null}
                        </div>
                        <div className="mt-3 privacy">
                            <Checkbox defaultChecked />
                            <small className="ms-2">
                                I agree to the <Link target="_blank" to={{ pathname: '/TermsAndCondition', search: '?item=Terms' }} >Terms And Conditions</Link>, <Link target="_blank" to={{ pathname: '/TermsAndCondition', search: '?item=Privacy' }}>Privacy Policy</Link>, and <Link target="_blank" to={{ pathname: '/TermsAndCondition', search: '?item=Coin' }} > Coin Policy</Link></small>
                        </div>
                        <button type="submit" className="default-btn">Login</button>
                        <div className="signup-text-info">Not a member yet? <Link onClick={openSinupPopup}> Sign Up</Link>.</div>

                    </form>
                </DialogContent>
            </Dialog>
            <Dialog open={openSinup} fullWidth maxWidth="sm">
                <DialogTitle>
                    Register
                    <IconButton className="float-end" onClick={closeSinupPopup} >
                        <i className="material-icons-round">close</i>
                    </IconButton>
                </DialogTitle>
                <DialogContent className="login-form">
                    <form onSubmit={handleRegistration}>
                        <div className="form-group">
                            <label className="mb-2">User Name</label>
                            <input type="text" name="userName" onChange={handleInput} placeholder='Enter user name' className="form-control" />
                            {errors.userName.required ?
                                <span className="text-danger form-text">
                                    This field is required.
                                </span> : null}
                        </div>
                        <div className="form-group">
                            <label className="mb-2">Phone Number</label>
                            <input type="text" name="phone" onChange={handleInput} placeholder='Enter phone number' className="form-control" />
                            {errors.phone.required ? (
                                <span className="text-danger form-text">
                                    This field is required.
                                </span>
                            ) : errors.phone.valid ? (
                                <span className="text-danger form-text">
                                    Enter valid Phone Number.
                                </span>
                            ) : null}
                        </div>
                        <div className="mt-3 privacy">
                            <Checkbox defaultChecked />
                            <small className="ms-2">
                                I agree to the <Link target="_blank" to={{ pathname: '/TermsAndCondition', search: '?item=Terms' }} >Terms And Conditions</Link>, <Link target="_blank" to={{ pathname: '/TermsAndCondition', search: '?item=Privacy' }}>Privacy Policy</Link>, and <Link target="_blank" to={{ pathname: '/TermsAndCondition', search: '?item=Coin' }} > Coin Policy</Link></small>
                        </div>
                        <button type="submit" className="default-btn">Register</button>
                    </form>
                </DialogContent>
            </Dialog>
            <Dialog open={openOTP} fullWidth maxWidth="sm">
                <DialogTitle>
                    Verify Your Account
                    <IconButton className="float-end" onClick={closeOTPPopup} >
                        <i className="material-icons-round">close</i>
                    </IconButton>
                </DialogTitle>
                <DialogContent className="login-form">
                    <div className="text-center">
                        <p>An OTP has been sent to your registered phone number.</p>
                        <p>Please check your message.</p>
                    </div>
                    <form onSubmit={handleSubmit}>
                        <div className="form-group mt-3">
                            <label>OTP</label>
                            <input type="text" placeholder='Enter OTP' name="otp" onChange={handleInput} className="form-control" />
                            {errors.otp.required ?
                                <span className="text-danger form-text">
                                    This field is required.
                                </span> : null}
                        </div>
                        <div className="remember-me-wrap d-flex justify-content-between align-items-center">
                            <div className="lost-your-password-wrap text-center">
                                <Link onClick={handleBack} className="back-to-login">
                                    Back
                                </Link>
                            </div>
                            <div className="lost-your-password-wrap">
                                <Link data-toggle="modal" data-target="#Forgotpassword" onClick={resendOtp} className="lost-your-password">Resend OTP</Link>
                            </div>
                        </div>
                        <button type="submit" className="default-btn">Submit</button>
                    </form>
                </DialogContent>
            </Dialog>


        </>
    )
}
export default ProfileShare;
