import API from "./api"
import { Story } from "./endpoints"

export const saveStory = (data) => {
    return API.post(`${Story}`, data)
}

export const getFollowingUserStory = () => {
    return API.get(`${Story}/getFollowingUserStory`)
}

export const getUserStroy = () => {
    return API.get(`${Story}/getUserStory`)
}

export const storySeenUser = (data) => {
    return API.put(`${Story}/saveSeenUser`,data)
}