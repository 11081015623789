
import { Link } from 'react-router-dom';
import Header from '../../components/Header';
import SideBar from '../../components/SideBar';
import SideBarRight from '../../components/SideBarRight';
import { useEffect, useState } from 'react';
import { Chip, Dialog, DialogContent, DialogTitle, IconButton, Tooltip } from '@mui/material';
import { getFilterCategory } from '../../api/category';
import { getCategory, getUserId, saveCategory } from '../../utils/Storage';
import { getSingleUser, updateUserCategory } from '../../api/user';
import { toast } from 'react-toastify';
import { deletePost, getFilterPost, getSinglePost, savePostComments, updateBlockPost, updatePostReport } from '../../api/post';
import { getFilterLikes, saveLikes } from '../../api/likes';
import { timeCal } from '../../utils/dateformat';
import { getFilterBidding, saveBidding } from '../../api/bidding';
import { EmailIcon, EmailShareButton, FacebookIcon, FacebookShareButton, LinkedinIcon, LinkedinShareButton, TelegramIcon, TelegramShareButton, TwitterIcon, TwitterShareButton, WhatsappIcon, WhatsappShareButton } from 'react-share';
import { s3Url, uploadFile } from '../../utils/FileUpload';
import { getFollowingUserStory, getUserStroy, saveStory, storySeenUser } from '../../api/story';
import Carousel from 'react-material-ui-carousel';
import ReactPlayer from 'react-player';
import { Helmet } from 'react-helmet';
import { Storage } from 'aws-amplify';


function Home() {
    const [open, setOpen] = useState(false);
    const [next, setNext] = useState(0);
    const [count, setCount] = useState(0);
    const [category, setCategory] = useState([]);
    const [more, setMore] = useState(false);
    const [followCategory, setFollowCategory] = useState([])
    const [user, setUser] = useState()
    const [post, setPost] = useState([]);
    const [postNext, setPostNext] = useState(0);
    const [likes, setLikes] = useState([])
    const [scroll, setScroll] = useState(false);
    const [reload, setReload] = useState(false);
    const [openBid, setOpenBid] = useState(false);
    const [bidAmount, setBidAmount] = useState('');
    const [submit, setSubmit] = useState(false);
    const [postId, setPostId] = useState('');
    const [bidding, setBidding] = useState([]);
    const [hide, setHide] = useState(false);
    const [hideId, setHideId] = useState('')
    const [postLikes, setPostLikes] = useState('')
    const [coins, setCoins] = useState('');
    const [share, setShare] = useState(false);
    const [shareUrl, setShareUrl] = useState('');
    const [content, setContent] = useState('')
    const [openComments, setOpenComments] = useState(false);
    const [userDetails, setUserDetails] = useState('');
    const [input, setInput] = useState('');
    const [userStory, setUserStory] = useState([]);
    const [story, setStory] = useState([])

    const [currentStoryIndex, setCurrentStoryIndex] = useState('');
    const [storyUrl, setStoryUrl] = useState('')
    const [currentStory, setCurrentStory] = useState('')
    const [storyVideo, setStoryVideo] = useState(false)
    const [playing, setPlaying] = useState(false);
    const [storyProfile, setStoryProfile] = useState('')
    const [uploadImage, setUploadImage] = useState('')
    const [key, setkey] = useState('')
    const [fileType, setFileType] = useState('')
    const [openPreview, setOpenPreview] = useState(false)
    const [openReport, setOpenReport] = useState(false)
    const [report, setReport] = useState({});
    const [openDescription, setOpenDescription] = useState(false);
    const [submitReport, setSubmitReport] = useState(false);
    const [reportId, setReportId] = useState('');
    const [reportResponse, setReportResponse] = useState(false);
    const [deleteId, setDeleteId] = useState('');
    const [openDelete, setOpenDelete] = useState(false);
    const [postDelete, setPostDelete] = useState(false);
    const [shareId, setShareId] = useState('')


    useEffect(() => {
        const user = getUserId()
        setUser(user)
        const selectCategory = getCategory();
        if (selectCategory.length === 0) {
            let isMounted = true;
            if (!isMounted) {
                getFilterCategoryList()
            } else {
                openPopup()
                isMounted = false
            }
        }
    }, [next])

    useEffect(() => {
        getPostList();
    }, [postNext])

    useEffect(() => {
        getUserLikedPost();
        getFilterBiddingList();
        getUserDetails()
        getUserStoryList()
        getStoryList();
        window.addEventListener('scroll', handleScroll);
    }, [])


    const getUserDetails = () => {
        const data = getUserId()
        getSingleUser(data)
            .then((res) => {
                setCoins(res?.data?.result?.coins);
                setUserDetails(res?.data?.result);
            })
            .catch((err) => {
                console.log(err);
            });
    }


    const handleScroll = () => {
        const value = window.innerHeight + window.scrollY >=
            document.documentElement.scrollHeight - 200
        setScroll(value)
    };
    useEffect(() => {
        if (scroll) {
            loadMorePost();
        }
    }, [scroll])



    const openPopup = () => {
        getFilterCategoryList()
        setOpen(true);
    };
    const closePopup = () => {
        setOpen(false);
    };

    const loadMore = () => {
        let nextCategory = next;
        nextCategory = nextCategory + 9;
        if (count <= nextCategory) {
            setMore(true)
        }
        else {
            setNext(nextCategory);
        }
    };

    const data = {
        limit: 9,
        page: next,
    };

    const getFilterCategoryList = () => {
        getFilterCategory(data)
            .then((res) => {
                const categoryList = res?.data?.result?.categoryList;
                setCount(res?.data?.result?.categoryCount)
                setCategory([...category, ...categoryList]);
            })
            .catch((err) => {
                console.log(err);
            });
    };

    const handleFollowCategory = (data) => {
        const newFollowCategory = [...followCategory, data]
        setFollowCategory(newFollowCategory)
    }
    const handleFollowingCategory = (data) => {
        const filterFollowCategory = followCategory.filter(x => x !== data)
        setFollowCategory(filterFollowCategory)
    }

    const handleSubmit = (event) => {
        event.preventDefault()
        if (followCategory.length >= 5) {
            const data = { _id: user, category: followCategory }
            updateUserCategory(data).then(res => {
                closePopup()
                toast.success(res?.data?.message)
                saveCategory(followCategory)
            }).catch((err) => {
                console.log(err);
            })
        }
        else {
            toast.warning('Please follow atleast five Categories')
        }
    }

    const getPostList = () => {
        const data = {
            limit: 5,
            page: postNext,
        };
        getFilterPost(data)
            .then((res) => {
                const postList = res?.data?.result?.postList;
                setCount(res?.data?.result?.postCount)
                setPost([...post, ...postList]);
            })
            .catch((err) => {
                console.log(err);
            });
    };


    const loadMorePost = () => {
        let nextPost = postNext;
        if (postDelete) {
            nextPost = nextPost + 4;
            setPostDelete(false)
        }
        else {
            nextPost = nextPost + 5;
        }
        if (count <= nextPost) {
            setReload(true)
        }
        else {
            setPostNext(nextPost);
        }
    };

    const savePostLikes = (postId, like) => {
        const user = getUserId()
        if (like === 'add') {
            setPostLikes(postId)
        }
        else { setPostLikes('') }
        const data = {
            user: user,
            post: postId
        }
        saveLikes(data).then(res => {
            singlePost(postId)
            getUserLikedPost()
        }).catch(err => {
            console.log(err);
        })
    }

    const singlePost = (postId) => {
        getSinglePost(postId).then(res => {
            const result = res?.data?.result
            const data = post.map(item =>
                item._id === result?._id ? { ...item, likeCount: result?.likeCount, bidingCount: result?.bidingCount, commentCount: result?.commentCount, comment: result?.comment } : item
            );
            setPost(data)
        }).catch(err => {
            console.log(err);
        })
    }

    const getUserLikedPost = () => {
        const data = { user: getUserId() }
        getFilterLikes(data).then(res => {
            setLikes(res?.data?.result)
        }).catch(err => {
            console.log(err);
        })
    }

    const openBidPopup = (id) => {
        setBidAmount(' ')
        setOpenBid(true);
        setSubmit(false);
        setPostId(id)
    };
    const closeBidPopup = () => {
        setOpenBid(false);
    };

    const handleBidAmount = (event) => {
        setBidAmount(event?.target?.value)
    }

    const handleBidAmountSubmit = (event) => {
        event.preventDefault()
        setSubmit(true)
        if (bidAmount) {
            if (coins >= bidAmount) {
                const data = {
                    user: user,
                    post: postId,
                    type: "post",
                    bidCoins: bidAmount
                }
                saveBidding(data).then(res => {
                    toast.success('Successfully submitting a bid');
                    closeBidPopup();
                    getFilterBiddingList();
                    singlePost(postId)
                }).catch(err => {
                    console.log(err);
                })
            }
            else {
                toast.warning('Your coin is less than the amount you have bid.')
            }
        }
    }

    const resetBidAmount = () => {
        setBidAmount("")
    }

    const getFilterBiddingList = () => {
        const data = { type: 'post' }
        getFilterBidding(data).then(res => {
            setBidding(res?.data?.result)
        }).catch(err => {
            console.log(err);
        })
    }

    const handleHidePost = () => {
        const data = { _id: hideId, blockedUsers: user }
        updateBlockPost(data).then(res => {
            closeHidePopup()
            toast.success(res?.data?.message)
            const newPostList = post.filter(x => x._id !== hideId)
            setPost(newPostList)
        }).catch(err => {
            console.log(err);
        })
    }

    const openHidePopup = (hideId) => {
        setHideId(hideId)
        setHide(true);
    };
    const closeHidePopup = () => {
        setHide(false);
    };

    const openSharePopup = (url, content, id) => {
        setShareId(id)
        setShareUrl(url);
        setContent(content)
        setShare(true)
    };
    const closeSharePopup = () => {
        setShare(false);
    };


    const getPostCommentsList = (commentId) => {
        if (openComments === commentId) {
            setOpenComments('')
        }
        else {
            setOpenComments(commentId)
        }
    }

    const handleInputs = (event) => {
        setInput({ ...input, message: event.target.value })
    }

    const handleComments = (id) => {
        if (input?.message) {
            const data = {
                _id: id,
                comment: {
                    comments: input?.message,
                    user: userDetails?._id
                }
            }
            savePostComments(data).then(res => {
                setInput({ ...input, message: '' })
                singlePost(id)
            }).catch(err => { console.log(err) })
        }
        else {
            toast.warning('Please enter the comment')
        }
    }

    const handleFileInputs = (event) => {
        const file = event?.target?.files[0];
        if (file) {
            if (file?.type === 'video/mp4') {
                setFileType(2)
            }
            else {
                setFileType(1)
            }
            openPreviewPopup()
            Storage.put(file.name, file).then((res) => {
                event.target.value = null;
                var url = 'https://d2iozohtwtwsbz.cloudfront.net/public/' + res.key;
                var key = 'public/' + res.key;
                setUploadImage(url)
                setkey(key)
            })
        }
    };

    const openPreviewPopup = () => {
        setUploadImage('')
        setkey('')
        setOpenPreview(true)
    }

    const closePreviewPopup = () => {
        setOpenPreview(false)
    }

    const uploadStory = () => {
        const data = { user: user, url: uploadImage, key, fileType: fileType };
        saveStory(data).then(res => {
            getUserStoryList()
            toast.success('Story successfully uploaded')
            closePreviewPopup()
        }).catch(err => { console.log(err); })
    }

    const getUserStoryList = () => {
        getUserStroy().then((res) => {
            setUserStory(res?.data?.result)
        }).catch(err => { console.log(err); })
    }

    const getStoryList = () => {
        getFollowingUserStory().then((res) => {
            setStory(res?.data?.result)
        }).catch(err => { console.log(err); })
    }


    const handlePlay = () => {
        setPlaying(true);
    };

    const handlePause = () => {
        setPlaying(false);
    };


    const handleEnded = () => {
        if (currentStoryIndex < storyUrl?.length - 1) {
            handlePause();
            setCurrentStoryIndex(currentStoryIndex + 1);
            handlePlay()
            if (currentStoryIndex + 1 === storyUrl?.length - 1) {
                getStoryList()
            }
        } else {
            closeStoryPopup()
        }
    };

    useEffect(() => {
        if (storyUrl) {
            setCurrentStory(storyUrl[currentStoryIndex])
            updateStorySeenUser(storyUrl[currentStoryIndex]?.id)
            if (storyUrl[currentStoryIndex]?.fileType === 1) {
                setTimeout(() => {
                    handleEnded()
                }, 5000);
            }
        }
    }, [currentStoryIndex])

    const handleVideo = (data) => {
        if (data) {
            setCurrentStoryIndex(0)
            setStoryProfile(data)
            setStoryUrl(data?.url)
            openStoryPopup()
        }
    }


    const handleprev = () => {
        setCurrentStoryIndex((prevIndex) => (prevIndex > 0 ? prevIndex - 1 : 0));
        handlePlay()
    }

    const handlenext = () => {
        setCurrentStoryIndex((prevIndex) => (prevIndex < storyUrl.length - 1 ? prevIndex + 1 : prevIndex))
        handlePlay()
    }

    const openStoryPopup = () => {
        setStoryVideo(true)
        handlePlay()
    }
    const closeStoryPopup = () => {
        setCurrentStoryIndex('')
        setCurrentStory('')
        setStoryVideo(false)
        handlePause();
    }

    const updateStorySeenUser = (id) => {
        const data = {
            _id: id,
            user: user
        }
        storySeenUser(data).then(res => {
            console.log(res);
        }).catch(err => { console.log(err); })
    }


    const openReportPopup = (data) => {
        setReportId(data)
        setOpenReport(true)
    }
    const closeReportPopup = () => {
        setOpenReport(false)
    }

    const openDescriptionPopup = (data) => {
        setReport({ ...report, type: data })
        setSubmitReport(false)
        closeReportPopup()
        setOpenDescription(true)
    }
    const closeDescriptionPopup = () => {
        setOpenDescription(false)
    }

    const openReportResponsePopup = () => {
        setReportResponse(true)
    }
    const closeReportResponsePopup = () => {
        setReportResponse(false)
    }

    const handleDescription = (event) => {
        const description = event?.target.value
        setReport({ ...report, description })
    }

    const handleReport = () => {
        setSubmitReport(true)
        if (report?.description) {
            const data = {
                _id: reportId,
                report: {
                    user: user,
                    type: report?.type,
                    description: report?.description
                }
            }
            updatePostReport(data).then(res => {
                closeDescriptionPopup();
                openReportResponsePopup()
            }).catch(err => { console.log(err); })
        }

    }

    const openDeletePopup = (data) => {
        setDeleteId(data)
        setOpenDelete(true)
    }
    const closeDeletePopup = () => {
        setOpenDelete(false)
    }

    const deletePostDetails = () => {
        deletePost(deleteId).then(res => {
            closeDeletePopup()
            toast.success(res?.data?.message);
            const newPostList = post.filter(x => x._id !== deleteId)
            setPost(newPostList)
            setPostDelete(true)
        }).catch(err => { console.log(err); })
    }


    return (
        <>
            <Header />
            <div className="content-page-box-area">
                <div className="row">
                    <SideBar menu={'Feed'} />
                    <div className="col-lg-6 col-md-6">
                        <div className="news-feed-area">
                            <div className="news-feed news-feed-form">
                                <h3 className="news-feed-title">Create Post</h3>
                                <form>
                                    <Link className="create-post-page" to='/CreatePost'>
                                        <div className="form-group">
                                            <textarea name="message" className="form-control" placeholder="Write something here..."></textarea>
                                        </div>
                                        <ul className="button-group d-flex justify-content-between align-items-center">
                                            <li className="photo-btn">
                                                <button type="submit"><i className="flaticon-gallery"></i> Photo</button>
                                            </li>
                                            <li className="tag-btn">
                                                <button type="submit"><i className="flaticon-video"></i> Video</button>
                                            </li>
                                            <li className="video-btn">
                                                <button type="submit"><i className="feather-play-circle"></i> Plays</button>
                                            </li>
                                            {/* <li className="post-btn">
                                            <button type="submit">Post</button>
                                        </li> */}
                                        </ul>
                                    </Link>
                                </form>
                            </div>
                            <div className="news-feed news-feed-stories">
                                <div className="stories-title d-flex justify-content-between align-items-center">
                                    <h3>Stories</h3>
                                    {/* <span><Link data-bs-toggle="modal" data-bs-target="#storyModel">See All</Link></span> */}
                                </div>

                                <div className="row">
                                    <div className="col-lg-2 col-sm-auto col-auto">
                                        <div className="stories-item stories-item-user">
                                            <div className="stories-image">
                                                <Link onClick={() => handleVideo(userStory[0])} >
                                                    <img src={s3Url+userDetails?.key ?? `${s3Url}empty_profile.png`} alt="user" />
                                                </Link>
                                                <div className="add-icon">
                                                    < label>
                                                        < Link >
                                                            <i className="flaticon-add text-primary">
                                                            </i>
                                                        </Link>
                                                        <span>
                                                            <input type="file" onChange={handleFileInputs} accept="image/*,video/*" className="default-file-input" />
                                                        </span>
                                                    </label>
                                                </div>
                                            </div>

                                            <span><Link>Your Story</Link></span>
                                        </div>
                                    </div>
                                    <div className="col-lg-10 col-sm col p-r-0 vertical-scroll">
                                        {story?.map((data, index) =>
                                            <div key={index} >
                                                <div className="stories-item px-2" onClick={() => { handleVideo(data) }}>
                                                    <Link className="store-item-user" style={{
                                                        border: data?.url[data?.url?.length - 1]?.seenUser?.includes(user) ? "3px solid white" : "3px solid #6720aa"
                                                    }}>
                                                        <img src={s3Url+ data?.key}
                                                            alt='user'
                                                        />
                                                    </Link>
                                                    <span><Link>{data?.userName?.length > 10 ? data?.userName.slice(0, 10) + '...' : data?.userName}</Link></span>
                                                </div>
                                            </div>)}

                                    </div>
                                </div>
                            </div>
                            {post?.map((data, index) =>
                                <div key={index} className="news-feed news-feed-post">
                                    {data?.isBided ?
                                        <div className="post-header d-flex justify-content-between align-items-center">
                                            <div className="image">
                                                <Link to={{ pathname: '/Profile', search: `id=${data?.bidedUser?._id}` }}> <img src={s3Url+data?.bidedUser?.key} alt="user" /></Link>
                                            </div>
                                            <div className="info">
                                                <span className="name"><Link to={{ pathname: '/Profile', search: `id=${data?.bidedUser?._id}` }}>{data?.bidedUser?.userName}</Link></span>
                                                <span className="small-text-bid">{" "}Owned this content</span>
                                            </div>
                                        </div> : null}
                                    <div className="post-header d-flex justify-content-between align-items-center">
                                        <div className="image">
                                            <Link to={!data?.isBided ? { pathname: '/Profile', search: `id=${data?.user?._id}` } : null}> <img src={s3Url+data?.user?.key} alt="user" /></Link>
                                        </div>
                                        <div className="info">
                                            <span className="name"><Link to={!data?.isBided ? { pathname: '/Profile', search: `id=${data?.user?._id}` } : null}>{data?.user?.userName}</Link></span>
                                            <span className="small-text"><i className="material-icons-outlined">public</i>{data?.postType === 1 ? 'Public' : 'Private'}<span> {timeCal(data?.createdOn)}</span></span>
                                        </div>
                                        {
                                            data?.isBid && !data?.isBided ?
                                                (data?.user?._id === user || bidding?.some(x => x?.user?._id === user && x?.post === data?._id) ?
                                                    <Tooltip title="Bid Count"> <Chip className='me-2' icon={<i className="material-icons-outlined" style={{ color: "gold", fontSize: "20px" }}>paid</i>} label={data?.bidingCount} size='small' color='success' /> </Tooltip>
                                                    : <div className="buy-button">
                                                        <button type="button" onClick={() => openBidPopup(data?._id)}><i className="material-icons-outlined">paid</i>Buy</button>
                                                    </div>)
                                                : null}
                                        <div className="dropdown mx-4">
                                            <button className="dropdown-toggle" type="button" data-bs-toggle="dropdown" aria-haspopup="true" aria-expanded="false"><i className="feather-more-horizontal"></i></button>
                                            <ul className="dropdown-menu">
                                                {data?.user?._id !== user || data?.isBided ? (data?.bidedUser?._id !== user ?
                                                    <><li><Link className="dropdown-item d-flex align-items-center" onClick={() => openHidePopup(data?._id)}><i className="material-icons-outlined">hide_source</i> Hide</Link></li>
                                                        <li><Link className="dropdown-item d-flex align-items-center" onClick={() => openReportPopup(data?._id)} ><i className="material-icons-outlined">report</i> Report</Link></li></>
                                                    : <><li><Link className="dropdown-item d-flex align-items-center" to={{ pathname: '/EditPost', search: `postId=${data?._id}` }} ><i className="material-icons-outlined">edit</i> Edit</Link></li>
                                                        <li><Link className="dropdown-item d-flex align-items-center" onClick={() => openDeletePopup(data?._id)} ><i className="material-icons-outlined">delete</i> Delete</Link></li></>) :
                                                    <><li><Link className="dropdown-item d-flex align-items-center" to={{ pathname: '/EditPost', search: `postId=${data?._id}` }} ><i className="material-icons-outlined">edit</i> Edit</Link></li>
                                                        <li><Link className="dropdown-item d-flex align-items-center" onClick={() => openDeletePopup(data?._id)} ><i className="material-icons-outlined">delete</i> Delete</Link></li></>}
                                            </ul>
                                        </div>
                                    </div>

                                    <div className="post-body">
                                        <div className="post-image">
                                            {data?.fileType === 1 ?
                                                <img
                                                    src={s3Url+data?.key}
                                                    alt="Post"
                                                /> : <video src={s3Url+data?.key} width="100%" height="auto" controls>
                                                </video>}
                                        </div>
                                        <div className="post-content-hashtag"> <p>{data?.textContent}</p></div>

                                        <ul className="post-meta-wrap d-flex justify-content-between align-items-center">
                                            <li className="post-react">
                                                <Link>
                                                    {postLikes === data?._id || likes?.some(x => x.post?._id === data?._id) ?
                                                        <i className="material-icons-round text-danger" onClick={() => savePostLikes(data?._id, 'remove')} >favorite</i> :
                                                        <i className="feather-heart" onClick={() => savePostLikes(data?._id, 'add')}></i>}
                                                    <span className="number">{data?.likeCount} </span></Link>
                                            </li>
                                            <li className="post-comment">
                                                <Link onClick={() => getPostCommentsList(data?._id)}><i className="feather-message-circle"></i><span className="number">{data?.commentCount}</span></Link>
                                            </li>
                                            <li className="post-share">
                                                <Link onClick={() => openSharePopup(s3Url+data?.key, data?.textContent, data?._id)}><img src="../assets/images/share.png" alt="share" />
                                                    {/* <span className="number">{data?.shareCount} </span> */}
                                                </Link>
                                            </li>
                                        </ul>
                                        {/* <form className="post-footer">
                                            <div className="footer-image">
                                                <Link to="#"><img src="../assets/images/user/user-2.jpg" className="rounded-circle" alt="user" /></Link>
                                            </div>
                                            <div className="form-group">
                                                <textarea name="message" className="form-control" placeholder="Write a comment..."></textarea>
                                                <label><Link to="#"><i className="feather-send"></i></Link></label>
                                            </div>
                                        </form> */}
                                        {openComments === data?._id ?
                                            <div className="new-comments post-modal-popup">
                                                <div className="comments-area" data-simplebar style={data?.comment?.length > 0 ? { height: '150px' } : { height: '0px' }}>
                                                    <div className="post-comment-list">
                                                        {data?.comment?.map((value, index) =>
                                                            <div className="comment-list" key={index}>
                                                                <div className="comment-image">
                                                                    <Link to={{ pathname: '/Profile', search: `id=${value?.user?._id}` }}><img src={s3Url+value?.user?.key} className="rounded-circle" alt="user" /></Link>
                                                                </div>
                                                                <div className="comment-info">
                                                                    <h3>
                                                                        <Link to={{ pathname: '/Profile', search: `id=${value?.user?._id}` }}>{value?.user?.userName}</Link>
                                                                    </h3>
                                                                    <span>{timeCal(value?.createdOn)}</span>
                                                                    <p>{value?.comments}</p>
                                                                    {/* <ul className="comment-react">
                                                        <li><a href="#" className="like">1,351 likes</a></li>
                                                        <li><a href="#">Reply</a></li>
                                                    </ul> */}
                                                                </div>
                                                            </div>)}
                                                    </div>
                                                </div>

                                                <form className="post-footer">
                                                    <div className="footer-image">
                                                        <Link to={{ pathname: '/Profile', search: `id=${userDetails?._id}` }}><img src={s3Url+userDetails?.key} className="rounded-circle" alt="user" /></Link>
                                                    </div>
                                                    <div className="form-group">
                                                        <textarea name="message" value={input?.message} onChange={handleInputs} className="form-control" placeholder="Write a comment..."></textarea>
                                                        <label><Link onClick={() => handleComments(data?._id)} ><i className="feather-send"></i></Link></label>
                                                    </div>
                                                </form>
                                            </div>
                                            : null}
                                    </div>
                                </div>
                            )}
                            {/* {post.length % 5 === 0 && (
                                <div className="ad">
                                    <ins
                                        className="adsbygoogle"
                                        style={{ display: 'block' }}
                                        data-ad-client="your-client-id"
                                        data-ad-slot="your-ad-slot"
                                        data-ad-format="auto"
                                    ></ins>
                                </div>
                            )} */}
                            {reload ?
                                <div className='form-text text-danger text-center'>No Post</div> : null}
                        </div>
                    </div>
                    <SideBarRight />
                </div>
            </div>
            <Dialog open={open} fullWidth maxWidth="md">
                <DialogTitle>
                    Category
                </DialogTitle>
                <DialogContent className="login-form">
                    <form onSubmit={handleSubmit}>
                        <div className="mt-2">
                            <div className="content-page-box-area">
                                <div className="row">
                                    {category?.map((data, index) =>
                                        <div key={index} className="col-lg-4 col-sm-6">
                                            <div className="single-groups-card">
                                                <div className="groups-content">
                                                    <div className="groups-info d-flex justify-content-between align-items-center">
                                                        <Link>
                                                            <img src={data?.image} alt="category" />
                                                        </Link>
                                                        <div className="text">
                                                            <h3><Link>{data?.category}</Link></h3>
                                                        </div>
                                                    </div>
                                                    <div className="join-groups-btn">
                                                        {followCategory?.includes(data?._id) ?
                                                            <button onClick={() => handleFollowingCategory(data?._id)} type="button">Following</button> :
                                                            <button onClick={() => handleFollowCategory(data?._id)} type="button">Follow</button>}
                                                    </div>
                                                </div>
                                            </div>
                                        </div>)}
                                </div>
                            </div>
                        </div>
                        {!more ?
                            <div className="load-more-posts-btn">
                                <Link onClick={loadMore}><i className="flaticon-loading"></i> Load More</Link>
                            </div> : null}
                        <div className='d-flex justify-content-center '>
                            <button type="submit" className="default-btn" style={{ width: '50%' }}>Submit</button>
                        </div>
                    </form>
                </DialogContent>
            </Dialog>

            <Dialog open={openBid} fullWidth maxWidth="xs">
                <DialogTitle>
                    Post Bidding					<IconButton className="float-end" onClick={closeBidPopup} >
                        <i className="material-icons-round">close</i>
                    </IconButton>
                </DialogTitle>
                <DialogContent>
                    <form onSubmit={handleBidAmountSubmit}>
                        <div className="from-group mb-3">
                            <label htmlFor="categoryName" className="form-label">
                                Bid Amount
                            </label>
                            <div className="input-group">
                                <div className="input-group-prepend">
                                    <span className="input-group-text">₹</span>
                                </div>
                                <input type="text" value={bidAmount} onChange={handleBidAmount} name='biddingAmount' className="form-control" placeholder="Bid Amount" />
                            </div>
                            {!bidAmount && submit ? (
                                <span className="form-text text-danger">
                                    This field is required.
                                </span>
                            ) : null}
                        </div>
                        <div className='d-flex justify-content-end'>
                            <button type='submit' className="btn-save me-3"> Submit </button>
                            <button type='button' className="btn-cancel" onClick={resetBidAmount}> Reset </button>
                        </div>
                    </form>
                </DialogContent>
            </Dialog>

            <Dialog open={hide}>
                <DialogContent>
                    <div className="text-center m-4">
                        <h5 className="mb-4">Are you sure you want to hide <br /> the selected Post ?</h5>
                        <button type="button" className="btn btn-save mx-3" onClick={handleHidePost}>Yes</button>
                        <button type="button" className="btn btn-cancel " onClick={closeHidePopup}>No</button>
                    </div>
                </DialogContent>
            </Dialog>

            <Dialog open={share} fullWidth maxWidth="xs">
                <DialogTitle className='text-secondary'>
                    Share On Social Media<IconButton className="float-end" onClick={closeSharePopup} >
                        <i className="material-icons-round">close</i>
                    </IconButton>
                </DialogTitle>
                <DialogContent>
                    <div className="p-4 d-flex justify-content-between">
                        <WhatsappShareButton url={`https://pixalive.me/singlepost?id=${shareId}`} title={content} >
                            <WhatsappIcon size={32} round={true} />
                        </WhatsappShareButton>
                        <FacebookShareButton url={`https://pixalive.me/singlepost?id=${shareId}`} title={content} >
                            <FacebookIcon size={32} round={true} />
                        </FacebookShareButton>
                        <TwitterShareButton url={`https://pixalive.me/singlepost?id=${shareId}`} title={content}>
                            <TwitterIcon size={32} round={true} />
                        </TwitterShareButton>
                        <TelegramShareButton url={`https://pixalive.me/singlepost?id=${shareId}`} title={content}>
                            <TelegramIcon size={32} round={true} />
                        </TelegramShareButton>
                        <LinkedinShareButton url={`https://pixalive.me/singlepost?id=${shareId}`} title={content}>
                            <LinkedinIcon size={32} round={true} />
                        </LinkedinShareButton>
                        <EmailShareButton url={`https://pixalive.me/singlepost?id=${shareId}`} title={content}>
                            <EmailIcon size={32} round={true} />
                        </EmailShareButton>
                    </div>
                </DialogContent>
            </Dialog>



            <Dialog open={storyVideo} fullWidth maxWidth="md" PaperProps={{ style: { background: "black" } }}>
                <DialogTitle className='text-secondary'>
                    <div className='stories-modal'>
                        <img src={s3Url+storyProfile?.key} alt='user' />
                        <div className='info'>
                            <span>{storyProfile?.userName}</span>
                            <span>{timeCal(currentStory?.createdOn)}</span>
                        </div>
                        <IconButton className="ms-auto" onClick={closeStoryPopup} >
                            <i className="material-icons-round" style={{ color: "white" }}>close</i>
                        </IconButton>
                    </div>
                </DialogTitle>
                <DialogContent className='story-model'>
                    <Carousel animation='slide' prev={handleprev} next={handlenext} indicatorIconButtonProps={{ style: { display: 'none', }, }}>

                        <div className="sliderContainer">
                            <div className="story-image">
                                {currentStory?.fileType === 2 ? <ReactPlayer
                                    className='react-player'
                                    url={s3Url+currentStory?.key}
                                    controls
                                    onEnded={handleEnded}
                                    playing={playing}
                                /> :
                                    <img src={s3Url+currentStory?.key} alt='story' />
                                }
                            </div>
                        </div>
                    </Carousel>
                </DialogContent>
            </Dialog>

            <Dialog open={openPreview} fullWidth maxWidth="md" PaperProps={{ style: { background: "black" } }}>
                <DialogTitle>
                    <IconButton className="float-end" onClick={closePreviewPopup} >
                        <i className="material-icons-round" style={{ color: "white" }}>close</i>
                    </IconButton>
                    <Tooltip title="Upload Story" style={{ marginLeft: "50%" }}> <IconButton className="text-secondary" onClick={uploadStory} >
                        <i className="material-icons-round" style={{ color: "white", fontSize: '40px' }}>cloud_upload</i>
                    </IconButton> </Tooltip>
                </DialogTitle>
                <DialogContent className='story-model'>
                    <div className="sliderContainer">
                        <div className="story-image">
                            {fileType === 2 ?
                                <video src={uploadImage} controls /> :
                                <img src={uploadImage} alt='story' />}
                        </div>
                    </div>
                </DialogContent>
            </Dialog>

            <Dialog open={openReport} fullWidth maxWidth="xs" >
                <DialogTitle>Report
                    <IconButton className="float-end" onClick={closeReportPopup} >
                        <i className="material-icons-round" >close</i>
                    </IconButton>
                </DialogTitle>
                <DialogContent>
                    <div className='report'>
                        <h6>Report this Post</h6>
                        <span>Help us understand what's happening with this post. How would you describe it?</span>
                        <ul className='mt-2'>
                            <li> <span onClick={() => openDescriptionPopup('Misleading or scam')}>Misleading or scam </span> </li>
                            <li> <span onClick={() => openDescriptionPopup('Sexually inappropriate')}> Sexually inappropriate </span></li>
                            <li><span onClick={() => openDescriptionPopup('Offensive')}>Offensive</span></li>
                            <li><span onClick={() => openDescriptionPopup('Violence')}>Violence</span></li>
                            <li><span onClick={() => openDescriptionPopup('Prohibited content')}>Prohibited content</span> </li>
                            <li><span onClick={() => openDescriptionPopup('Spam')}>Spam </span> </li>
                            <li><span onClick={() => openDescriptionPopup('False news')}>False news </span></li>
                            <li><span onClick={() => openDescriptionPopup('Political candidate or issue')}>Political candidate or issue </span> </li>
                            <li><span onClick={() => openDescriptionPopup('Other')}>Other</span></li>
                        </ul>
                    </div>
                </DialogContent>
            </Dialog>

            <Dialog open={openDescription} fullWidth maxWidth="sm" >
                <DialogTitle>Report
                    <IconButton className="float-end" onClick={closeDescriptionPopup} >
                        <i className="material-icons-round" >close</i>
                    </IconButton>
                </DialogTitle>
                <DialogContent>
                    <div className="row">
                        <div className="col-lg-12 col-md-12 col-sm-12">
                            <div className="form-group">
                                <label className='mb-2'>Description</label>
                                <textarea className="form-control" rows={4} onChange={handleDescription} name='description' placeholder="Description"></textarea>
                                {!report?.description && submitReport ? (
                                    <span className="form-text text-danger">
                                        This field is required.
                                    </span>
                                ) : null}
                            </div>
                        </div>
                    </div>
                    <div className="float-end mt-3">
                        <button type="button" className="btn btn-save mx-3" onClick={handleReport} >Submit</button>
                        <button type="button" className="btn btn-cancel " onClick={closeDescriptionPopup}>Cancel</button>
                    </div>
                </DialogContent>
            </Dialog>

            <Dialog open={reportResponse} fullWidth maxWidth="xs" >
                <DialogTitle className='p-0'>
                    <IconButton className="float-end m-2" onClick={closeReportResponsePopup} >
                        <i className="material-icons-round" >close</i>
                    </IconButton>
                </DialogTitle>
                <DialogContent className='responseModal'>
                    <span><i className="material-icons-round" >check_circle</i></span>
                    <h5>Thank you for submitting a report.</h5>
                    <div className='my-2'><Chip color="primary" variant="outlined" label={report?.type}></Chip></div>
                    <span>We'll let you know when there's an update on this report.</span>
                </DialogContent>
            </Dialog>

            <Dialog open={openDelete}>
                <DialogContent>
                    <div className="text-center m-4">
                        <h5 className="mb-4">Are you sure you want to Delete <br /> the selected Post ?</h5>
                        <button type="button" className="btn btn-save mx-3" onClick={deletePostDetails}>Yes</button>
                        <button type="button" className="btn btn-cancel " onClick={closeDeletePopup}>No</button>
                    </div>
                </DialogContent>
            </Dialog>
        </>
    );
}
export default Home;