import API from "./api"
import {User } from "./endpoints"


export const getTrendingPeopleByLogout = ()=>{
    return API.get(`${User}/getTrendingUsersByLogOut`)
}

export const updateUserCategory = (data)=>{
    return API.put(`${User}/updateUserCategory`,data)
}

export const getSingleUser = (data)=>{
    return API.get(`${User}/getSingleUser`,{params:{_id:data}})
}

export const getSingleUserForShare = (data)=>{
    return API.get(`${User}/getSingleUserForShare`,{params:{_id:data}})
}

export const updateUserImage = (data)=>{
    return API.put(`${User}/updateUserImage`,data)
}


export const getFilterSuggestionUsersList = (data)=>{
    return API.put(`${User}/getFilterSuggestionUsersList`,data)
}

export const getSuggestionUsersByLogOut = ()=>{
    return API.get(`${User}/getSuggestionUsersListByLogOut`)
}

export const getFilterTrendingUsers = (data)=>{
    return API.put(`${User}/getFilterTrendingUsersForWeb`,data)
}

export const updateBlockedUsers = (data)=>{
    return API.put(`${User}/updateBlockUsers`,data)
}

export const removeBlockedUsers = (data)=>{
    return API.put(`${User}/removeBlockUsers`,data)
}

export const updateUser = (data)=>{
    return API.put(`${User}`,data)
}

export const getAllUsersForSearch = ()=>{
    return API.get(`${User}/getAllUserForSearch`)
}