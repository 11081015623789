
import { Link, useNavigate } from 'react-router-dom';
import Header from '../../components/Header';
import { useEffect, useState } from 'react';
import { toast } from 'react-toastify';
import { savePost } from '../../api/post';
import { getSingleUser } from '../../api/user';
import { getUserId } from '../../utils/Storage';
import { savePlay } from '../../api/play';
import { LinearProgress } from '@mui/material';
import { Storage } from 'aws-amplify';
function CreatePost() {
    let initialStateInputs = {
        user: getUserId(),
        url: "",
        key: "",
        fileType: "",
        textContent: "",
        postType: "1",
        category: "",
        categoryName: "",
        isDownload: false,
        isComment: false,
        isBid: false,
        bidAmount: ""
    }

    let initialStateErrors = {
        url: { required: false },
        category: { required: false },
        textContent: { required: false },
        bidAmount: { required: false }
    };
    const [errors, setErrors] = useState(initialStateErrors);
    const [inputs, setInputs] = useState(initialStateInputs);
    const [categories, setCategories] = useState();
    const [submitted, setSubmitted] = useState(false);
    const [progress, setProgress] = useState(false)
    const navigate = useNavigate()
    useEffect(() => {
        getUserDetails();
    }, [])

    const handleFileInputs = (event) => {
        if (inputs?.fileType) {
            const file = event?.target?.files[0];
            if (file) {
                setProgress(true)
                Storage.put(file.name, file).then(res => {
                    var url = 'https://d2iozohtwtwsbz.cloudfront.net/public/' + res.key;
                    var key = 'public/' + res.key;
                    event.target.value = null;
                    setProgress(false)
                    setInputs({ ...inputs, url, key })
                    if (submitted) {
                        const newError = handleValidation({ ...inputs, url })
                        setErrors(newError)
                    }
                }).catch(err => { console.log(err); })
            };
        }
        else {
            toast.warning('Please select image or video or plays')
            event.target.value = null
        }
    };
    const getUserDetails = () => {
        const userId = getUserId()
        getSingleUser(userId).then(res => {
            const categoryList = res?.data?.result?.category
            setCategories(categoryList)
        }).catch(err => {
            console.log(err);
        })
    }
    const handleInputs = (event) => {
        setInputs({ ...inputs, [event.target.name]: event.target.value });
        if (submitted) {
            const newError = handleValidation({ ...inputs, [event.target.name]: event.target.value })
            setErrors(newError)
        }
    };

    const handleFileType = (event) => {
        const fileType = Number(event.target.value)
        setInputs({ ...inputs, fileType, url: "" })
    }

    const handleCheckBox = (event) => {
        setInputs({ ...inputs, [event.target?.name]: event.target.checked })
    }

    const selectCategory = (event) => {
        const id = event.target.value
        const { _id, category } = categories.find(x => x._id === id)
        setInputs({ ...inputs, category: _id, categoryName: category })
        if (submitted) {
            const newError = handleValidation({ ...inputs, category: _id, categoryName: category })
            setErrors(newError)
        }
    }

    const handleValidation = (data) => {
        let error = initialStateErrors;
        if (data.url === "") {
            error.url.required = true;
        }
        if (data.fileType !== 3 && data.category === "") {
            error.category.required = true;
        } if (data.isBid && data.bidAmount === "") {
            error.bidAmount.required = true;
        }
        return error
    }

    const handleSubmit = (event) => {
        event.preventDefault();
        const newError = handleValidation(inputs)
        setErrors(newError)
        setSubmitted(true)
        const allInputsValid = Object.values((newError))
        const valid = allInputsValid.some(x => x.required === true)
        if (!valid) {
            if (inputs?.fileType === 3) {
                const data = {
                    user: inputs?.user,
                    url: inputs?.url,
                    playType: inputs?.postType,
                    isDownload: inputs?.isDownload,
                    description: inputs?.textContent,
                    isBid: inputs?.isBid,
                    bidAmount: inputs?.bidAmount
                }
                savePlay(data).then((res) => {
                    toast.success(res?.data?.message);
                    navigate("/Home")
                })
                    .catch((err) => {
                        toast.error(err?.response?.data?.message);
                    });
            }
            else {
                savePost(inputs)
                    .then((res) => {
                        toast.success(res?.data?.message);
                        navigate("/Home")
                    })
                    .catch((err) => {
                        toast.error(err?.response?.data?.message);
                    });
            }
        }
    }

    return (
        <>
            <Header />
            <div className="content-page-box-area">
                <div className="row">
                    <div className="col-lg-9 col-md-12 m-auto">
                        <div className="content-page-box-area">
                            <form onSubmit={handleSubmit}>
                                <div className="create-post-page-body">
                                    <div className="page-banner-box page-banner-box-post">
                                        <div>
                                            <h3>Create Post</h3>
                                            <p>Post a Images or Video or Plays to your account</p>
                                        </div>
                                        <div className="post-option-list">
                                            <input id="image" type="radio" onChange={handleFileType} name="fileType" value={1} />
                                            <label htmlFor="image"><span></span>Image</label>
                                            <input id="video" type="radio" onChange={handleFileType} name="fileType" value={2} />
                                            <label htmlFor="video"><span></span>Video</label>
                                            <input id="play" type="radio" onChange={handleFileType} name="fileType" value={3} />
                                            <label htmlFor="play"><span></span>Plays</label>
                                        </div>
                                    </div>
                                    <div className="row">
                                        <div className="col-lg-4 col-md-4">
                                            <div className="upload-files-container">
                                                <label >
                                                    <div className="drag-file-area">
                                                        {progress ? <LinearProgress color="info" /> : null}
                                                        {!inputs?.url ?
                                                            <div className='mt-5'><span className="material-icons-outlined upload-icon"> file_upload </span>
                                                                <h3 className="dynamic-message">Select {inputs?.fileType === 1 ? 'image' : "video"} to upload</h3></div> : null}
                                                        <span className="browse-files">
                                                            <input type="file" onChange={handleFileInputs} accept={inputs?.fileType === 1 ? "image/*" : "video/*"} className="default-file-input" />
                                                            {inputs?.url ?
                                                                <div>
                                                                    {inputs?.fileType === 1 ?
                                                                        <img src={(inputs?.url)} width="85%" alt="post" /> :
                                                                        <video src={(inputs?.url)} width="85%" height="280" controls></video>}</div> : null}
                                                        </span>
                                                    </div>
                                                </label>
                                                {errors.url.required ? (
                                                    <span className="form-text text-danger">
                                                        This field is required.
                                                    </span>
                                                ) : null}
                                            </div>
                                        </div>
                                        <div className="col-lg-8 col-md-8">
                                            <div className="create-post-from-detail">
                                                <div className="col-lg-12 col-md-12 mb-2">
                                                    <div className="form-group">
                                                        <label className="label_hint">Caption <span>{inputs?.textContent?.length}/200</span></label>
                                                        <textarea className="form-control" onChange={handleInputs} name='textContent' maxLength={200}></textarea>
                                                    </div>
                                                </div>
                                                <div className='row'>
                                                    <div className="col-lg-6 col-md-6">
                                                        <div className="form-group">
                                                            <label>Who can view this video</label>
                                                            <select className="form-select" onChange={handleInputs} name='postType'>
                                                                <option value="1">Public</option>
                                                                <option value="2">Private</option>
                                                                <option value="3">Only Me</option>
                                                            </select>
                                                        </div>
                                                    </div>
                                                    {inputs?.fileType !== 3 ?
                                                        <div className="col-lg-6 col-md-6">
                                                            <div className="form-group">
                                                                <label>Category</label>
                                                                <select defaultValue={'default'} className="form-select" name='category' onChange={selectCategory}>
                                                                    <option value={"default"} disabled hidden >Select Category</option>
                                                                    {categories?.map((data, index) =>
                                                                        <option key={index} value={data?._id}>{data?.category}</option>)}
                                                                </select>
                                                                {errors.category.required ? (
                                                                    <span className="form-text text-danger">
                                                                        This field is required.
                                                                    </span>
                                                                ) : null}
                                                            </div>
                                                        </div> : null}
                                                </div>
                                                <div className="row">
                                                    <div className=" col-lg-6 col-md-6 form-group sell-permission">
                                                        <label>Are you want to sell</label>
                                                        <div className="toggle-btn1 post-toggle-btn">
                                                            <input type="checkbox" onChange={handleCheckBox} name='isBid' id="toggle-btn-5" />
                                                            <label htmlFor="toggle-btn-5"></label>
                                                        </div>
                                                    </div>
                                                    {inputs?.isBid ? <div className=" col-lg-6 col-md-6 form-group-1">
                                                        <label>Bid Amount in Rs</label>
                                                        <div className="input-group">
                                                            <div className="input-group-prepend">
                                                                <span className="input-group-text">₹</span>
                                                            </div>
                                                            <input type="text" onChange={handleInputs} name='bidAmount' className="form-control" placeholder="Bid Amount" />
                                                        </div>
                                                        {errors.bidAmount.required ? (
                                                            <span className="form-text text-danger">
                                                                This field is required.
                                                            </span>
                                                        ) : null}
                                                    </div> : null}
                                                </div>

                                                <div className="col-lg-12 col-md-12">
                                                    <div className="form-group">
                                                        <label>Allow users to</label>
                                                        <ul className="other-permission-list">
                                                            {inputs?.fileType !== 3 ?
                                                                <li>
                                                                    <input id="checkbox1" onChange={handleCheckBox} name='isComment' type="checkbox" />
                                                                    <h5>Comment</h5>
                                                                </li> : null}
                                                            <li>
                                                                <input id="checkbox2" onChange={handleCheckBox} name='isDownload' type="checkbox" />
                                                                <h5>Download Permission</h5>
                                                            </li>
                                                        </ul>
                                                    </div>
                                                </div>
                                                <div className="col-lg-12 col-md-12">
                                                    <Link to="/Home" className="upload-button-discard"> Discard </Link>
                                                    <button type='submit' className="upload-button-submit"> Post </button>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
            </div>


        </>
    );
}
export default CreatePost;