import API from "./api"
import { Login, User } from "./endpoints"


export const loginUser = (data)=>{
    return API.post(`${Login}`,data)
}

export const verifyOTP = (data)=>{
    return API.post(`${Login}/verifyOtp`,data)
}

export const resendOTP = (data)=>{
    return API.put(`${Login}/resentOtp`,data)
}

export const sinupUser = (data)=>{
    return API.post(`${User}`,data)
}