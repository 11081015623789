import API from "./api"
import { Follow } from "./endpoints"

export const getFollowersList = (data) => {
    return API.get(`${Follow}/getfollowerList`, { params: data })
}

export const getFollowingList = (data) => {
    return API.get(`${Follow}/getfollowingList`, { params: data })
}

export const getRequestFollowersList = (data) => {
    return API.get(`${Follow}/getRequestFollower`, { params: data })
}

export const saveFollower = (data) => {
    return API.post(`${Follow}`, data)
}

export const acceptFollower = (data) => {
    return API.put(`${Follow}`, data)
}

export const getAllFollowings = (data) => {
    return API.get(`${Follow}`, { params: data })
}

export const deleteFollower = (data) => {
    return API.delete(`${Follow}`, { params: data })
}