import API from "./api";
import { Category } from "./endpoints";


export const getFilterCategory = (data) => {
    return API.put(`${Category}/getFilterAdminCategory`,data);
  };

  export const getAllCategory = () => {
    return API.get(`${Category}`);
  };
  export const getFollowCategoryDetails = (data) => {
    return API.get(`${Category}/getFollowCategoryDetails`,data);
  };

  export const getTrendingCategories = ()=>{
    return API.get(`${Category}/getTrendingCategories`)
}

export const getSingleCategory = (data)=>{
  return API.get(`${Category}/getSingleCategory`,{params:data})
}