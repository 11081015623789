import React, { useEffect, useState } from 'react'; 
import { getTrendingPeopleByLogout } from '../api/user';
import { Link } from 'react-router-dom';
import { s3Url } from '../utils/FileUpload';

function SideBarLogin({onChildClick}) 
{ 
    const [trendingPeople,setTrendingPeople]=useState();

    useEffect(()=>{
getTrendingPeopleList()
    },[])

    const getTrendingPeopleList = () => {
        getTrendingPeopleByLogout()
            .then((res) => {
                setTrendingPeople(res?.data?.result);
            })
            .catch((err) => {
                console.log(err);
            });
    };


    return( 
    <>    
            <div className="col-lg-3 col-md-3">
            <div className="sidemenu-area-1">
                <div className="sidemenu-body-1">
                    <div className="sidemenu-nav-1 metisMenu h-100" id="sidemenu-nav" data-simplebar>
                        <aside className="widget-area">                            
                            <div className="widget sticky-cls">
                                <ul className="sidemenu-nav-1">
                                    <li className="nav-item ">
                                        <Link onClick={onChildClick} className="nav-link">
                                            <span className="icon"><i className="feather-home"></i></span>
                                            <span className="menu-title">Feed</span>
                                        </Link>
                                    </li>
                                    <li className="nav-item active">
                                        <Link onClick={onChildClick} className="nav-link">
                                            <span className="icon"><i className="material-icons-outlined">play_circle</i></span>
                                            <span className="menu-title">Plays</span>
                                        </Link>
                                    </li>
                                    <li className="nav-item">
                                        <Link onClick={onChildClick} className="nav-link">
                                            <span className="icon"><i className="material-icons-outlined">widgets</i></span>
                                            <span className="menu-title">Category</span>
                                        </Link>
                                    </li>
                                    {/* <li className="nav-item">
                                            <Link to="https://play920.atmegame.com/start" target='_blank' className="nav-link">
                                                <span className="icon"><i className="material-icons-outlined">sports_esports</i></span>
                                                <span className="menu-title">Games</span>
                                            </Link>
                                        </li>
                                        <li className="nav-item">
                                            <Link to="https://play920.atmequiz.com/start" target='_blank' className="nav-link">
                                                <span className="icon"><i className="material-icons-outlined">quiz</i></span>
                                                <span className="menu-title">Quiz</span>
                                            </Link>
                                        </li> */}
                                </ul>
                            </div>
                        </aside>
                        <aside className="widget-area">                        
                            <div className="widget widget-page-you-like">
                                <h3 className="widget-title mb-3">Trending People</h3>
                                {trendingPeople?.map((data,index)=>
                                <article key={index} className="item">
                                    <Link onClick={onChildClick}  className="thumb">
                                        <img src={s3Url+data?.key} alt="user"/>
                                    </Link>    
                                    <div className="place">{index+1}</div>
                                    <div className="info">
                                        <div>
                                            <h4 className="title">
                                                <Link onClick={onChildClick}>{data?.userName}</Link>
                                            </h4>
                                            <span>{data?.coins} coins</span>
                                        </div>
                                    </div>
                                    </article>)}
                            </div>                        
                            {/* <div className="widget widget-suggested-groups">
                                <h3 className="widget-title">Trending Category</h3>
                                <article className="item">
                                    <a href="#" className="thumb">
                                        <img src="../assets/images/user/user-11.jpg" className="rounded-circle" alt="image"/>
                                    </a>      
                                    <div className="info">
                                        <h4 className="title">
                                            <a href="#">Agriculture</a>
                                        </h4>
                                        <span>5000+ Members</span>                                        
                                    </div>
                                </article>
                                <article className="item">
                                    <a href="#" className="thumb">
                                    <img src="../assets/images/user/user-12.jpg" className="rounded-circle" alt="image"/>
                                    </a>  
    
                                    <div className="info">
                                        <h4 className="title">
                                            <a href="#">Beauty</a>
                                        </h4>
                                        <span>5000+ Members</span>                                        
                                    </div>
                                </article>
                                <article className="item">
                                    <a href="#" className="thumb">
                                        <img src="../assets/images/user/user-13.jpg" className="rounded-circle" alt="image"/>
                                    </a>      
                                    <div className="info">
                                        <h4 className="title">
                                            <a href="#">Photography</a>
                                        </h4>
                                        <span>5000+ Members</span>                                        
                                    </div>
                                </article>
                            </div> */}
                        </aside>
                    </div>
                </div>
            </div>
        </div>

    </> 
); 
} 
export default SideBarLogin;