import API from "./api"
import { Bidding } from "./endpoints"

export const saveBidding = (data)=>{
    return API.post(`${Bidding}`,data)
}


export const getFilterBidding = (data)=>{
    return API.put(`${Bidding}/getFilterBidding`,data)
}