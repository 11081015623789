import React from 'react';
import { Link } from 'react-router-dom';
import Button from '@mui/material/Button';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import Fade from '@mui/material/Fade';

function HeaderLogin({ onChildClick }) {

    const [anchorEl, setAnchorEl] = React.useState(null);
    const open = Boolean(anchorEl);
    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
    };
    const handleClose = () => {
        setAnchorEl(null);
    };

    return (
        <>

            <div className="navbar-area">
                <div className="main-navbar">
                    <nav className="navbar navbar-expand-lg navbar-light">
                        <Link onClick={onChildClick} className="navbar-brand d-flex align-items-center">
                            <img src="../assets/images/logo.svg" alt="logo" />
                        </Link>

                        <div className="collapse navbar-collapse" id="navbarSupportedContent">
                            <div className="search-box m-auto">
                                <form>
                                    <input type="text" onClick={onChildClick} className="input-search" placeholder="Search Users..." />
                                    <button type="submit"><i className="ri-search-line"></i></button>
                                </form>
                            </div>

                            <div className="others-options d-flex align-items-center">
                                <div className="option-item">
                                    <div className="profile-nav-item">
                                        <div className="dropdown-bs-toggle">
                                            <div className="menu-profile cursor" >
                                                <img src="../assets/images/profile.svg" className="rounded-circle cursor" alt="user" onClick={onChildClick} />
                                                <span className="name cursor" onClick={onChildClick}>Login</span>
                                                <span className='name ps-5 cursor'>
                                                        <Button
                                                            id="fade-button"
                                                            aria-controls={open ? 'fade-menu' : undefined}
                                                            aria-haspopup="true"
                                                            aria-expanded={open ? 'true' : undefined}
                                                            onClick={handleClick}
                                                            variant='white'
                                                        >
                                                          <i className="material-icons-outlined me-1">menu</i>  Menu
                                                        </Button>
                                                        <Menu
                                                            id="fade-menu"
                                                            MenuListProps={{
                                                                'aria-labelledby': 'fade-button',
                                                            }}
                                                            anchorEl={anchorEl}
                                                            open={open}
                                                            onClose={handleClose}
                                                            TransitionComponent={Fade}
                                                        >
                                                            <MenuItem onClick={handleClose}><Link target='_blank' to="/privacypolicy">Privacy Policy</Link></MenuItem>
                                                            <MenuItem onClick={handleClose}><Link target='_blank' to="/termsandconditions">Terms and Conditions</Link></MenuItem>
                                                            <MenuItem onClick={handleClose}><Link target='_blank' to="/coinpolicy">Pixalive Coin Policy</Link></MenuItem>
                                                            <MenuItem onClick={handleClose}><Link target='_blank' to="/aboutus">About Us</Link></MenuItem>
                                                            <MenuItem onClick={handleClose}><Link target='_blank' to="/contactus">Contact Us</Link></MenuItem>
                                                            <MenuItem onClick={handleClose}><Link target='_blank' to="/returnpolicy">Return, Cancellation, <br/> and Refund Policy </Link></MenuItem>
                                                        </Menu>
                                                </span>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </nav>
                </div>
            </div>

            {/* 
            <div className="modal fade" id="loginModal" tabIndex="-1" role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true">
                <div className="modal-dialog modal-dialog-centered" role="document">
                    <div className="modal-content">
                        <div className="login-form">
                            <button type="button" className="close" data-dismiss="modal" aria-label="Close"> 
                                <span className="material-icons-round">close</span>
                            </button>
                            <h2>Login</h2>        
                            <form>
                                <div className="form-group">
                                    <label>Phone Number</label>
                                    <input type="text" placeholder='Enter phone number' className="form-control"/>
                                </div>        
                                <div className="form-group">
                                    <label>Password</label>
                                    <input type="password" placeholder='Enter Password' className="form-control"/>
                                </div>        
                                <div className="remember-me-wrap d-flex justify-content-between align-items-center">
                                    <p>
                                        <input type="checkbox" id="test1"/>
                                        <label htmlFor="test1">Remember me</label>
                                    </p>        
                                    <div className="lost-your-password-wrap">
                                        <a  data-toggle="modal" data-target="#Forgotpassword" className="lost-your-password">Forgot password ?</a>
                                    </div>
                                </div>
                                <button type="submit" className="default-btn">Login</button>
                                <div className="signup-text-info">Not a member yet? <a href="#a"> Sign Up</a>.</div>
                    
                            </form>
                        </div>
                        
                    </div>
                </div>
            </div> */}
            {/* <div className="modal fade" id="Forgotpassword" tabIndex="-1" role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true">
                <div className="modal-dialog modal-dialog-centered" role="document">
                    <div className="modal-content">
                        <div className="login-form">
                            <button type="button" className="close" data-dismiss="modal" aria-label="Close"> 
                                <span className="material-icons-round">close</span>
                            </button>
                            <h2>Login</h2>        
                            <form>                                      
                                <div className="form-group">
                                    <label>Password</label>
                                    <input type="password" placeholder='Enter Password' className="form-control"/>
                                </div>        
                                <div className="remember-me-wrap d-flex justify-content-between align-items-center">
                                    <p>
                                        <input type="checkbox" id="test1"/>
                                        <label htmlFor="test1">Remember me</label>
                                    </p>        
                                    <div className="lost-your-password-wrap">
                                        <a href="#" className="lost-your-password">Forgot password ?</a>
                                    </div>
                                </div>
                                <button type="submit" className="default-btn">Login</button>
                                <div className="signup-text-info">Not a member yet? <a href="#a"> Sign Up</a>.</div>
                    
                            </form>
                        </div>
                        
                    </div>
                </div>
            </div> */}



        </>
    );
}

export default HeaderLogin;