import API from "./api"
import { Post } from "./endpoints"

export const getFilterPost = (data) => {
    return API.put(`${Post}/getFilterPostForWeb`, data)
}

export const getSinglePost = (data) => {
    return API.get(`${Post}/getSinglePost`, { params: { _id: data } })
}


export const getCategoryBasedPostCount = (data) => {
    return API.put(`${Post}/getCategoryBasedPostCount`, data)
}

export const savePost = (data) => {
    return API.post(`${Post}`, data);
};

export const updatePost = (data) => {
    return API.put(`${Post}`, data);
};

export const updateBlockPost = (data) => {
    return API.put(`${Post}/updatePostBlockUsers`, data)
}

export const getPostComments = (data) => {
    return API.get(`${Post}/getPostComments`, { params: data })
}


export const savePostComments = (data) => {
    return API.post(`${Post}/createPostComments`, data)
}


export const updatePostReport = (data) => {
    return API.put(`${Post}/updatePostReport`, data)
}

export const deletePost = (data) => {
    return API.delete(`${Post}`, { params: { _id: data } })
}

export const getSinglePostForShare = (data) => {
    return API.get(`${Post}/getSinglePostForShare`, { params: { _id: data } })
}
