
import { useEffect, useState } from 'react';
import Header from '../../components/Header';
import SideBar from '../../components/SideBar';
import { Link } from 'react-router-dom';
import { getFilterCategory, getFollowCategoryDetails } from '../../api/category';
import { getSingleUser, updateUserCategory } from '../../api/user';
import { toast } from 'react-toastify';
import { getUserId } from '../../utils/Storage';

function Category() {
    const [category, setCategory] = useState([]);
    const [next, setNext] = useState(0);
    const [user, setUser] = useState()
    const [followCategory, setFollowCategory] = useState([]);
    const [followingCategory, setFollowingCategory] = useState([]);
    const [stateChanged, setStateChanged] = useState(false);
    const [categoryDetails, setCategoryDetails] = useState();
    const [scroll, setScroll] = useState(false);
    const [reload,setReload]=useState(false);
    const [count,setCount]=useState(0)

    useEffect(() => {
        getFilterCategoryList()
    }, [next])


    useEffect(() => {
        if (stateChanged) {
            handleSubmit()
            setStateChanged(false)
        }
    }, [followCategory])

    useEffect(() => {
        userDetails();
        followCategoryDetails();
        window.addEventListener('scroll', handleScroll);
    }, [])

    const handleScroll = () => {
        const value = window.innerHeight + window.scrollY >=
            document.documentElement.scrollHeight - 200
        setScroll(value)
    };
    useEffect(() => {
        if (scroll) {
            loadMore();
        }
    }, [scroll])


    const userDetails = () => {
        const userId = getUserId()
        getSingleUser(userId).then(res => {
            const data = res?.data?.result?.category
            const categoryArray = data.map(x => x._id);
            setFollowingCategory(categoryArray);           
            setUser(res?.data?.result)
        }).catch(err => {
            console.log(err);
        })
    }

    const loadMore = () => {
        let nextCategory = next;
        nextCategory = nextCategory + 9;
        if (count <= nextCategory) {
            setReload(true)
        }
        else{
            setNext(nextCategory);
        }
    };

    const data = {
        limit: 9,
        page: next,
    };

    const getFilterCategoryList = () => {
        getFilterCategory(data)
            .then((res) => {
                const categoryList = res?.data?.result?.categoryList;
                setCategory([...category, ...categoryList]);
                setCount(res?.data?.result?.categoryCount)
            })
            .catch((err) => {
                console.log(err);
            });
    };


    const handleFollowCategory = (data) => {
        const newFollowCategory = [...followingCategory, data]
        setFollowCategory(newFollowCategory)
        setStateChanged(true)
    }
    const handleFollowingCategory = (data) => {
        if (followingCategory.length > 5) {
            const filterFollowCategory = followingCategory.filter(x => x !== data)
            setFollowCategory(filterFollowCategory)
            setStateChanged(true)
        }
        else {
            toast.warning('Please follow atleast five Categories')
        }
    }

    const handleSubmit = () => {
        const data = { _id: user?._id, category: followCategory }
        updateUserCategory(data).then(res => {
            toast.success(res?.data?.message)
            userDetails();
            followCategoryDetails();
        }).catch((err) => {
            console.log(err);
        })

    }

    const followCategoryDetails = () => {
        getFollowCategoryDetails().then(res => {
            setCategoryDetails(res?.data?.result)
        }).catch((err) => {
            console.log(err);
        })
    }

    return (
        <>
            <Header />
            <div className="content-page-box-area">
                <div className="row">
                    <SideBar menu={'Category'} />
                    <div className="col-lg-9 col-md-12">
                        <div className="content-page-box-area">
                            <div className="page-banner-box bg-5">
                                <h3>Category</h3>
                            </div>
                            <div className="row justify-content-center">
                                {category?.map((data, index) =>
                                    <div key={index} className="col-lg-4 col-sm-6">
                                        <div className="single-groups-card">
                                            <div className="groups-content">
                                                <div className="groups-info d-flex justify-content-between align-items-center">
                                                    <Link to={{ pathname: '/SingleCategory', search: `id=${data?._id}` }}>
                                                        <img src={data?.image} alt="category" />
                                                    </Link>
                                                    <div className="text ">
                                                        <h3><Link to={{ pathname: '/SingleCategory', search: `id=${data?._id}` }}>{data?.category}</Link></h3>
                                                        <span>Public</span>
                                                    </div>
                                                </div>
                                                <ul className="statistics">
                                                    <li>
                                                        <Link>
                                                            {categoryDetails?.post?.find(x => x?._id === data?._id) ? (
                                                                <span className="item-number">
                                                                    {categoryDetails.post.find(x => x._id === data._id)?.followers}
                                                                </span>
                                                            ) : (
                                                                <span className="item-number">0</span>
                                                            )}
                                                            <span className="item-text">Post</span>
                                                        </Link>
                                                    </li>
                                                    <li>
                                                        <Link>
                                                            {categoryDetails?.user?.find(x => x?._id === data?._id) ? (
                                                                <span className="item-number">
                                                                    {categoryDetails.user.find(x => x._id === data._id)?.followers}
                                                                </span>
                                                            ) : (
                                                                <span className="item-number">0</span>
                                                            )}
                                                            <span className="item-text">Following</span>
                                                        </Link>
                                                    </li>
                                                </ul>
                                                {followingCategory?.includes(data?._id) ?
                                                    <div className="join-groups-btn-following"> <button onClick={() => handleFollowingCategory(data?._id)} type="button"><i className="material-icons-outlined">check</i>Following</button></div> :
                                                    <div className="join-groups-btn">  <button onClick={() => handleFollowCategory(data?._id)} type="button"><i className="material-icons-outlined">add</i>Follow</button></div>}
                                            </div>
                                        </div>
                                    </div>)}
                                    {reload?
                                <div className='form-text text-danger text-center'>No Categories</div>:null}
                            </div>
                        </div>
                    </div>
                </div>
            </div>

        </>
    );
}
export default Category;