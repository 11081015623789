import React from 'react';
import './Termsofuse.css'

const Termsofuse = () => {
    return (
        <div className='Termsofuse_container'>
            <div className='Termsofuse_contents'>
                <h3><b>1. INTRODUCTION</b></h3>

                These Terms and Conditions of Use ("Terms and Conditions") govern your use of the "Pixalive" mobile application ("Platform") provided by "Tripalive.Me Technology Private Limited," a company registered under the Indian Companies Act 2013.
                Our Platform (as described in detail below) and these Terms and Conditions comply with the Indian Penal Code, 1860, and the Information Technology Act, 2000, including all amendments and rules under it. By using our Platform, you accept and agree to these Terms and Conditions. However, please note that we do not represent compliance with the laws of any country other than India. Before using our Platform, ensure that you are permitted to do so in your jurisdiction.
                You are required to adhere to certain rules while using our Platform, which are listed in this document. Please read these Terms and Conditions carefully. By using our Platform, you indicate your agreement to these Terms and Conditions. Additionally, if you are using this Platform outside of India, please comply with your local laws.
                These Terms and Conditions govern the use of our Platform.
                Our Privacy Policy also governs your use of our Platform and explains how we collect, protect, and disclose information resulting from your use of our web pages.
                Your agreement with us includes these Terms and Conditions as well as our Privacy Policy ("Agreements"). By acknowledging that you have read and understood the Agreements, you agree to be bound by them.
                If you do not agree with or cannot comply with the Agreements, you may not use the Platform. Please inform us by emailing support@pixalive.me so that we can attempt to find a solution. These Terms apply to all visitors, users, and others who wish to access or use the Platform.

                <h3><b>2. COMMUNICATIONS</b></h3>

                When you use our Platform, you agree to subscribe to newsletters, marketing or promotional materials, and other information we may send. However, you have the option to opt out of receiving any or all these communications from us. To do so, you can follow the unsubscribe link provided in the communication or send an email to support@pixalive.me

                <h3><b>3. CONTESTS, SWEEPSTAKES, AND PROMOTIONS</b></h3>

                Any contests, sweepstakes, or other promotions (collectively referred to as "Promotions") that are made available through the Platform may have separate rules that govern them, which are distinct from these Terms and Conditions. If you choose to participate in any Promotions, we recommend reviewing the relevant rules along with our Privacy Policy. In the event of any conflict between the rules of a Promotion and these Terms and Conditions, the rules of the Promotion will prevail.

                <h3><b>4. CONTENT</b></h3>

                Our Platform allows you to post, link, store, share, and make certain information, text, graphics, videos, or other materials ("Content") available. You are responsible for the Content you post on or through the Platform, including its legality, reliability, and appropriateness.
                By posting Content on or through the Platform, you represent and warrant that:

                <br></br><br></br>a)	The Content is yours (you own it) and/or you have the right to use it, as well as the right to grant us the rights and license as provided in these Terms and Conditions.
                <br></br><br></br>b)	The posting of your Content on or through the Platform does not violate the privacy rights, publicity rights, copyrights, contract rights, or any other rights of any person or entity. We reserve the right to terminate the account of anyone found to be infringing on a copyright.
                <br></br><br></br>c)	You retain all your rights to any Content you submit, post, or display on or through the Platform, and you are responsible for protecting those rights. We take no responsibility and assume no liability for the Content you or any third-party posts on or through the Platform. However, by posting Content using the Platform, you grant us the right and license to use, modify, publicly perform, publicly display, reproduce, and distribute such Content on and through the Platform.
                <br></br><br></br>d)	You agree that this license includes the right for us to make your Content available to other users of the Platform, who may also use your Content subject to these Terms and Conditions.
                <br></br><br></br>e)	You agree that sharing "Pixalive moments" video content on other social media platforms is solely dependent on your desire and needs, and it is your responsibility. The platform (Pixalive) will not be held responsible for this action.
                <br></br><br></br>f)	Pixalive has the right, but not the obligation, to monitor and edit all Content provided by users. Additionally, Content found on or through this Platform is the property of Pixalive or used with permission. You may not distribute, modify, transmit, reuse, download, repost, copy, or use said Content, whether in whole or in part, for commercial purposes or personal gain, without express advance written permission from us.

                <h3><b>5. PROHIBITED USES</b></h3>

                You may only use our Platform for lawful purposes and in accordance with the Terms and Conditions stated herein. By using the Platform, you agree not to:

                <br></br><br></br>a.	Violate any applicable national or international laws or regulations.
                <br></br><br></br>b.	Exploit, harm, or attempt to exploit or harm minors in any way, including exposing them to inappropriate content.
                <br></br><br></br>c.	Transmit or solicit the sending of any advertising or promotional material, including "junk mail," "chain letters," "spam," or any similar solicitations.
                <br></br><br></br>d.	Impersonate or attempt to impersonate the Company, a Company employee, another user, or any other person or entity.
                <br></br><br></br>e.	Infringe upon the rights of others, engage in illegal, threatening, fraudulent, or harmful activities, or use the Platform for any unlawful, illegal, fraudulent, or harmful purpose or activity.
                <br></br><br></br>f.	Engage in conduct that restricts or inhibits anyone's use or enjoyment of the Platform, or that may harm or offend the Company, other users of the Platform, or expose them to liability.

                <br></br><br></br>Additionally, you agree not to:
                <br></br><br></br>g.	Use the Platform in any way that could disable, overburden, damage, or impair the Platform or interfere with any other party's use of the Platform, including their ability to engage in real-time activities through the Platform.
                <br></br><br></br>h.	Use any robot, spider, or other automatic device, process, or means to access the Platform for any purpose, including monitoring or copying any material on the Platform.
                <br></br><br></br>i.	Use any manual process to monitor or copy any material on the Platform or for any other unauthorized purpose without our prior written consent.
                <br></br><br></br>j.	Use any device, software, or routine that interferes with the proper working of the Platform.
                <br></br><br></br>k.	Introduce any viruses, Trojan horses, worms, logic bombs, or other materials that are malicious or technologically harmful.
                <br></br><br></br>l.	Attempt to gain unauthorized access to, interfere with, damage, or disrupt any part of the Platform, the server on which the Platform is stored, or any server, computer, or database connected to the Platform.
                <br></br><br></br>m.	Conduct a denial-of-service attack or a distributed denial-of-service attack on the Platform.
                <br></br><br></br>  n.	Attempt to interfere with the proper working of the Platform in any other way.

                <h3><b>6. ANALYTICS</b></h3>

                We may utilize third-party Service Providers to monitor and analyze the usage of our Platform. As of the current date, we have not engaged any such service provider.

                <h3><b>7. ACCOUNTS</b></h3>

                By creating an account with us, you affirm that you are above the age of 18 and that the information you provide us is accurate, complete, and up-to-date at all times. Providing inaccurate, incomplete, or outdated information may result in the immediate termination of your account on the Platform.
                You are responsible for maintaining the confidentiality of your account and password, which includes restricting access to your computer and/or account. You agree to take responsibility for all activities or actions that occur under your account and/or password, whether your password is associated with our Platform or a third-party service. If you become aware of any security breaches or unauthorized use of your account, you must notify us immediately.
                You are not permitted to use another person's or entity's name as your username unless you have the appropriate authorization to do so. Additionally, you may not use a name or trademark that is lawfully restricted to another person or entity. Offensive, vulgar, or obscene names are also prohibited as usernames.
                We reserve the right to refuse service, terminate accounts, remove, or modify content, or cancel orders at our sole discretion.

                <h3><b>8. DEVICE SECURITY</b></h3>

                We have implemented measures to ensure the security of our Platform. However, we cannot guarantee that our Platform is completely immune to hacking and virus attacks. It is your responsibility to have appropriate anti-malware and antivirus software installed on your mobile device and computer to ensure their safety.
                While we take every precaution to secure your use of the Platform, please be aware that we cannot anticipate all forms of attacks on our Platform. As a best practice, you should ensure that your mobile device and computer are not misused or tampered with in any way.

                <h3><b>9. CONTENT GUIDELINES</b></h3>

                We actively remove content which is not allowed on our Platform and violates our Content Guidelines as well as applicable Indian Laws. If such impermissible Content is brought to our attention, we may proceed to take the impermissible content down or ban user Accounts or both. If in the event you come across any content which violated the present Guidelines, we encourage you to report it. We as a Company, emphasize on the importance of freedom of expression, but in no manner whatsoever do we solicit or support Content that intends to cause discomfort, spread what may be under the ambit and purview of hate speech and abuse, promotes violence and illegal activities or impedes the creator or artist ecosystem on the Platform.

                <br></br><br></br>I. Adherence to Applicable Laws
                All content, including without limitation, content that is uploaded, posted, commented on, or shared by you on our Platform, must adhere to the laws of India, including without limitation, the Indian Penal Code,1860 and, the Information Technology Act, 2000 along with all rules and amendments made under such laws. We cooperate with legal authorities and follow enforcement mechanisms in cases of breach of applicable laws. Content may not be uploaded, posted, commented on, or shared by you if it threatens the unity, integrity, defence, security or sovereignty of India, friendly relations with foreign States, or public order. You may not post or engage with content that is insulting to any other nation, incites the commission of any offences or prevents the investigation of offences.

                <br></br><br></br>II. Nudity and Pornography
                Content which contains the following is prohibited on the Platform and will be considered as a strict violation of these Guidelines:
                <br></br><br></br>A.	Obscene, sexually explicit, pornographic, or nude material or images/videos that expose private parts (sexual organs, female breasts and nipples, male penis, buttocks) and/or portray sexual activities;
                <br></br><br></br>B.	Videos or Images of people in compromising positions or content which portrays sexual actions or fetish or erotic intent or sexual arousal;
                <br></br><br></br>C.	Sextortion or revenge pornography;
                <br></br><br></br>D.	Bestiality or zoophilia;
                <br></br><br></br>E.	Content that exploits or endangers any person (for example, listing of phone numbers, or other personal information aimed at any exploitation or endangerment of a person including for the purposes of encouraging or soliciting prostitution or escort services);
                <br></br><br></br>F.	Content that is paedophile or related to child pornography (including without limitation, creation, promotion, glorification, transmission or browsing of child pornography); or
                <br></br><br></br>G.	Content that is indecent, immoral, or related to on rape, sexual objectification, non-consensual activities and molestation.
                <br></br><br></br>III. Harassment or Bullying
                Content which qualifies as a violation of these Guidelines includes, but is not limited to:
                <br></br><br></br>A.	Posting abusive language or curse words, morphed images, and/or malicious recordings.
                <br></br><br></br>B.	Objectifying, insulting, or harassing someone based on their race, caste, colour, disabilities, religion, sexual preferences and /or making sexual advances or otherwise engaging in sexual misconduct will not be tolerated on this Platform. Similarly, extorting or blackmailing any individual otherwise or based on the abovementioned content is strictly prohibited.
                <br></br><br></br>C.	If someone blocks you from their account, kindly do not try and contact them from a different account. If a user does not want to engage with you on the Platform, we urge you to respect the same and vice versa.
                <br></br><br></br>D.	Any image or information of a person that is shared without their consent with an intent to harass, distress or endanger them.
                <br></br><br></br>E.	False information posted to harass someone for financial gain, or cause any injury to them
                <br></br><br></br>F.	However, if a matter involves critical discussion and deliberation of such individuals who are featured in the news or tend to have a large public audience, we may allow it subject to the Terms and these Guidelines.

                <br></br><br></br>IV. Intellectual Property
                We aim to safeguard intellectual property rights and consider violations of such rights as serious misconduct. All content such as literary, musical, dramatic, artistic, sound recordings, cinematographic works, is subject to intellectual property protection.
                Posting content on the Platform which is not original and is copied from an individual/organisation who owns the intellectual property rights in such content/works is not allowed. Any content which violates the intellectual property rights of third parties will be taken down and strict action will be taken against users who are repeat defaulters. If you wish to reshare such content from within the Platform, kindly do not remove any attributions, watermarks and original captions which state the authentic source of the content. In addition to this, please take the required permissions and give due credits to your fellow users or any other organisation/individual who own the intellectual property rights in such content by mentioning their name and /or the original source.

                <br></br><br></br>V. Violence
                Violence includes all content that causes discomfort to our users due to the goriness in the content, such as but not limited to graphical images or videos that glorify violence and suffering, or intends to incite violence, depiction of physical violence or animal cruelty. Content which promotes dangerous and illegal activities, or praises individuals, groups or leaders involved in terrorism, organized violence or criminal activities is strictly prohibited.
                Educative or informative content pertaining to violence may be allowed on the Platform. Violent content on the Platform in the form of fictional set-up, martial arts may be permitted subject to these Guidelines.

                <br></br><br></br>VI. Hate speech and Propaganda
                Content that promotes violent behaviour against an individual or a group of individuals, intends to intimidate, target, or demean any particular religion, race, caste, ethnicity, community, nationality, disability (physical or mental), diseases or gender, is prohibited. Any kind of content which produces hatred or has the intention of creating or spreading hatred or hate propaganda along the lines of including, but not limited to religion, caste, ethnicity, community, sexual orientation, or gender identity is also not allowed. We do not entertain content that spreads discrimination, intends to justify violence based on the above-mentioned attributes and refers to an individual or a group of individuals as inferior in any sense or with negative connotations.
                We urge you to refrain from incendiary commentary and publishing theories or hateful ideologies that may cause outrage to our users and influence them negatively. We may permit such content which intends to raise awareness about these issues or challenge it, subject to clear intention of posting such content on the Platform.

                <br></br><br></br>VII. Abuse, Self-Injury or Suicide
                We do not permit content that exhibits suicide or such tendencies, triggers self-injury and harm or encourages participation in dangerous activities. Posting any content that relates to physical, mental, sexual, or psychological maltreatment, neglect, or abuse of any person, be it that of a child or an adult, is strictly condemned. Content showcasing self-harm, glorifying self-injury or suicide or even stating instructions on how to commit self-harm through any means is not allowed. Further, content that identifies, tags, attacks, and negatively targets or makes fun of victims and survivors of psychological/physical maltreatment, abuse, self-injury or domestic abuse or any other form of violence is prohibited.
                We allow content that intends to provide support, aid and relief to those undergoing such serious issues. We also allow users to share their experiences which may provide coping mechanisms for those who need help, subject to the intent of posting such content.

                <br></br><br></br>VIII. Illegal Activities
                We have zero-tolerance for content that advocates or promotes illegal activities. We prohibit content related to organized crime, criminal activities, promotion/sale/use of weapons, firearms and explosives, violence, or terrorist activities. Sale of illegal goods or services, regulated goods, drugs and controlled substances, and soliciting or selling of sexual services is strictly forbidden.
                We do not permit content that is harassing, harmful, or abusive to children.
                Users may not post content that is related to or encourages money laundering or gambling.
                Users are not allowed to post content that displays tutorials or instructions or educates the users about illegal and prohibited activities including, but not limited to participating in criminal activities, making bombs, or encouraging or doing or trading in drugs. Do not use our Platform to solicit or facilitate any transaction or gift involving such goods and services which are declared illegal by the Government of India
                Impersonating another person (such as your family, friends, celebrities, brands, or any other individuals/organisations) and distributing false or misleading information on our Platform in order to make personal or financial gain will be considered fraud.
                Content that contains computer viruses, malware, or any other computer code designed to restrict the functionality of any computer resource cannot be uploaded on the Platform.

                <br></br><br></br>IX. Non-consensual (Personal) Content
                Posting or misusing personal content or data or information of another person, including pictures or videos of other people who have not given express consent to such material being posted, is not allowed. Do not post anyone’s personal or intimate photos or videos without their permission or consent. Do not post content that is invasive of anyone’s privacy. We will remove such content.
                Revealing someone’s personal data or sensitive personal information, including without limitation: contact information, address, financial information, Aadhar Number, healthcare information, sexual or intimate images and videos, passport information, or threatening someone to reveal or use such information, will be considered as harassment, and such activities are strictly unacceptable.

                <br></br><br></br>X. Misinformation
                We aim to combat the spread of misinformation on our Platform. Any kind of content which spreads deliberate misinformation, disinformation, hoaxes, or fake propaganda, with the intent to mislead the users or general public at large, is not permitted. We prohibit posting of content that exaggerates an existing piece of news by introducing non-factual elements into it.
                We do not allow content on the Platform that misleads users or attempts to create an avenue for fabricating content, or is defamatory, libellous, or attempts to damage someone’s reputation, or hurt their financial or political standing based on incorrect information. We engage third party fact checkers to combat the menace of fake news basis which we proactively warn our users that a piece of content is found to be factually incorrect. We urge you to take the same into consideration and act accordingly.
                However, we do not confuse fake news with any satire or parodies. We allow for such content on the Platform provided the content does not mislead other users and the intent behind the same is not to spread false information.

                <h3><b>10. PLATFORM SECURITY</b></h3>

                <br></br><br></br>I. Reporting
                When you see any content or activity that violates these Guidelines, please tap or click on the REPORT button. When you report content, we will process and review the content. If we find the content or activity to be unsuitable for our Platform, we will remove it. If you believe any content on the Platform infringes your rights as a copyright holder, you can file a copyright claim by sending an email to support@pixalive.me and the same will be assessed for further review and action. There may be such content on the Platform which you do not like but the same does not violate these Guidelines. In that case, we request you to either unfollow or block such users.

                <br></br><br></br>II. Intermediary status and review of content
                We are an intermediary as per the applicable laws. We do not control what our user's post, comment, share or say on the Platform and are not responsible for their (or your) actions (whether online or offline). We are not responsible for services and features offered by others even if you access them through our Platform. Our responsibility and liability for anything that happens on our Platform are strictly governed and limited by the laws of India.
                We expect you to be responsible for what you post and what you see. If any of our users report your content to be against these Guidelines, we may take enforcement actions as necessary.

                <br></br><br></br>III. Grievance officer
                Pixalive has a Grievance Officer to address your concerns regarding data safety, privacy, and other Platform usage concerns.
                You may contact Email: support@pixalive.me
                Note: - This email is solely for use by police and investigating agencies. This is not the correct email ID for user-related issues. For all user-related grievances, kindly contact us at support@pixalive.me

                <br></br><br></br>IV. Right to Challenge
                In case the content you upload or post, or your activity is reported by another user and removed from our Platform, we will notify you of such removal and our reasons for the same. If you believe that your content has been unfairly removed, you may write to us at support@pixalive.me to challenge the removal. We may review the content again and determine if it may be reposted on the Platform.

                <br></br><br></br>V. Our actions against violators
                We take strict and prompt action against those who violate these Guidelines. If your profile is reported for violating these Guidelines, then your profile may be suspended temporarily. In case of repeated breach of these Guidelines, we may be compelled to terminate your account with us permanently and block you from registering with us.
                If required, we will cooperate with legal authorities and law enforcement mechanisms. Please note that we are under no obligation to assist you.

                <h3><b>11. INTELLECTUAL PROPERTY</b></h3>

                The Platform and its original content (excluding Content provided by users), features and functionality are and will remain the exclusive property of Pixalive and its licensors. The Platform is protected by copyright, trademark, and other laws of and foreign countries. Our trademarks may not be used in connection with any product or service without the prior written consent of Pixalive.

                <h3><b>12. ERROR REPORTING AND FEEDBACK</b></h3>

                You may provide us either directly at support@pixalive.me or via third party sites and tools with information and feedback concerning errors, suggestions for improvements, ideas, problems, complaints, and other matters related to our Platform ("Feedback"). You acknowledge and agree that:
                you shall not retain, acquire, or assert any intellectual property right or other right, title or interest in or to the Feedback;
                Company may have development ideas similar to the Feedback;
                <br></br><br></br>Feedback does not contain confidential information or proprietary information from you or any third party; and
                Company is not under any obligation of confidentiality with respect to the Feedback. In the event the transfer of the ownership to the Feedback is not possible due to applicable mandatory laws, you grant Company and its affiliates an exclusive, transferable, irrevocable, free-of-charge, sub-licensable, unlimited, and perpetual right to use (including copy, modify, create derivative works, publish, distribute, and commercialize) Feedback in any manner and for any purpose.
                Our Platform may contain links to third party web sites or services that are not owned or controlled by the Company.

                <h3><b>13. LINKS TO OTHER WEBSITES</b></h3>

                The Company has no control over, and assumes no responsibility for the content, privacy policies, or practices of any third-party websites or services. We do not warrant the offerings of any of these entities/individuals or their websites.
                You acknowledge and agree that company shall not be responsible or liable, directly, or indirectly, for any damage or loss caused or alleged to be caused by or in connection with use of or reliance on any such content, goods or services available on or through any such third-party web sites or services.
                We strongly advise you to read the terms and conditions and privacy policies of any third-party web sites or services that you visit.

                <h3><b>14. DISCLAIMER OF WARRANTY</b></h3>

                These services and the Platform provided by the Company is on an “as is” and “as available” basis. Company makes no representations or warranties of any kind, express or implied, as to the operation of their services, or the information, content or materials included therein. You expressly agree that your use of these services, their content, and any services or items obtained from us is at your sole risk.
                Neither company nor any person associated with company makes any warranty or representation with respect to the completeness, security, reliability, quality, accuracy, or availability of the Platform. Without limiting the foregoing, neither company nor anyone associated with company represents or warrants that the Platform, their content, or any services or items obtained through the Platform will be accurate, reliable, error-free, or uninterrupted, that defects will be corrected, that the Platform or the server that makes it available are free of viruses or other harmful components or that the Platform or any services or items obtained through the Platform will otherwise meet your needs or expectations.
                Company hereby disclaims all warranties of any kind, whether express or implied, statutory, or otherwise, including but not limited to any warranties of merchantability, non-infringement, and fitness for particular purpose.
                The foregoing does not affect any warranties which cannot be excluded or limited under applicable law.

                <h3><b>15. LIMITATION OF LIABILITY</b></h3>

                We do not assume any liability with respect to any loss or damage, arising directly or indirectly due to any inaccuracy or incompleteness of any information or a breach of any warranty or guaranty due to the actions of any user of the Platform
                The Platform and services, are provided on "as is" and "as available" basis without any representation or warranties, express or implied except otherwise specified in writing. We do not warrant the quality of the services or the Platform including its uninterrupted, timely, secure, or error-free provision, continued compatibility on any device, or correction of any errors.
                In no event shall we, or any of our affiliates, successors, and assigns, and each of their respective investors, directors, officers, employees, agents, service providers, and suppliers be liable for any special, incidental, punitive, direct, indirect, or consequential damages suffered as a consequence of a breach of the Terms by another user or arising out of the use of or the reliance on the Platform.

                <h3><b>16. INDEMNIFICATION</b></h3>

                You agree to indemnify, defend, and hold harmless us, and our subsidiaries, affiliates and agents and their respective officers, directors, employees, successors and assigns from and against any claim, proceeding, loss, damage, liability, cost, demand or expense (including but not limited to attorney's fees) of any kind arising out of:
                <br></br><br></br>I. your access to or use of the Platform;
                <br></br><br></br>II. any breach by you of your obligations under this Agreement;
                <br></br><br></br>III. your violation of the rights of any third party, including any infringement of intellectual property, or of any privacy or consumer protection right;
                <br></br><br></br>IV. any violation of law or contractual obligation and any claims, demands, notices pursuant to such violation;
                <br></br><br></br>V. your negligence or wilful misconduct
                This obligation will survive the termination of our Terms.

                <h3><b>17. TERMINATION</b></h3>

                We may terminate or suspend your account and bar access to the Platform immediately, without prior notice or liability, under our sole discretion, for any reason whatsoever and without limitation, including but not limited to a breach of the Terms and Conditions.
                If you wish to terminate your account, you may simply discontinue using our Platform. All provisions of the Terms and Conditions which by their nature should survive termination shall survive termination, including, without limitation, ownership provisions, warranty disclaimers, indemnity, and limitations of liability.

                <h3><b>18. GOVERNING LAW</b></h3>

                These Terms and Conditions shall be governed and construed in accordance with the laws of India, which governing law applies to agreement without reference to conflict of Law Principles, and disputes arising in relation hereto shall be subject to the exclusive jurisdiction of the Courts at Chennai, Tamil Nadu, India. Our failure to enforce any right or provision of these Terms and Conditions will not be considered a waiver of those rights. If any provision of these Terms and Conditions is held to be invalid or unenforceable by a court, the remaining provisions of these Terms will remain in effect. These Terms and Conditions constitute the entire agreement between us regarding our Platform and supersede and replace any prior agreements we might have had between us regarding the Platform.

                <h3><b>19. CHANGES TO SERVICE</b></h3>

                We reserve the right to withdraw or amend access to our Platform, and any service or material we provide via the Platform, in our sole discretion without notice. We will not be liable if for any reason all or any part of the Platform is unavailable at any time or for any period. From time to time, we may restrict access to some parts of the Platform, or the entire Platform, to users, including registered users.

                <h3><b>20. AMENDMENTS TO TERMS</b></h3>

                We may amend Terms at any time by posting the amended terms on this site. It is your responsibility to review these Terms periodically.
                Your continued use of the Platform following the posting of revised Terms means that you accept and agree to the changes. You are expected to check this page frequently so you are aware of any changes, as they are binding on you.
                By continuing to access or use our Platform after any revisions become effective, you agree to be bound by the revised terms. If you do not agree to the new terms, you are no longer authorized to use the Platform.


                <h3><b>21. WAIVER AND SEVERABILITY</b></h3>

                No waiver by Company of any term or condition set forth in Terms and Conditions shall be deemed a further or continuing waiver of such term or condition or a waiver of any other term or condition, and any failure of Company to assert a right or provision under the Terms and Conditions shall not constitute a waiver of such right or provision.
                If any provision of the Terms and Conditions is held by a court or other tribunal of competent jurisdiction to be invalid, illegal, or unenforceable for any reason, such provision shall be eliminated or limited to the minimum extent such that the remaining provisions of the Terms and Conditions will continue in full force and effect.

                <h3><b>23. ACKNOWLEDGEMENT</b></h3>

                By using the Platform or other services provided by us, you acknowledge that you have read these Terms and Conditions and agree to be bound by them.

                <h3><b>24. CONTACT US</b></h3>

                Please send your feedback, comments, requests for technical support by email: support@pixalive.me


            </div>
        </div>
    )
}

export default Termsofuse
