import React, { useEffect, useState } from 'react';
import { getUserId } from '../utils/Storage';
import { getFilterTrendingUsers, getSingleUser, updateUserCategory } from '../api/user';
import { Link } from 'react-router-dom';
import { getTrendingVideos } from '../api/play';
import { getTrendingCategories } from '../api/category';
import { toast } from 'react-toastify';
import { acceptFollower, getAllFollowings, saveFollower } from '../api/follow';
import { s3Url } from '../utils/FileUpload';
function SideBar({ menu }) {
    const [user, setUser] = useState();
    const [trendingPeople, setTrendingPeople] = useState();
    const [videos, setVideos] = useState([]);
    const [categories, setCategories] = useState([]);
    const [followingCategory, setFollowingCategory] = useState([]);
    const [followCategory, setFollowCategory] = useState([]);
    const [stateChanged, setStateChanged] = useState(false);
    const [allFollowing, setAllFollowing] = useState([])

    useEffect(() => {
        userDetails();
        getAllFollowing()
        getTrendingPeopleList();
        getTrendingVideosList();
        getTrendingCategoriesList()
    }, [])

    useEffect(() => {
        if (stateChanged) {
            handleSubmit()
            setStateChanged(false)
        }
    }, [followCategory])


    const getTrendingPeopleList = () => {
        getFilterTrendingUsers()
            .then((res) => {
                setTrendingPeople(res?.data?.result?.trendingUserList);
            })
            .catch((err) => {
                console.log(err);
            });
    };


    const userDetails = () => {
        const userId = getUserId()
        getSingleUser(userId).then(res => {
            setUser(res?.data?.result);
            const data = res?.data?.result?.category
            const categoryArray = data.map(x => x._id);
            setFollowingCategory(categoryArray);
        }).catch(err => {
            console.log(err);
        })
    }

    const getTrendingCategoriesList = () => {
        getTrendingCategories()
            .then((res) => {
                setCategories(res?.data?.result);
            })
            .catch((err) => {
                console.log(err);
            });
    };

    const getTrendingVideosList = () => {
        const data = { limit: 3 }
        getTrendingVideos(data)
            .then((res) => {
                setVideos(res?.data?.result?.playList);
            })
            .catch((err) => {
                console.log(err);
            });
    };

    const handleFollowCategory = (data) => {
        const newFollowCategory = [...followingCategory, data]
        setFollowCategory(newFollowCategory)
        setStateChanged(true)
    }
    const handleFollowingCategory = (data) => {
        if (followingCategory.length > 5) {
            const filterFollowCategory = followingCategory.filter(x => x !== data)
            setFollowCategory(filterFollowCategory)
            setStateChanged(true)
        }
        else {
            toast.warning('Please follow atleast five Categories')
        }
    }

    const handleSubmit = () => {
        const data = { _id: user?._id, category: followCategory }
        updateUserCategory(data).then(res => {
            userDetails()
            toast.success(res?.data?.message)
        }).catch((err) => {
            console.log(err);
        })

    }

    const getAllFollowing = () => {
        const data = { id: getUserId() };
        getAllFollowings(data)
            .then((res) => {
                setAllFollowing(res?.data?.result);
            })
            .catch((err) => {
                console.log(err);
            });
    };

    const handleFollowers = (id, connect) => {
        const data = {
            follower: getUserId(),
            following: id,
            isConnect: connect === false ? true : false,
        };
        saveFollower(data)
            .then((res) => {
                getAllFollowing()
            })
            .catch((err) => {
                console.log(err);
            });

    }

    const handleRequest = (id) => {
        const data = { _id: id, isConnect: false };
        acceptFollower(data)
            .then((res) => {
                getAllFollowing()
            })
            .catch((err) => {
                console.log(err);
            });
    };

    return (
        <>

            <div className="col-lg-3 col-md-3">
                <div className="sidemenu-area-1">
                    <div className="sidemenu-body-1">
                        <div className="sidemenu-nav-1 metisMenu h-100" id="sidemenu-nav" data-simplebar>
                            <aside className="widget-area">

                                <div className="widget sticky-cls">
                                    <ul className="sidemenu-nav-1">
                                        <li className={`nav-item ${menu === 'Feed' ? 'active' : ''}`}>
                                            <Link to="/Home" className="nav-link" >
                                                <span className="icon"><i className="feather-home"></i></span>
                                                <span className="menu-title">Feed</span>
                                            </Link>
                                        </li>
                                        <li className={`nav-item ${menu === 'Plays' ? 'active' : ''}`}>
                                            <Link to="/Plays" className="nav-link" >
                                                <span className="icon"><i className="material-icons-outlined">play_circle</i></span>
                                                <span className="menu-title">Plays</span>
                                            </Link>
                                        </li>
                                        <li className={`nav-item ${menu === 'Category' ? 'active' : ''}`}>
                                            <Link to="/Category" className="nav-link">
                                                <span className="icon"><i className="material-icons-outlined">widgets</i></span>
                                                <span className="menu-title">Category</span>
                                            </Link>
                                        </li>
                                        {/* <li className={`nav-item ${menu === 'Games' ? 'active' : ''}`}>
                                            <Link to="https://play920.atmegame.com/start" target='_blank' className="nav-link">
                                                <span className="icon"><i className="material-icons-outlined">sports_esports</i></span>
                                                <span className="menu-title">Games</span>
                                            </Link>
                                        </li>
                                        <li className={`nav-item ${menu === 'Quiz' ? 'active' : ''}`}>
                                            <Link to="https://play920.atmequiz.com/start" target='_blank' className="nav-link">
                                                <span className="icon"><i className="material-icons-outlined">quiz</i></span>
                                                <span className="menu-title">Quiz</span>
                                            </Link>
                                        </li> */}
                                    </ul>
                                </div>
                            </aside>
                            <aside className="widget-area">
                                <div className="widget widget-view-profile">
                                    <div className="profile-box d-flex justify-content-between align-items-center">
                                        <img src={s3Url+user?.key ?? `${s3Url}empty_profile.png`} alt="user" />
                                        <div className="text ms-2">
                                            <h3><Link to="/Profile">{user?.userName}</Link></h3>
                                            <span>{user?.userId}</span>
                                        </div>
                                    </div>
                                    <ul className="profile-statistics">
                                        <li>
                                            <Link>
                                                <span className="item-number">{user?.postCount}</span>
                                                <span className="item-text">Post</span>
                                            </Link>
                                        </li>
                                        <li>
                                            <Link>
                                                <span className="item-number">{user?.followingCount}</span>
                                                <span className="item-text">Following</span>
                                            </Link>
                                        </li>
                                        <li>
                                            <Link>
                                                <span className="item-number">{user?.followersCount}</span>
                                                <span className="item-text">Followers</span>
                                            </Link>
                                        </li>
                                    </ul>
                                    <div className="profile-btn">
                                        <Link to="/Profile" className="default-btn">View Profile</Link>
                                    </div>
                                </div>
                                <div className="widget widget-page-you-like">
                                    <div className="widget-heading">
                                        <h3 className="widget-title">Trending People</h3>
                                        <span><Link to="/TrendingPeople">See All</Link></span>
                                    </div>
                                    {trendingPeople?.slice(0, 5)?.map((data, index) =>
                                        <article key={index} className="item">
                                            <Link className="thumb" to={{ pathname: '/Profile', search: `id=${data?._id}` }}>
                                                <img src={s3Url+data?.key} alt="user" />
                                            </Link>
                                            <div className="place">{index + 1}</div>
                                            <div className="info">
                                                <div>
                                                    <h4 className="title">
                                                        <Link to={{ pathname: '/Profile', search: `id=${data?._id}` }}>{data?.userName}</Link>
                                                    </h4>
                                                    <span>{data?.coins} coins</span>
                                                </div>
                                                <div className="add-friend-btn">
                                                    {allFollowing.some(x => x?.following?._id === data?._id && !x.isConnect) ?
                                                        (
                                                            allFollowing.map(x => {
                                                                if (x?.following?._id === data?._id && !x.isConnect) {
                                                                    return (
                                                                        <span className="add-friend-request">
                                                                            <Link onClick={() => handleRequest(x?._id)}>Requested</Link>
                                                                        </span>
                                                                    );
                                                                }
                                                                return null
                                                            })) :
                                                        allFollowing.some(x => x?.following?._id === data?._id && x.isConnect) ?
                                                            <span className="add-friend-following">
                                                                <Link onClick={() =>
                                                                    handleFollowers(
                                                                        data?._id,
                                                                        data?.isPrivate
                                                                    )
                                                                }>Following</Link>
                                                            </span> :
                                                            <span className="add-friend">
                                                                <Link onClick={() =>
                                                                    handleFollowers(
                                                                        data?._id,
                                                                        data?.isPrivate
                                                                    )}>Follow</Link>
                                                            </span>}
                                                </div>
                                            </div>
                                        </article>)}
                                </div>
                                <div className="widget widget-watch-video">
                                    <div className="widget-heading">
                                        <h3 className="widget-title">Trending Video</h3>
                                        <span><Link to="/TrendingVideo">See All</Link></span>
                                    </div>

                                    <div className="widget-container">
                                        {videos?.map((data, index) =>
                                            <div key={index} >
                                                <video className="item" src={s3Url+data?.key} type="video/mp4" width={65} height={'auto'}>
                                                </video>
                                            </div>)}
                                    </div>
                                </div>

                                <div className="widget widget-suggested-groups">
                                    <div className="widget-heading">
                                        <h3 className="widget-title">Trending Category</h3>
                                        <span><Link to="/Category">See All</Link></span>
                                    </div>
                                    {categories?.map((data, index) => (
                                        <article key={index} className="item">
                                            <Link to={{ pathname: '/SingleCategory', search: `id=${data?.category?._id}` }} className="thumb">
                                                <img src={data?.category?.image} className="rounded-circle" alt="category" />
                                            </Link>
                                            <div className="info">
                                                <div>
                                                    <h4 className="title">
                                                        <Link to={{ pathname: '/SingleCategory', search: `id=${data?.category?._id}` }} >{data?.category?.category}</Link>
                                                    </h4>
                                                    <span>{data?.postCount} Post</span>
                                                </div>
                                                {followingCategory?.includes(data?.category?._id) ?
                                                    <div className="add-friend-following"><Link onClick={() => handleFollowingCategory(data?.category?._id)} ><i className="material-icons-outlined">check</i>Following</Link></div> :
                                                    <div className="add-friend"><Link onClick={() => handleFollowCategory(data?.category?._id)} ><i className="material-icons-outlined">add</i>Follow</Link></div>}
                                            </div>
                                        </article>
                                    ))}
                                </div>
                            </aside>
                        </div>
                    </div>
                </div>
            </div>

        </>
    );
}
export default SideBar;