import React from 'react';
import './Aboutus.css'

const Aboutus = () => {
    return (
        <div className='Aboutus_container'>
            <h3 className='Aboutus_h3_tag'>About Us</h3>
            <br></br><p className='Aboutus_p_tag'>Keep your interest alive</p>
            Pixalive stands as India's pioneering interest-based social media networking application, offering rewards to all users on its platform. It empowers regular users to generate earnings through their daily activities and engagement within the app. Earn Pixalive Rewards by uploading videos, watching content, and inviting friends to join the community. Maximize your benefits by engaging in these activities more frequently! This marks the first instance in India where a social media application values and motivates users through rewards.
            Install the Pixalive Android app from the Google Play Store or the iOS app from the App Store.

            <br></br><br></br><h3 className='Aboutus_h3_tag'>Features & Functionality</h3>
            Pixalive Social Network features create posts under 40+ categories, Plays, Chat, Stories, Bidding, Trending people facilities with privacy.

            <br></br><br></br><h3 className='Aboutus_h3_tag'>Pixalive Play</h3>
            Pixalive Play offers a set of editing tools that allow users to create engaging, fun, and content video footage in short-form video clips up to 60 seconds long with our multi-segment camera. Our features include users uploading their own audio & dub audio to their recordings, editing video, sharing short video clips, and lip-sync over audio clips of songs, movies, and famous quotes.

            <br></br><br></br><h3 className='Aboutus_h3_tag'>Working Culture</h3>
            <p className='Aboutus_p_tag'>Embrace a big future with us!</p>
            <br></br>Do what you love with passion and have a fabulous time doing it. We are a team of engaging, motivated, integrity, intelligent, transparency, accountability, and energized trouble-shooters who work & play hard as a team to commit to impacting the organization make our customers and ourselves successful. Work culture depends on core values such as teamwork, trust, innovation, responsibility, quality. As a customer-driven company, we allow our employees to work in a progressive, flexible, and challenging environment to learn, grow, and evolve. We very much appreciate our employee's work and giving recognition and rewards. Our work culture and values reflect our integrity and transparency.

        </div>
    )
}

export default Aboutus
