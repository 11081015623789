import API from "./api"
import { Likes } from "./endpoints"

export const saveLikes = (data)=>{
    return API.post(`${Likes}`,data)
}

export const getFilterLikes = (data)=>{
    return API.put(`${Likes}/getFilterLikes`,data)
}
