import Header from '../../components/Header'; 
import SideBar from '../../components/SideBar'; 
import SideBarRight from '../../components/SideBarRight';
function Stories() { 
return( 
<>
<Header />
    <div class="content-page-box-area">
        <div class="row">
           
            
        </div>
    </div>
</> 
); 
} 
export default Stories;