import API from "./api"
import { Play } from "./endpoints"


export const getFilterPlay = (data)=>{
    return API.put(`${Play}/getFilterPlayForWebApp`,data)
}
export const getFilterPlayByLogOut = (data)=>{
    return API.put(`${Play}/getFilterPlayForWebAppByLogOut`,data)
}

export const getTrendingVideos = (data)=>{
    return API.put(`${Play}/getTrendingVideos`,data)
}

export const savePlay = (data) => {
    return API.post(`${Play}`,data);
  };

  export const updatePlay = (data) => {
    return API.put(`${Play}`, data);
};

export const deletePlay = (data) => {
    return API.delete(`${Play}`, { params: { _id: data } })
}


  export const getSinglePlay = (data)=>{
    return API.get(`${Play}/getSinglePlay`,{params:{_id:data}})
}

export const savePlayLikes = (data) => {
    return API.post(`${Play}/savePlayLikes`,data);
  };

  export const savePlayComments = (data) => {
    return API.post(`${Play}/createPlayComments`, data)
}

export const updatePlayReport = (data) => {
    return API.put(`${Play}/updatePlayReport`, data)
}

export const updateBlockPlay = (data) => {
    return API.put(`${Play}/updatePlayBlockUsers`, data)
}

export const getSinglePlayForShare = (data) => {
    return API.get(`${Play}/getSinglePlayForShare`, { params: { _id: data } })
}