import { useEffect, useState } from "react";
import HeaderLogin from "../../components/HeaderLogin";
import SideBarLogin from "../../components/SideBarLogin";
import { getFilterPlayByLogOut } from "../../api/play";
import { Link, Navigate, useNavigate } from "react-router-dom";
import { Checkbox, Dialog, DialogContent, DialogTitle, IconButton } from "@mui/material";
import { toast } from "react-toastify";
import { loginUser, resendOTP, sinupUser, verifyOTP } from "../../api/login";
import { isValidPhone } from "../../utils/validation";
import { isAuthenticated } from "../../utils/Auth";
import { saveToken } from "../../utils/Storage";
import SideBarRightLogin from "../../components/SideBarRightLogin";
import { timeCal } from "../../utils/dateformat";
import { s3Url } from "../../utils/FileUpload";

function HomeLogin() {
    const navigate = useNavigate();

    const initialState = {
        _id: "",
        phone: "",
        otp: "",
        userName: "",
    }
    const initialStateErrors = {
        phone: { required: false, valid: false },
        otp: { required: false },
        userName: { required: false }
    }
    const [play, setPlay] = useState([]);
    const [next, setNext] = useState(0);
    const [count, setCount] = useState(0);
    const [open, setOpen] = useState(false);
    const [inputs, setInputs] = useState(initialState)
    const [errors, setErrors] = useState(initialStateErrors)
    const [submitted, setSubmitted] = useState(false);
    const [openOTP, setOpenOTP] = useState(false);
    const [openSinup, setOpenSinup] = useState(false);
    const [back, setBack] = useState(false);
    const [scroll, setScroll] = useState(false);
    const [reload, setReload] = useState(false);

    const data = {
        limit: 5,
        page: next,
    };
    useEffect(() => {
        getPlayList();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [next]);

    useEffect(() => {
        window.addEventListener('scroll', handleScroll);
    }, [])

    const handleScroll = () => {
        const value = window.innerHeight + window.scrollY >=
            document.documentElement.scrollHeight - 200
        setScroll(value)
    };
    useEffect(() => {
        if (scroll) {
            loadMore();
        }
    }, [scroll])

    const getPlayList = () => {
        getFilterPlayByLogOut(data)
            .then((res) => {
                const playList = res?.data?.result?.playList;
                setCount(res?.data?.result?.playCount)
                setPlay([...play, ...playList]);
            })
            .catch((err) => {
                console.log(err);
            });
    };



    const loadMore = () => {
        let nextPlay = next;
        nextPlay = nextPlay + 5;
        if (count <= nextPlay) {
            setReload(true)
        }
        else {
            setNext(nextPlay);
        }
    };

    const openPopup = () => {
        setOpen(true);
        setBack(false);
        setSubmitted(false);
        setErrors(initialStateErrors);
    };
    const closePopup = () => {
        setOpen(false);
    };

    const openSinupPopup = () => {
        setOpenSinup(true);
        setSubmitted(false);
        setBack(true);
        closePopup();
        setErrors(initialStateErrors);
    };
    const closeSinupPopup = () => {
        setOpenSinup(false);
    };


    const openOTPPopup = () => {
        setOpenOTP(true);
        setSubmitted(false);
        const otp = { required: false }
        setErrors({ ...errors, otp });
    };
    const closeOTPPopup = () => {
        setOpenOTP(false);
    };

    const handleValidation = (data) => {
        let error = initialStateErrors;
        if (data.phone === "") {
            error.phone.required = true;
        }
        if (data.otp === "") {
            error.otp.required = true;
        }
        if (data.userName === "") {
            error.userName.required = true;
        }
        if (!isValidPhone(data.phone)) {
            error.phone.valid = true;
        }
        return error
    }

    const handleInputs = (event) => {
        setInputs({ ...inputs, [event.target.name]: event.target.value });
        if (submitted) {
            const newError = handleValidation({ ...inputs, [event.target.name]: event.target.value })
            setErrors(newError)
        }
    }

    const handleLogin = (event) => {
        event.preventDefault();
        const newError = handleValidation(inputs)
        setErrors(newError)
        setSubmitted(true)
        const allInputsValid = !newError?.phone?.required && !newError?.phone?.valid
        if (allInputsValid) {
            const data = { phone: inputs?.phone }
            loginUser(data)
                .then((res) => {
                    const _id = res?.data?.result?._id
                    setInputs({ ...inputs, _id })
                    closePopup()
                    openOTPPopup()
                    toast.success(res?.data?.message);
                })
                .catch((err) => {
                    toast.error(err?.response?.data?.message);
                });
        }
    };

    const handleRegistration = (event) => {
        event.preventDefault();
        const newError = handleValidation(inputs)
        setErrors(newError)
        setSubmitted(true)
        const allInputsValid = !newError?.phone?.required && !newError?.phone?.valid && !newError?.userName?.required
        if (allInputsValid) {
            const data = { phone: inputs?.phone, userName: inputs?.userName }
            sinupUser(data).then(res => {
                const _id = res?.data?.result?._id
                setInputs({ ...inputs, _id })
                closeSinupPopup()
                openOTPPopup()
                toast.success(res?.data?.message);
            })
                .catch((err) => {
                    toast.error(err?.response?.data?.message);
                });
        }
    }

    const handleSubmit = (event) => {
        event.preventDefault();
        const newError = handleValidation(inputs)
        setErrors(newError)
        setSubmitted(true)
        if (!newError?.otp?.required) {
            const data = { otp: inputs?.otp, _id: inputs?._id }
            verifyOTP(data)
                .then((res) => {
                    closeOTPPopup()
                    let token = res?.data?.result?.token;
                    let userId = res?.data?.result?._id;
                    let category = res?.data?.result?.category;
                    let data = {
                        token: token, _id: userId, category: category
                    }
                    saveToken(data);
                    if (isAuthenticated()) {
                        navigate("/Home");
                    }
                    toast.success(res?.data?.message);

                })
                .catch((err) => {
                    toast.error(err?.response?.data?.message);
                });
        }
    };

    const resendOtp = () => {
        const data = { _id: inputs?._id }
        resendOTP(data).then(res => {
            toast.success(res?.data?.message);
        })
            .catch((err) => {
                toast.error(err?.response?.data?.message);
            })
    }

    const handleBack = () => {
        closeOTPPopup();
        if (back) {
            openSinupPopup()
        }
        else {
            openPopup();
        }
    }


    if (isAuthenticated()) {
        return <Navigate to="/Home" />;
    }

    return (
        <>
            <HeaderLogin onChildClick={openPopup} />

            <div className="content-page-box-area">
                <div className="row">
                    <SideBarLogin onChildClick={openPopup} />

                    <div className="col-lg-6 col-md-6">
                        {play?.map((data, index) => (
                            <div key={index} className="post-panel">
                                <div className="post-wrapper">
                                    <div className="profile">
                                        <div className="media">
                                            <Link onClick={openPopup}
                                                className="popover-cls user-img bg-size blur-up lazyloaded"
                                                data-bs-toggle="popover"
                                                data-placement="right"
                                                data-name="sufiya eliza"
                                                title=""
                                            >
                                                <img
                                                    src={
                                                        s3Url+data?.user?.key
                                                    }
                                                    className="img-fluid blur-up lazyload bg-img"
                                                    alt="user"
                                                />
                                            </Link>
                                        </div>
                                    </div>

                                    <div className="post-conten-wrapper">
                                        <div className="post-title">
                                            <div className="profile">
                                                <div className="media-body media-body-user">
                                                    <div>
                                                        <h5>{data?.user?.userName}</h5>
                                                        <h6>{timeCal(data?.createdOn)}</h6>
                                                    </div>
                                                </div>
                                            </div>

                                        </div>
                                        <div className="post-details">
                                            <div className="img-wrapper post-video">
                                                <video width="auto" height="100%" controls>
                                                    <source src={s3Url+data?.key} type="video/mp4" />
                                                </video>
                                            </div>
                                            <div className="sidebar-items">
                                                <div className="sidebar">
                                                    <div className="feed">
                                                        <Link onClick={openPopup}>
                                                            <i className="feather-heart"></i>
                                                            <div>
                                                                <p>{data?.likeCount}</p>
                                                            </div>
                                                        </Link>
                                                    </div>
                                                </div>
                                                <div className="sidebar">
                                                    <div className="feed">
                                                        <Link onClick={openPopup}>
                                                            <i className="feather-message-circle"></i>
                                                            <div>
                                                                <p>{data?.commentCount}</p>
                                                            </div>
                                                        </Link>
                                                    </div>
                                                </div>
                                                <div className="sidebar">
                                                    <div className="feed">
                                                        <Link onClick={openPopup}>
                                                            <img
                                                                src="../assets/images/share.png"
                                                                alt="share"
                                                            />
                                                            <div>
                                                                <p>{data?.shareCount}</p>
                                                            </div>
                                                        </Link>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        {/* <div className="post-hashtag-list">
                                                    <span>#TamilDialouge</span>
                                                    <span>#Love</span>
                                                    <span>#Trending</span>
                                                    <span>#Dance</span>
                                                    <span>#HipiPannuFunPannu</span>
                                                    <span>#TamilDialouge</span>
                                                    <span>#Love</span>
                                                    <span>#Trending</span>
                                                    <span>#Dance</span>
                                                    <span>#FunPannu</span>
                                                </div> */}
                                    </div>
                                    <div className="profile">
                                        <div className="media-body media-body-user">
                                            <button onClick={openPopup} className="ms-auto btn-feed-follow">
                                                Follow
                                            </button>
                                            <div className="dropdown">
                                                <button onClick={openPopup}
                                                    className="dropdown-toggle"
                                                    type="button"
                                                    data-bs-toggle="dropdown"
                                                    aria-haspopup="true"
                                                    aria-expanded="false"
                                                >
                                                    <i className="feather-more-horizontal"></i>
                                                </button>
                                                <ul className="dropdown-menu">
                                                    <li>
                                                        <Link className="dropdown-item d-flex align-items-center">
                                                            <i className="flaticon-edit"></i> Edit Post
                                                        </Link>
                                                    </li>
                                                    <li>
                                                        <Link className="dropdown-item d-flex align-items-center">
                                                            <i className="flaticon-private"></i> Hide Post
                                                        </Link>
                                                    </li>
                                                    <li>
                                                        <Link className="dropdown-item d-flex align-items-center">
                                                            <i className="flaticon-trash"></i> Delete Post
                                                        </Link>
                                                    </li>
                                                </ul>
                                            </div>
                                        </div>
                                    </div>

                                </div>
                            </div>
                        ))}
                        {reload ?
                            <div className='form-text text-danger text-center'>No Plays</div> : null}
                    </div>
                    <SideBarRightLogin onChildClick={openPopup} />
                </div>
            </div>

            <Dialog open={open} fullWidth maxWidth="sm">
                <DialogTitle>
                    Login
                    <IconButton className="float-end" onClick={closePopup} >
                        <i className="material-icons-round">close</i>
                    </IconButton>
                </DialogTitle>
                <DialogContent className="login-form">
                    <form onSubmit={handleLogin}>
                        <div className="form-group">
                            <label className="mb-2">Phone Number</label>
                            <input type="text" name="phone" onChange={handleInputs} placeholder='Enter phone number' className="form-control" />
                            {errors.phone.required ? (
                                <span className="text-danger form-text">
                                    This field is required.
                                </span>
                            ) : errors.phone.valid ? (
                                <span className="text-danger form-text">
                                    Enter valid Phone Number.
                                </span>
                            ) : null}
                        </div>
                        <div className="mt-3 privacy">
                                <Checkbox defaultChecked />
                            <small className="ms-2">
                                I agree to the <Link target="_blank" to={{ pathname: '/TermsAndCondition', search: '?item=Terms'}} >Terms And Conditions</Link>, <Link target="_blank" to={{ pathname: '/TermsAndCondition', search: '?item=Privacy'}}>Privacy Policy</Link>, and <Link target="_blank" to={{ pathname: '/TermsAndCondition',search: '?item=Coin'}} > Coin Policy</Link></small>
                        </div>
                        <button type="submit" className="default-btn">Login</button>
                        <div className="signup-text-info">Not a member yet? <Link onClick={openSinupPopup}> Sign Up</Link>.</div>

                    </form>
                </DialogContent>
            </Dialog>
            <Dialog open={openSinup} fullWidth maxWidth="sm">
                <DialogTitle>
                    Register
                    <IconButton className="float-end" onClick={closeSinupPopup} >
                        <i className="material-icons-round">close</i>
                    </IconButton>
                </DialogTitle>
                <DialogContent className="login-form">
                    <form onSubmit={handleRegistration}>
                        <div className="form-group">
                            <label className="mb-2">User Name</label>
                            <input type="text" name="userName" onChange={handleInputs} placeholder='Enter user name' className="form-control" />
                            {errors.userName.required ?
                                <span className="text-danger form-text">
                                    This field is required.
                                </span> : null}
                        </div>
                        <div className="form-group">
                            <label className="mb-2">Phone Number</label>
                            <input type="text" name="phone" onChange={handleInputs} placeholder='Enter phone number' className="form-control" />
                            {errors.phone.required ? (
                                <span className="text-danger form-text">
                                    This field is required.
                                </span>
                            ) : errors.phone.valid ? (
                                <span className="text-danger form-text">
                                    Enter valid Phone Number.
                                </span>
                            ) : null}
                        </div>
                        <div className="mt-3 privacy">
                                <Checkbox defaultChecked />
                            <small className="ms-2">
                                I agree to the <Link target="_blank" to={{ pathname: '/TermsAndCondition', search: '?item=Terms'}} >Terms And Conditions</Link>, <Link target="_blank" to={{ pathname: '/TermsAndCondition', search: '?item=Privacy'}}>Privacy Policy</Link>, and <Link target="_blank" to={{ pathname: '/TermsAndCondition',search: '?item=Coin'}} > Coin Policy</Link></small>
                        </div>
                        <button type="submit" className="default-btn">Register</button>
                    </form>
                </DialogContent>
            </Dialog>
            <Dialog open={openOTP} fullWidth maxWidth="sm">
                <DialogTitle>
                    Verify Your Account
                    <IconButton className="float-end" onClick={closeOTPPopup} >
                        <i className="material-icons-round">close</i>
                    </IconButton>
                </DialogTitle>
                <DialogContent className="login-form">
                    <div className="text-center">
                        <p>An OTP has been sent to your registered phone number.</p>
                        <p>Please check your message.</p>
                    </div>
                    <form onSubmit={handleSubmit}>
                        <div className="form-group mt-3">
                            <label>OTP</label>
                            <input type="text" placeholder='Enter OTP' name="otp" onChange={handleInputs} className="form-control" />
                            {errors.otp.required ?
                                <span className="text-danger form-text">
                                    This field is required.
                                </span> : null}
                        </div>
                        <div className="remember-me-wrap d-flex justify-content-between align-items-center">
                            <div className="lost-your-password-wrap text-center">
                                <Link onClick={handleBack} className="back-to-login">
                                    Back
                                </Link>
                            </div>
                            <div className="lost-your-password-wrap">
                                <Link data-toggle="modal" data-target="#Forgotpassword" onClick={resendOtp} className="lost-your-password">Resend OTP</Link>
                            </div>
                        </div>
                        <button type="submit" className="default-btn">Submit</button>
                    </form>
                </DialogContent>
            </Dialog>
        </>
    );
}
export default HomeLogin;
