import { useEffect, useState } from "react";
import Header from "../../components/Header";
import SideBar from "../../components/SideBar";
import SideBarRightProfile from "../../components/SideBarRightProfile";
import { getUserId } from "../../utils/Storage";
import {
    getSingleUser,
    removeBlockedUsers,
    updateBlockedUsers,
    updateUser,
    updateUserImage,
} from "../../api/user";
import { Link, useLocation } from "react-router-dom";
import {
    deletePost,
    getFilterPost,
    getSinglePost,
    savePostComments,
    updateBlockPost,
    updatePostReport,
} from "../../api/post";
import { getFilterLikes, saveLikes } from "../../api/likes";
import { timeCal } from "../../utils/dateformat";
import { getFilterBidding, saveBidding } from "../../api/bidding";
import { toast } from "react-toastify";
import {
    Chip,
    Dialog,
    DialogContent,
    DialogTitle,
    IconButton,
    Tooltip,
} from "@mui/material";
import {
    acceptFollower,
    deleteFollower,
    getAllFollowings,
    getFollowersList,
    getFollowingList,
    getRequestFollowersList,
    saveFollower,
} from "../../api/follow";
import { EmailIcon, EmailShareButton, FacebookIcon, FacebookShareButton, LinkedinIcon, LinkedinShareButton, TelegramIcon, TelegramShareButton, TwitterIcon, TwitterShareButton, WhatsappIcon, WhatsappShareButton } from 'react-share';
import { isValidEmail, isValidPhone } from "../../utils/validation";
import { Storage } from 'aws-amplify';
import { s3Url } from "../../utils/FileUpload";

function Profile({ profileMenu }) {
    const [user, setUser] = useState();
    const [next, setNext] = useState(0);
    const [count, setCount] = useState(0);
    const [post, setPost] = useState([]);
    const [likes, setLikes] = useState([]);
    const [userId, setUserId] = useState();
    const location = useLocation();
    const [scroll, setScroll] = useState(false);
    const [openBid, setOpenBid] = useState(false);
    const [bidAmount, setBidAmount] = useState("");
    const [submit, setSubmit] = useState(false);
    const [reload, setReload] = useState(false);
    const [profileId, setProfileId] = useState();
    const [postId, setPostId] = useState("");
    const [bidding, setBidding] = useState([]);
    const [hide, setHide] = useState(false);
    const [hideId, setHideId] = useState("");
    const [block, setBlock] = useState(false);
    const [blockId, setBlockId] = useState("");
    const [changeProfileId, setChangeProfileId] = useState(false);
    const [isMounted, setIsMounted] = useState(false);
    const paramId = new URLSearchParams(location.search).get("id");
    const [followers, setFollowers] = useState([]);
    const [following, setFollowing] = useState([]);
    const [friends, setFriends] = useState([]);
    const [allFollowing, setAllFollowing] = useState([])
    const [remove, setRemove] = useState(false);
    const [removeId, setRemoveId] = useState('');
    const [removeFollowing, setRemoveFollowing] = useState('')
    const [userDetails, setUserDetails] = useState('')
    const [blocking, setBlocking] = useState(false);
    const [blockingId, setBlockingId] = useState("");
    const [share, setShare] = useState(false);
    const [shareUrl, setShareUrl] = useState('');
    const [content, setContent] = useState('')
    const [postLikes, setPostLikes] = useState('');
    const [menu, setMenu] = useState('');
    const [openComments, setOpenComments] = useState('');
    const [input, setInput] = useState('');
    const item = new URLSearchParams(location.search).get('item');
    const state = item ? item : 'Feed'
    const [editDetails, setEditDetails] = useState({})
    const [coins, setCoins] = useState('');
    const [edit, setEdit] = useState(false);
    const [submitDetails, setSubmitDetails] = useState(false)
    const [about, setAbout] = useState(false);
    const [searchFollowers, setSearchFollowers] = useState([])
    const [searchFollowing, setSearchFollowing] = useState([])
    const [searchRequest, setSearchRequest] = useState([]);
    const [openReport, setOpenReport] = useState()
    const [report, setReport] = useState({});
    const [openDescription, setOpenDescription] = useState();
    const [submitReport, setSubmitReport] = useState(false);
    const [reportId, setReportId] = useState('');
    const [reportResponse, setReportResponse] = useState(false)
    const [deleteId, setDeleteId] = useState('');
    const [openDelete, setOpenDelete] = useState(false)
    const [postDelete, setPostDelete] = useState(false);


    useEffect(() => {
        setMenu(state)
        const user = getUserId();
        setProfileId(user);
        const id = paramId ?? getUserId();
        setUserId(id);
        window.addEventListener("scroll", handleScroll);
    }, [paramId]);

    useEffect(() => {
        if (userId) {
            getFriendRequest();
            getFilterBiddingList();
            getUserDetails();
            getUserLikedPost();
            getFollowers();
            getFollowing();
            getAllFollowing();
            getProfileUserDetails();
        }

    }, [userId]);

    useEffect(() => {
        if (userId) {
            getPostList();
        }
    }, [next]);

    useEffect(() => {
        if (changeProfileId) {
            getPostList();
            setChangeProfileId(false);
        }
    }, [changeProfileId]);

    useEffect(() => {
        if (isMounted) {
            if (next === 0) {
                setChangeProfileId(true);
            } else {
                setNext(0);
            }
            setPost([]);
        } else {
            setIsMounted(true);
        }
    }, [userId]);

    const handleScroll = () => {
        const value =
            window.innerHeight + window.scrollY >=
            document.documentElement.scrollHeight - 200;
        setScroll(value);
    };
    useEffect(() => {
        if (scroll) {
            loadMore();
        }
    }, [scroll]);

    const getUserDetails = () => {
        getSingleUser(userId)
            .then((res) => {
                setUser(res?.data?.result);
            })
            .catch((err) => {
                console.log(err);
            });
    };

    const getFollowers = () => {
        const data = { id: userId };
        getFollowersList(data)
            .then((res) => {
                setFollowers(res?.data?.result);
                setSearchFollowers(res?.data?.result)
            })
            .catch((err) => {
                console.log(err);
            });
    };

    const getFollowing = () => {
        const data = { id: userId };
        getFollowingList(data)
            .then((res) => {
                setFollowing(res?.data?.result);
                setSearchFollowing(res?.data?.result)
            })
            .catch((err) => {
                console.log(err);
            });
    };



    const getFriendRequest = () => {
        const data = { id: userId };
        getRequestFollowersList(data)
            .then((res) => {
                setFriends(res?.data?.result);
                setSearchRequest(res?.data?.result)
            })
            .catch((err) => {
                console.log(err);
            });
    };

    const saveFollowers = (id, connect) => {
        const data = {
            follower: profileId,
            following: id,
            isConnect: connect === false ? true : false,
        };
        saveFollower(data)
            .then((res) => {
                getAllFollowing();
            })
            .catch((err) => {
                console.log(err);
            });
    };


    const getAllFollowing = () => {
        const data = { id: profileId };
        getAllFollowings(data)
            .then((res) => {
                setAllFollowing(res?.data?.result);
            })
            .catch((err) => {
                console.log(err);
            });
    };

    const acceptFrientRequest = (id) => {
        const data = { _id: id, isConnect: true };
        acceptFollower(data)
            .then((res) => {
                toast.success("Accepting the friend request successfully.");
                getFriendRequest();
            })
            .catch((err) => {
                console.log(err);
            });
    };

    const removeFrientRequest = (id) => {
        const data = { _id: id, isConnect: false };
        acceptFollower(data)
            .then((res) => {
                toast.success("The friend request was successfully removed.");
                getFriendRequest();
            })
            .catch((err) => {
                console.log(err);
            });
    };

    const handleFollowers = (id, connect) => {
        const data = {
            follower: profileId,
            following: id,
            isConnect: connect === false ? true : false,
        };
        saveFollower(data)
            .then((res) => {
                getAllFollowing()
            })
            .catch((err) => {
                console.log(err);
            });

    }

    const handleRequest = (id) => {
        const data = { _id: id, isConnect: false };
        acceptFollower(data)
            .then((res) => {
                getAllFollowing()
            })
            .catch((err) => {
                console.log(err);
            });
    };

    const openFollowerPopup = (id) => {
        setRemove(true)
        setRemoveId(id);
    };
    const closeFollowerPopup = () => {
        setRemove(false);
    };


    const handleDeleteFollower = () => {
        const data = { id: removeId }
        deleteFollower(data).then(res => {
            toast.success("The follower was successfully removed.");
            closeFollowerPopup()
            getFollowers()
        }).catch(err => {
            console.log(err);
        })
    }

    const handleFileInputs = (event) => {
        const file = event?.target?.files[0];
        if (file) {
            Storage.put(file.name, file).then((res) => {
                var url = 'https://d2iozohtwtwsbz.cloudfront.net/public/' + res.key;
                var key = 'public/' + res.key;
                const data = { _id: user?._id, imageUrl: url, key };
                updateUserImage(data)
                    .then((res) => {
                        getUserDetails();
                    })
                    .catch((err) => {
                        console.log(err);
                    });
            })
                .catch((err) => {
                    console.log(err);
                });
        }
    };

    const getPostList = () => {
        const data = {
            limit: 5,
            page: next,
            user: userId,
        };
        getFilterPost(data)
            .then((res) => {
                const postList = res?.data?.result?.postList;
                setCount(res?.data?.result?.postCount);
                setPost([...post, ...postList]);
            })
            .catch((err) => {
                console.log(err);
            });
    };

    const loadMore = () => {
        let nextPost = next;
        if (postDelete) {
            nextPost = nextPost + 4;
            setPostDelete(false)
        }
        else {
            nextPost = nextPost + 5;
        }
        if (count <= nextPost) {
            setReload(true);
        } else {
            setNext(nextPost);
        }
    };

    const savePostLikes = (postId, like) => {
        if (like === 'add') {
            setPostLikes(postId)
        }
        else { setPostLikes('') }
        const data = {
            user: userId,
            post: postId,
        };
        saveLikes(data)
            .then((res) => {
                singlePost(postId);
                getUserLikedPost();
            })
            .catch((err) => {
                console.log(err);
            });
    };

    const singlePost = (postId) => {
        getSinglePost(postId)
            .then((res) => {
                const result = res?.data?.result;
                const data = post.map(item =>
                    item._id === result?._id ? { ...item, likeCount: result?.likeCount, bidingCount: result?.bidingCount, commentCount: result?.commentCount, comment: result?.comment } : item
                );
                setPost(data);
            })
            .catch((err) => {
                console.log(err);
            });
    };

    const getUserLikedPost = () => {
        const data = { user: userId };
        getFilterLikes(data)
            .then((res) => {
                setLikes(res?.data?.result);
            })
            .catch((err) => {
                console.log(err);
            });
    };

    const openBidPopup = (id) => {
        setBidAmount(' ')
        setOpenBid(true);
        setSubmit(false);
        setPostId(id)
    };
    const closeBidPopup = () => {
        setOpenBid(false);
    };

    const handleBidAmount = (event) => {
        setBidAmount(event?.target?.value);
    };

    const handleBidAmountSubmit = (event) => {
        event.preventDefault();
        setSubmit(true);
        if (bidAmount) {
            if (coins >= bidAmount) {
                const data = {
                    user: profileId,
                    post: postId,
                    type: "post",
                    bidCoins: bidAmount
                }
                saveBidding(data).then(res => {
                    toast.success('Successfully submitting a bid');
                    closeBidPopup();
                    getFilterBiddingList();
                    singlePost(postId)
                }).catch(err => {
                    console.log(err);
                })
            }
            else {
                toast.warning('Your coin is less than the amount you have bid.')
            }
        }
    };

    const resetBidAmount = () => {
        setBidAmount("");
    };

    const getFilterBiddingList = () => {
        const data = { type: "post" };
        getFilterBidding(data)
            .then((res) => {
                setBidding(res?.data?.result);
            })
            .catch((err) => {
                console.log(err);
            });
    };

    const handleHidePost = () => {
        const data = { _id: hideId, blockedUsers: profileId };
        updateBlockPost(data)
            .then((res) => {
                closeHidePopup();
                toast.success(res?.data?.message);
                const newPostList = post.filter((x) => x._id !== hideId);
                setPost(newPostList);
            })
            .catch((err) => {
                console.log(err);
            });
    };

    const handleBlockUser = () => {
        const data = { blockedUsers: blockId };
        updateBlockedUsers(data)
            .then((res) => {
                closeBlockPopup();
                toast.success(res?.data?.message);
                getProfileUserDetails()
            })
            .catch((err) => {
                console.log(err);
            });
    };

    const openHidePopup = (hideId) => {
        setHideId(hideId);
        setHide(true);
    };
    const closeHidePopup = () => {
        setHide(false);
    };

    const openBlockPopup = (hideId) => {
        setBlockId(hideId);
        setBlock(true);
    };
    const closeBlockPopup = () => {
        setBlock(false);
    };

    const openBlockingPopup = (hideId) => {
        setBlockingId(hideId);
        setBlocking(true);
    };
    const closeBlockingPopup = () => {
        setBlocking(false);
    };

    const handleBlockingUser = () => {
        const data = { blockedUsers: blockingId };
        removeBlockedUsers(data)
            .then((res) => {
                closeBlockingPopup();
                toast.success(res?.data?.message);
                getProfileUserDetails()
            })
            .catch((err) => {
                console.log(err);
            });
    };


    const getProfileUserDetails = () => {
        getSingleUser(profileId)
            .then((res) => {
                setCoins(res?.data?.result?.coins);
                setUserDetails(res?.data?.result);
            })
            .catch((err) => {
                console.log(err);
            });
    }

    const openSharePopup = (url, content) => {
        setShareUrl(url);
        setContent(content)
        setShare(true)
    };
    const closeSharePopup = () => {
        setShare(false);
    };

    const getPostCommentsList = (commentId) => {
        if (openComments === commentId) {
            setOpenComments('')
        }
        else {
            setOpenComments(commentId)
        }
    }

    const handleInputs = (event) => {
        setInput({ ...input, message: event.target.value })
    }

    const handleComments = (id) => {
        if (input?.message) {
            const data = {
                _id: id,
                comment: {
                    comments: input?.message,
                    user: userDetails?._id
                }
            }
            savePostComments(data).then(res => {
                setInput({ ...input, message: '' })
                singlePost(id)
            }).catch(err => { console.log(err) })
        }
        else {
            toast.warning('Please enter the comment')
        }
    }

    const openEditPopup = () => {
        setSubmitDetails(false)
        setEditDetails(user)
        setEdit(true)
    }

    const closeEditPopup = () => {
        setEdit(false)
    }

    const handleDetails = (event) => {
        setEditDetails({ ...editDetails, [event.target.name]: event.target.value });
    };

    const updateUserDetails = () => {
        setSubmitDetails(true)
        const validEamil = editDetails?.email ? isValidEmail(editDetails?.email) : true
        if (editDetails?.userName && validEamil) {
            updateUser(editDetails).then(res => {
                toast.success(res?.data?.message)
                closeEditPopup();
                closeAboutPopup()
                getUserDetails()
            }).catch(err => { console.log(err); })
        }
    }
    const openAboutPopup = () => {
        setSubmitDetails(false)
        setEditDetails(user)
        setAbout(true)
    }

    const closeAboutPopup = () => {
        setAbout(false)
    }


    const searchList = (event) => {
        var value = event.target.value.toLowerCase();
        if (value) {
            var searchFollowersData = searchFollowers.filter((x) => {
                return x?.follower?.userName.toLowerCase().indexOf(value) !== -1
            })
            setFollowers(searchFollowersData)
            var searchFollowingData = searchFollowing.filter((x) => {
                return x?.following?.userName.toLowerCase().indexOf(value) !== -1
            })
            setFollowing(searchFollowingData)
            var searchRequestData = searchRequest.filter((x) => {
                return x?.follower?.userName.toLowerCase().indexOf(value) !== -1
            })
            setFriends(searchRequestData)
        }
        else {
            setFollowers(searchFollowers)
            setFollowing(searchFollowing)
            setFriends(searchRequest)
        }
    }

    const openReportPopup = (data) => {
        setReportId(data)
        setOpenReport(true)
    }
    const closeReportPopup = () => {
        setOpenReport(false)
    }

    const openDescriptionPopup = (data) => {
        setReport({ ...report, type: data })
        setSubmitReport(false)
        closeReportPopup()
        setOpenDescription(true)
    }
    const closeDescriptionPopup = () => {
        setOpenDescription(false)
    }

    const openReportResponsePopup = (data) => {
        setReportResponse(true)
    }
    const closeReportResponsePopup = () => {
        setReportResponse(false)
    }

    const handleDescription = (event) => {
        const description = event?.target.value
        setReport({ ...report, description })
    }

    const handleReport = () => {
        setSubmitReport(true)
        if (report?.description) {
            const data = {
                _id: reportId,
                report: {
                    user: user,
                    type: report?.type,
                    description: report?.description
                }
            }
            updatePostReport(data).then(res => {
                closeDescriptionPopup();
                openReportResponsePopup()
            }).catch(err => { console.log(err); })
        }

    }

    const openDeletePopup = (data) => {
        setDeleteId(data)
        setOpenDelete(true)
    }
    const closeDeletePopup = () => {
        setOpenDelete(false)
    }

    const deletePostDetails = () => {
        deletePost(deleteId).then(res => {
            closeDeletePopup()
            toast.success(res?.data?.message);
            const newPostList = post.filter(x => x._id !== deleteId)
            setPost(newPostList)
            setUser({ ...user, postCount: user?.postCount - 1 })
            setPostDelete(true)
        }).catch(err => { console.log(err); })
    }


    return (
        <>
            <Header />
            <div className="content-page-box-area">
                <div className="row">
                    <SideBar menu={"Feed"} />
                    <div className="col-lg-9 col-md-12">
                        <div className="content-page-box-area">
                            <div className="my-profile-inner-box">
                                <div className="profile-info-box">
                                    <div className="inner-info-box">
                                        <div className="info-profile-image">
                                            <div className="info-image">
                                                <img
                                                    src={
                                                        s3Url+user?.key ??
                                                        `${s3Url}empty_profile.png`
                                                    }
                                                    alt="user"
                                                />
                                              {userId === profileId ?  <div className="icon">
                                                    <label htmlFor="file-input">
                                                        <i className="flaticon-photo-camera cursor"></i>
                                                    </label>
                                                    <input
                                                        name="imageUrl"
                                                        id="file-input"
                                                        type="file"
                                                        onChange={handleFileInputs}
                                                        style={{ width: "0" }}
                                                        accept="image/*"
                                                    />
                                                </div>:null}
                                            </div>
                                            <div className="info-text ms-3">
                                                <h3>{user?.userName}</h3>
                                                <span className="__cf_email__">{user?.userId}</span>
                                            </div>
                                        </div>
                                        <ul className="statistics">
                                            <li>

                                                <span className="item-number">{user?.postCount}</span>
                                                <span className="item-text">Posts</span>

                                            </li>
                                            <li>

                                                <span className="item-number">{user?.playCount}</span>
                                                <span className="item-text">Plays</span>

                                            </li>
                                            <li>

                                                <span className="item-number">
                                                    {user?.followingCount}
                                                </span>
                                                <span className="item-text">Following</span>

                                            </li>
                                            <li>

                                                <span className="item-number">
                                                    {user?.followersCount}
                                                </span>
                                                <span className="item-text">Followers</span>

                                            </li>
                                        </ul>
                                    </div>
                                    {userId !== profileId ? (
                                        <div className="profile-btn d-flex justify-content-end">
                                            {userDetails?.blockedUsers?.includes(userId) ? <button
                                                type="button" className="blocking" onClick={() => openBlockingPopup(user?._id)}>
                                                Blocking
                                            </button> :
                                                <button className="block"
                                                    type="button"
                                                    onClick={() => openBlockPopup(user?._id)}
                                                >
                                                    Block
                                                </button>}
                                        </div>
                                    ) : null}
                                    <div className="profile-list-tabs">
                                        <ul className="nav nav-tabs" id="myTab" role="tablist">
                                            <li className="nav-item">
                                                <a
                                                    className={`nav-link ${menu === 'Feed' ? 'active' : ''}`}
                                                    id="timeline-tab"
                                                    data-bs-toggle="tab"
                                                    href="#timeline"
                                                    role="tab"
                                                    aria-controls="timeline"
                                                >
                                                    Feed
                                                </a>
                                            </li>
                                            <li className="nav-item">
                                                <a
                                                    className={`nav-link ${menu === 'About' ? 'active' : ''}`}
                                                    id="about-tab"
                                                    data-bs-toggle="tab"
                                                    href="#about"
                                                    role="tab"
                                                    aria-controls="about"
                                                >
                                                    About
                                                </a>
                                            </li>
                                            <li className="nav-item">
                                                <a
                                                    className={`nav-link ${menu === 'Followers' ? 'active' : ''}`}
                                                    id="followers-tab"
                                                    data-bs-toggle="tab"
                                                    href="#followers"
                                                    role="tab"
                                                    aria-controls="recently-added"
                                                >
                                                    Followers
                                                </a>
                                            </li>
                                            <li className="nav-item">
                                                <a
                                                    className={`nav-link ${menu === 'Following' ? 'active' : ''}`}
                                                    id="following-tab"
                                                    data-bs-toggle="tab"
                                                    href="#following"
                                                    role="tab"
                                                    aria-controls="recently-added"
                                                >
                                                    Following
                                                </a>
                                            </li>
                                            {userId === profileId ? <li className="nav-item">
                                                <a
                                                    className={`nav-link ${menu === 'Friends' ? 'active' : ''}`}
                                                    id="friend-request-tab"
                                                    data-bs-toggle="tab"
                                                    href="#friend-request"
                                                    role="tab"
                                                    aria-controls="recently-added"
                                                >
                                                    Friend Request
                                                </a>
                                            </li> : null}
                                            {/* <li className="nav-item">
                                           <a className="nav-link" id="photos-tab" data-bs-toggle="tab" href="#photos" role="tab" aria-controls="photos">Photos</a>
                                             </li>
                                            <li className="nav-item">
                                             <a className="nav-link" id="videos-tab" data-bs-toggle="tab" href="#videos" role="tab" aria-controls="videos">Videos</a>
                                                  </li> */}
                                        </ul>
                                        <div className="friends-search-box">
                                            <input
                                                type="text"
                                                className="input-search"
                                                placeholder="Search friends..." onKeyUp={searchList}
                                            />
                                            <button type="submit">
                                                <i className="material-icons-round">search</i>
                                            </button>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div className="tab-content" id="myTabContent">
                                <div
                                    className={`tab-pane fade ${menu === 'Feed' ? ' show active' : ''} `}
                                    id="timeline"
                                    role="tabpanel"
                                >
                                    <div className="row">
                                        <div className="col-lg-8 col-md-12">
                                            <div className="news-feed-area">
                                                {post?.map((data, index) => (
                                                    <div key={index} className="news-feed news-feed-post">
                                                        {data?.isBided ? (
                                                            <div className="post-header d-flex justify-content-between align-items-center">
                                                                <div className="image">
                                                                    <Link
                                                                        to={{
                                                                            pathname: "/Profile",
                                                                            search: `id=${data?.bidedUser?._id}`,
                                                                        }}
                                                                    >
                                                                        {" "}
                                                                        <img
                                                                            src={s3Url+data?.bidedUser?.key}
                                                                            alt="user"
                                                                        />
                                                                    </Link>
                                                                </div>
                                                                <div className="info">
                                                                    <span className="name">
                                                                        <Link
                                                                            to={{
                                                                                pathname: "/Profile",
                                                                                search: `id=${data?.bidedUser?._id}`,
                                                                            }}
                                                                        >
                                                                            {data?.bidedUser?.userName}
                                                                        </Link>
                                                                    </span>
                                                                    <span className="small-text-bid">
                                                                        {" "}
                                                                        Owned this content
                                                                    </span>
                                                                </div>
                                                            </div>
                                                        ) : null}
                                                        <div className="post-header d-flex justify-content-between align-items-center">
                                                            <div className="image">
                                                                <Link
                                                                    to={{
                                                                        pathname: "/Profile",
                                                                        search: `id=${data?.user?._id}`,
                                                                    }}
                                                                >
                                                                    {" "}
                                                                    <img src={s3Url+data?.user?.key} alt="user" />
                                                                </Link>
                                                            </div>
                                                            <div className="info">
                                                                <span className="name">
                                                                    <Link
                                                                        to={{
                                                                            pathname: "/Profile",
                                                                            search: `id=${data?.user?._id}`,
                                                                        }}
                                                                    >
                                                                        {data?.user?.userName}
                                                                    </Link>
                                                                </span>
                                                                <span className="small-text">
                                                                    <i className="material-icons-outlined">
                                                                        public
                                                                    </i>
                                                                    {data?.postType === 1 ? "Public" : "Private"}
                                                                    <span> {timeCal(data?.createdOn)}</span>
                                                                </span>
                                                            </div>
                                                            {data?.isBid && !data?.isBided ? (
                                                                data?.user?._id === profileId ||
                                                                    bidding?.some(
                                                                        (x) =>
                                                                            x?.user?._id === profileId &&
                                                                            x?.post === data?._id
                                                                    ) ? (
                                                                    <Tooltip title="Bid Count">
                                                                        {" "}
                                                                        <Chip
                                                                            className="me-2"
                                                                            icon={
                                                                                <i
                                                                                    className="material-icons-outlined"
                                                                                    style={{
                                                                                        color: "gold",
                                                                                        fontSize: "20px",
                                                                                    }}
                                                                                >
                                                                                    paid
                                                                                </i>
                                                                            }
                                                                            label={data?.bidingCount}
                                                                            size="small"
                                                                            color="success"
                                                                        />{" "}
                                                                    </Tooltip>
                                                                ) : (
                                                                    <div className="buy-button">
                                                                        <button
                                                                            type="button"
                                                                            onClick={() => openBidPopup(data?._id)}
                                                                        >
                                                                            <i className="material-icons-outlined">
                                                                                paid
                                                                            </i>
                                                                            Buy
                                                                        </button>
                                                                    </div>
                                                                )
                                                            ) : null}
                                                            <div class="dropdown mx-4">
                                                                <button
                                                                    class="dropdown-toggle"
                                                                    type="button"
                                                                    data-bs-toggle="dropdown"
                                                                    aria-haspopup="true"
                                                                    aria-expanded="false"
                                                                >
                                                                    <i class="feather-more-horizontal"></i>
                                                                </button>
                                                                <ul class="dropdown-menu">
                                                                    {data?.user?._id !== profileId || data?.isBided ? (data?.bidedUser?._id !== profileId ?
                                                                        <><li><Link className="dropdown-item d-flex align-items-center" onClick={(event) => { event.preventDefault(); openHidePopup(data?._id) }}><i className="material-icons-outlined">hide_source</i> Hide</Link></li>
                                                                            <li><Link className="dropdown-item d-flex align-items-center" onClick={(event) => { event.preventDefault(); openReportPopup(data?._id) }} ><i className="material-icons-outlined">report</i> Report</Link></li></>
                                                                        : <><li><Link className="dropdown-item d-flex align-items-center" to={{ pathname: '/EditPost', search: `postId=${data?._id}` }} ><i className="material-icons-outlined">edit</i> Edit</Link></li>
                                                                            <li><Link className="dropdown-item d-flex align-items-center" onClick={() => openDeletePopup(data?._id)} ><i className="material-icons-outlined">delete</i> Delete</Link></li></>) :
                                                                        <><li><Link className="dropdown-item d-flex align-items-center" to={{ pathname: '/EditPost', search: `postId=${data?._id}` }} ><i className="material-icons-outlined">edit</i> Edit</Link></li>
                                                                            <li><Link className="dropdown-item d-flex align-items-center" onClick={() => openDeletePopup(data?._id)} ><i className="material-icons-outlined">delete</i> Delete</Link></li></>}
                                                                </ul>
                                                            </div>
                                                        </div>

                                                        <div className="post-body">
                                                            <div className="post-image">
                                                                {data?.fileType === 1 ? (
                                                                    <img src={s3Url+data?.key} alt="Post" />
                                                                ) : (
                                                                    <video
                                                                        src={s3Url+data?.key}
                                                                        width="100%"
                                                                        height="auto"
                                                                        controls
                                                                    ></video>
                                                                )}
                                                            </div>
                                                            <div className="post-content-hashtag">
                                                                {" "}
                                                                <p>{data?.textContent}</p>
                                                            </div>

                                                            <ul className="post-meta-wrap d-flex justify-content-between align-items-center">
                                                                <li className="post-react">
                                                                    <Link>
                                                                        {postLikes === data?._id || likes?.some(
                                                                            (x) => x.post?._id === data?._id
                                                                        ) ? (
                                                                            <i
                                                                                className="material-icons-round text-danger"
                                                                                onClick={(event) => {
                                                                                    event.preventDefault();
                                                                                    savePostLikes(data?._id, 'remove');
                                                                                }}
                                                                            >
                                                                                favorite
                                                                            </i>
                                                                        ) : (
                                                                            <i
                                                                                className="feather-heart"
                                                                                onClick={(event) => {
                                                                                    event.preventDefault();
                                                                                    savePostLikes(data?._id, 'add');
                                                                                }}
                                                                            ></i>
                                                                        )}
                                                                        <span className="number">
                                                                            {data?.likeCount}{" "}
                                                                        </span>
                                                                    </Link>
                                                                </li>
                                                                <li className="post-comment">
                                                                    <Link onClick={(event) => { event?.preventDefault(); getPostCommentsList(data?._id) }}>
                                                                        <i className="feather-message-circle"></i>
                                                                        <span className="number">
                                                                            {data?.commentCount}
                                                                        </span>
                                                                    </Link>
                                                                </li>
                                                                <li className="post-share">
                                                                    <Link onClick={(event) => { event?.preventDefault(); openSharePopup(s3Url+data?.key, data?.textContent) }}>
                                                                        <img
                                                                            src="../assets/images/share.png"
                                                                            alt="share"
                                                                        />
                                                                        {/* <span className="number">
                                                                            {data?.shareCount}{" "}
                                                                        </span> */}
                                                                    </Link>
                                                                </li>
                                                            </ul>
                                                            {/* <form className="post-footer">
                                                                <div className="footer-image">
                                                                    <Link to="#">
                                                                        <img
                                                                            src="../assets/images/user/user-2.jpg"
                                                                            className="rounded-circle"
                                                                            alt="user"
                                                                        />
                                                                    </Link>
                                                                </div>
                                                                <div className="form-group">
                                                                    <textarea
                                                                        name="message"
                                                                        className="form-control"
                                                                        placeholder="Write a comment..."
                                                                    ></textarea>
                                                                    <label>
                                                                        <Link to="#">
                                                                            <i className="feather-send"></i>
                                                                        </Link>
                                                                    </label>
                                                                </div>
                                                               
                                                            </form> */}
                                                            {openComments === data?._id ?
                                                                <div className="new-comments post-modal-popup">
                                                                    <div className="comments-area" data-simplebar style={data?.comment?.length > 0 ? { height: '150px' } : { height: '0px' }}>
                                                                        <div className="post-comment-list">
                                                                            {data?.comment?.map((value, index) =>
                                                                                <div className="comment-list" key={index}>
                                                                                    <div className="comment-image">
                                                                                        <Link to={{ pathname: '/Profile', search: `id=${value?.user?._id}` }}><img src={s3Url+value?.user?.key} className="rounded-circle" alt="user" /></Link>
                                                                                    </div>
                                                                                    <div className="comment-info">
                                                                                        <h3>
                                                                                            <Link to={{ pathname: '/Profile', search: `id=${value?.user?._id}` }}>{value?.user?.userName}</Link>
                                                                                        </h3>
                                                                                        <span>{timeCal(value?.createdOn)}</span>
                                                                                        <p>{value?.comments}</p>
                                                                                        {/* <ul className="comment-react">
                                                        <li><a href="#" className="like">1,351 likes</a></li>
                                                        <li><a href="#">Reply</a></li>
                                                    </ul> */}
                                                                                    </div>
                                                                                </div>)}
                                                                            {/* <div className="more-comments">
                                                <a href="#">More Comments+</a>
                                            </div> */}
                                                                        </div>
                                                                    </div>

                                                                    <form className="post-footer">
                                                                        <div className="footer-image">
                                                                            <Link to={{ pathname: '/Profile', search: `id=${userDetails?._id}` }}><img src={s3Url+userDetails?.key} className="rounded-circle" alt="user" /></Link>
                                                                        </div>
                                                                        <div className="form-group">
                                                                            <textarea name="message" value={input?.message} onChange={handleInputs} className="form-control" placeholder="Write a comment..."></textarea>
                                                                            <label><Link onClick={(event) => { event?.preventDefault(); handleComments(data?._id) }} ><i className="feather-send"></i></Link></label>
                                                                        </div>
                                                                    </form>
                                                                </div>
                                                                : null}
                                                        </div>
                                                    </div>
                                                ))}
                                                {reload ? (
                                                    <div className="form-text text-danger text-center">
                                                        No Post
                                                    </div>
                                                ) : null}
                                            </div>
                                        </div>

                                        <SideBarRightProfile />
                                    </div>
                                </div>

                                <div className={`tab-pane fade ${menu === 'About' ? ' show active' : ''} `} id="about" role="tabpanel">
                                    <div className="row">
                                        <div className="col-lg-4 col-md-12">
                                            <div className="about-personal-information ">
                                                <div className="about-header d-flex justify-content-between align-items-center">
                                                    <div className="title">Personal Information</div>
                                                    {userId === profileId ? <div className="prifile-edit">
                                                        <Link onClick={openEditPopup}>Edit</Link>
                                                    </div> : null}
                                                </div>

                                                <ul className="information-list">
                                                    <li>
                                                        <span>Name:</span> {user?.userName}
                                                    </li>
                                                    <li>
                                                        <span>Email:</span> {user?.email}
                                                    </li>
                                                    <li>
                                                        <span>Birthday:</span> {user?.dob}
                                                    </li>
                                                    <li>
                                                        <span>Phone:</span> {user?.phone}
                                                    </li>
                                                    <li>
                                                        <span>Gender:</span> {user?.gender}
                                                    </li>
                                                    <li>
                                                        <span>Private Account:</span>{" "}
                                                        {user?.isPrivate ? "Yes" : "No"}
                                                    </li>
                                                </ul>
                                            </div>
                                        </div>

                                        <div className="col-lg-8 col-md-12">
                                            <div className="about-details-information">
                                                <div className="information-box-content">
                                                    <div className="information-header d-flex justify-content-between align-items-center">
                                                        <div className="title">About Me</div>
                                                        {userId === profileId ? <div className="prifile-edit">
                                                            <Link onClick={openAboutPopup}>Edit</Link>
                                                        </div> : null}
                                                    </div>
                                                    <div className="content">
                                                        <p>{user?.aboutMe}</p>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className={`tab-pane fade ${menu === 'Followers' ? ' show active' : ''} `} id="followers" role="tabpanel">
                                    <div className="row">
                                        {followers?.map((data, index) => (
                                            <div key={index} className="col-lg-4 col-sm-6">
                                                <div className="single-friends-card">
                                                    <div className="friends-content">
                                                        <div className="friends-info">
                                                            <Link
                                                                to={{
                                                                    pathname: "/Profile",
                                                                    search: `id=${data?.follower?._id}`,
                                                                }}
                                                            >
                                                                <img
                                                                    src={s3Url+data?.follower?.key}
                                                                    alt="user"
                                                                />
                                                            </Link>
                                                            <div className="text ms-3">
                                                                <h3>
                                                                    <Link
                                                                        to={{
                                                                            pathname: "/Profile",
                                                                            search: `id=${data?.follower?._id}`,
                                                                        }}
                                                                    >
                                                                        {data?.follower?.userName?.length > 10
                                                                            ? data?.follower?.userName?.slice(0, 10) +
                                                                            "..."
                                                                            : data?.follower?.userName}
                                                                    </Link>
                                                                    {userId === profileId ?
                                                                        (allFollowing?.length > 0 ? (
                                                                            allFollowing.some(x => x?.following?._id === data?.follower?._id && !x.isConnect) ? (
                                                                                allFollowing.map(x => {
                                                                                    if (x?.following?._id === data?.follower?._id && !x.isConnect) {
                                                                                        return (
                                                                                            <small
                                                                                                key={x.id}
                                                                                                className='form-text cursor'
                                                                                                onClick={() => handleRequest(x?._id)}
                                                                                            >
                                                                                                Requested
                                                                                            </small>
                                                                                        );
                                                                                    }
                                                                                    return null;
                                                                                })

                                                                            )
                                                                                : allFollowing.some(x => x?.following?._id === data?.follower?._id && x.isConnect) ? null : (
                                                                                    <small
                                                                                        className='form-text text-primary cursor'
                                                                                        onClick={() => saveFollowers(data?.follower?._id, data?.follower?.isPrivate)}
                                                                                    >
                                                                                        {' '} Follow
                                                                                    </small>
                                                                                )
                                                                        ) : (
                                                                            <small
                                                                                className="form-text text-primary cursor"
                                                                                onClick={() =>
                                                                                    saveFollowers(
                                                                                        data?.follower?._id,
                                                                                        data?.follower?.isPrivate
                                                                                    )
                                                                                }
                                                                            >
                                                                                {" "}
                                                                                Follow
                                                                            </small>
                                                                        )) : null}
                                                                </h3>
                                                                {/* <span>65 Mutual Friends</span> */}
                                                            </div>
                                                        </div>
                                                        <ul className="statistics">
                                                            <li>
                                                                <Link>
                                                                    <span className="item-number">
                                                                        {data?.follower?.postCount}
                                                                    </span>
                                                                    <span className="item-text">Post</span>
                                                                </Link>
                                                            </li>
                                                            <li>
                                                                <Link>
                                                                    <span className="item-number">
                                                                        {data?.follower?.followingCount}
                                                                    </span>
                                                                    <span className="item-text">Following</span>
                                                                </Link>
                                                            </li>
                                                            <li>
                                                                <Link>
                                                                    <span className="item-number">
                                                                        {data?.follower?.followersCount}
                                                                    </span>
                                                                    <span className="item-text">Followers</span>
                                                                </Link>
                                                            </li>
                                                        </ul>

                                                        <div className="button-group d-flex  align-items-center">
                                                            {userId === profileId ?
                                                                <div className="send-message-btn">
                                                                    <button type="submit" onClick={() => openFollowerPopup(data?._id)}>Remove</button>
                                                                </div> :
                                                                <div className="add-friend-btn">
                                                                    {data?.follower?._id !== profileId ?
                                                                        (allFollowing.some(x => x?.following?._id === data?.follower?._id && !x.isConnect) ?
                                                                            (
                                                                                allFollowing.map(x => {
                                                                                    if (x?.following?._id === data?.follower?._id && !x.isConnect) {
                                                                                        return (
                                                                                            <button className="request" onClick={() => handleRequest(x?._id)} type="button" >Requested</button>

                                                                                        );
                                                                                    }
                                                                                    return null
                                                                                })) :
                                                                            allFollowing.some(x => x?.following?._id === data?.follower?._id && x.isConnect) ?
                                                                                <button className="following" onClick={() =>
                                                                                    handleFollowers(
                                                                                        data?.follower?._id,
                                                                                        data?.follower?.isPrivate
                                                                                    )
                                                                                } type="button" >Following</button> :
                                                                                <button className="follow" onClick={() =>
                                                                                    handleFollowers(
                                                                                        data?.follower?._id,
                                                                                        data?.follower?.isPrivate
                                                                                    )
                                                                                } type="button">Follow</button>) : null}
                                                                </div>}
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        ))}
                                        {followers.length === 0 ? (
                                            <div className="form-text text-danger text-center">
                                                No Followers
                                            </div>
                                        ) : null}
                                    </div>
                                </div>
                                <div className={`tab-pane fade ${menu === 'Following' ? ' show active' : ''} `} id="following" role="tabpanel">
                                    <div className="row">
                                        {following?.map((data, index) => (
                                            <div key={index} className="col-lg-4 col-sm-6">
                                                <div className="single-friends-card">
                                                    <div className="friends-content">
                                                        <div className="friends-info">
                                                            <Link
                                                                to={{
                                                                    pathname: "/Profile",
                                                                    search: `id=${data?.following?._id}`,
                                                                }}
                                                            >
                                                                <img
                                                                    src={s3Url+data?.following?.key}
                                                                    alt="user"
                                                                />
                                                            </Link>
                                                            <div className="text ms-3">
                                                                <h3>
                                                                    <Link
                                                                        to={{
                                                                            pathname: "/Profile",
                                                                            search: `id=${data?.following?._id}`,
                                                                        }}
                                                                    >
                                                                        {data?.following?.userName}
                                                                    </Link>
                                                                </h3>
                                                                {/* <span>65 Mutual Friends</span> */}
                                                            </div>
                                                        </div>
                                                        <ul className="statistics">
                                                            <li>
                                                                <Link>
                                                                    <span className="item-number">
                                                                        {data?.following?.postCount}
                                                                    </span>
                                                                    <span className="item-text">Post</span>
                                                                </Link>
                                                            </li>
                                                            <li>
                                                                <Link>
                                                                    <span className="item-number">
                                                                        {data?.following?.followingCount}
                                                                    </span>
                                                                    <span className="item-text">Following</span>
                                                                </Link>
                                                            </li>
                                                            <li>
                                                                <Link>
                                                                    <span className="item-number">
                                                                        {data?.following?.followersCount}
                                                                    </span>
                                                                    <span className="item-text">Followers</span>
                                                                </Link>
                                                            </li>
                                                        </ul>
                                                        <div className="button-group d-flex  align-items-center">
                                                            {userId === profileId ?
                                                                <div className="add-friend-btn">
                                                                    {removeFollowing === data?.following?._id ? <button className="follow" type='button'>Follow</button> : <button className="following" onClick={() => {
                                                                        saveFollowers(
                                                                            data?.following?._id,
                                                                            data?.following?.isPrivate
                                                                        ); setRemoveFollowing(data?.following?._id)
                                                                    }
                                                                    } type="submit">Following</button>}
                                                                </div> :
                                                                <div className="add-friend-btn">
                                                                    {data?.following?._id !== profileId ?
                                                                        (allFollowing.some(x => x?.following?._id === data?.following?._id && !x.isConnect) ?
                                                                            (
                                                                                allFollowing.map(x => {
                                                                                    if (x?.following?._id === data?.following?._id && !x.isConnect) {
                                                                                        return (
                                                                                            <button className="request" onClick={() => handleRequest(x?._id)} type="button" >Requested</button>

                                                                                        );
                                                                                    }
                                                                                    return null
                                                                                })) :
                                                                            allFollowing.some(x => x?.following?._id === data?.following?._id && x.isConnect) ?
                                                                                <button className="following" onClick={() =>
                                                                                    handleFollowers(
                                                                                        data?.following?._id,
                                                                                        data?.following?.isPrivate
                                                                                    )
                                                                                } type="button" >Following</button> :
                                                                                <button className="follow" onClick={() =>
                                                                                    handleFollowers(
                                                                                        data?.following?._id,
                                                                                        data?.following?.isPrivate
                                                                                    )
                                                                                } type="button">Follow</button>) : null}
                                                                </div>}
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        ))}
                                        {following.length === 0 ? (
                                            <div className="form-text text-danger text-center">
                                                No Followings
                                            </div>
                                        ) : null}
                                    </div>
                                </div>
                                <div
                                    className={`tab-pane fade ${menu === 'Friends' ? ' show active' : ''} `}
                                    id="friend-request"
                                    role="tabpanel"
                                >
                                    <div className="row">
                                        {friends?.map((data, index) => (
                                            <div key={index} className="col-lg-4 col-sm-6">
                                                <div className="single-friends-card">
                                                    <div className="friends-content">
                                                        <div className="friends-info">
                                                            <Link
                                                                to={{
                                                                    pathname: "/Profile",
                                                                    search: `id=${data?.follower?._id}`,
                                                                }}
                                                            >
                                                                <img
                                                                    src={s3Url+data?.follower?.key}
                                                                    alt="user"
                                                                />
                                                            </Link>
                                                            <div className="text ms-3">
                                                                <h3>
                                                                    <Link
                                                                        to={{
                                                                            pathname: "/Profile",
                                                                            search: `id=${data?.follower?._id}`,
                                                                        }}
                                                                    >
                                                                        {data?.follower?.userName}
                                                                    </Link>
                                                                </h3>
                                                                {/* <span>65 Mutual Friends</span> */}
                                                            </div>
                                                        </div>
                                                        <ul className="statistics">
                                                            <li>
                                                                <Link>
                                                                    <span className="item-number">
                                                                        {data?.follower?.postCount}
                                                                    </span>
                                                                    <span className="item-text">Post</span>
                                                                </Link>
                                                            </li>
                                                            <li>
                                                                <Link>
                                                                    <span className="item-number">
                                                                        {data?.follower?.followingCount}
                                                                    </span>
                                                                    <span className="item-text">Following</span>
                                                                </Link>
                                                            </li>
                                                            <li>
                                                                <Link>
                                                                    <span className="item-number">
                                                                        {data?.follower?.followersCount}
                                                                    </span>
                                                                    <span className="item-text">Followers</span>
                                                                </Link>
                                                            </li>
                                                        </ul>
                                                        <div className="button-group d-flex justify-content-between align-items-center">
                                                            <div className="add-friend-btn">
                                                                <button
                                                                    type="button"
                                                                    onClick={() => acceptFrientRequest(data?._id)}
                                                                >
                                                                    Add Friend
                                                                </button>
                                                            </div>
                                                            <div className="send-message-btn">
                                                                <button
                                                                    type="button"
                                                                    onClick={() => removeFrientRequest(data?._id)}
                                                                >
                                                                    Remove
                                                                </button>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        ))}
                                        {friends?.length === 0 ? (
                                            <div className="form-text text-danger text-center">
                                                No Friend Request
                                            </div>
                                        ) : null}
                                    </div>
                                </div>
                                {/* <div className="tab-pane fade" id="photos" role="tabpanel">
                                    <div className="photos-inner-box-style d-flex justify-content-between align-items-center">
                                        <div className="title">
                                            <h3>Photos</h3>
                                            <span>855</span>
                                        </div>

                                        <ul className="nav nav-tabs" id="myTab" role="tablist">
                                            <li className="nav-item">
                                                <a
                                                    className="nav-link active"
                                                    id="all-photos-tab"
                                                    data-bs-toggle="tab"
                                                    href="#all-photos"
                                                    role="tab"
                                                    aria-controls="all-photos"
                                                >
                                                    Photos of You
                                                </a>
                                            </li>
                                            <li className="nav-item">
                                                <a
                                                    className="nav-link"
                                                    id="photos-albums-tab"
                                                    data-bs-toggle="tab"
                                                    href="#photos-albums"
                                                    role="tab"
                                                    aria-controls="photos-albums"
                                                >
                                                    Your Photos
                                                </a>
                                            </li>
                                            <li className="nav-item">
                                                <a
                                                    className="nav-link"
                                                    id="photos-albums-tab"
                                                    data-bs-toggle="tab"
                                                    href="#all-photos"
                                                    role="tab"
                                                    aria-controls="all-photos"
                                                >
                                                    Albums
                                                </a>
                                            </li>
                                        </ul>

                                        <div className="photos-search-box">
                                            <form>
                                                <input
                                                    type="text"
                                                    className="input-search"
                                                    placeholder="Search photos..."
                                                />
                                                <button type="submit">
                                                    <i className="ri-search-line"></i>
                                                </button>
                                            </form>
                                        </div>
                                    </div>

                                    <div className="tab-content" id="myTabContent">
                                        <div
                                            className="tab-pane fade show active"
                                            id="all-photos"
                                            role="tabpanel"
                                        >
                                            <div className="row">
                                                <div className="col-lg-3 col-sm-6">
                                                    <div className="single-photos-card">
                                                        <a href="#">
                                                            <img
                                                                src="../assets/images/photos/photos-1.jpg"
                                                                alt="image"
                                                            />
                                                        </a>
                                                    </div>
                                                </div>
                                                <div className="col-lg-3 col-sm-6">
                                                    <div className="single-photos-card">
                                                        <a href="#">
                                                            <img
                                                                src="../assets/images/photos/photos-2.jpg"
                                                                alt="image"
                                                            />
                                                        </a>
                                                    </div>
                                                </div>
                                                <div className="col-lg-3 col-sm-6">
                                                    <div className="single-photos-card">
                                                        <a href="#">
                                                            <img
                                                                src="../assets/images/photos/photos-3.jpg"
                                                                alt="image"
                                                            />
                                                        </a>
                                                    </div>
                                                </div>
                                                <div className="col-lg-3 col-sm-6">
                                                    <div className="single-photos-card">
                                                        <a href="#">
                                                            <img
                                                                src="../assets/images/photos/photos-4.jpg"
                                                                alt="image"
                                                            />
                                                        </a>
                                                    </div>
                                                </div>
                                                <div className="col-lg-3 col-sm-6">
                                                    <div className="single-photos-card">
                                                        <a href="#">
                                                            <img
                                                                src="../assets/images/photos/photos-5.jpg"
                                                                alt="image"
                                                            />
                                                        </a>
                                                    </div>
                                                </div>
                                                <div className="col-lg-3 col-sm-6">
                                                    <div className="single-photos-card">
                                                        <a href="#">
                                                            <img
                                                                src="../assets/images/photos/photos-6.jpg"
                                                                alt="image"
                                                            />
                                                        </a>
                                                    </div>
                                                </div>
                                                <div className="col-lg-3 col-sm-6">
                                                    <div className="single-photos-card">
                                                        <a href="#">
                                                            <img
                                                                src="../assets/images/photos/photos-7.jpg"
                                                                alt="image"
                                                            />
                                                        </a>
                                                    </div>
                                                </div>
                                                <div className="col-lg-3 col-sm-6">
                                                    <div className="single-photos-card">
                                                        <a href="#">
                                                            <img
                                                                src="../assets/images/photos/photos-8.jpg"
                                                                alt="image"
                                                            />
                                                        </a>
                                                    </div>
                                                </div>
                                                <div className="col-lg-3 col-sm-6">
                                                    <div className="single-photos-card">
                                                        <a href="#">
                                                            <img
                                                                src="../assets/images/photos/photos-9.jpg"
                                                                alt="image"
                                                            />
                                                        </a>
                                                    </div>
                                                </div>
                                                <div className="col-lg-3 col-sm-6">
                                                    <div className="single-photos-card">
                                                        <a href="#">
                                                            <img
                                                                src="../assets/images/photos/photos-10.jpg"
                                                                alt="image"
                                                            />
                                                        </a>
                                                    </div>
                                                </div>
                                                <div className="col-lg-3 col-sm-6">
                                                    <div className="single-photos-card">
                                                        <a href="#">
                                                            <img
                                                                src="../assets/images/photos/photos-11.jpg"
                                                                alt="image"
                                                            />
                                                        </a>
                                                    </div>
                                                </div>
                                                <div className="col-lg-3 col-sm-6">
                                                    <div className="single-photos-card">
                                                        <a href="#">
                                                            <img
                                                                src="../assets/images/photos/photos-12.jpg"
                                                                alt="image"
                                                            />
                                                        </a>
                                                    </div>
                                                </div>
                                                <div className="col-lg-3 col-sm-6">
                                                    <div className="single-photos-card">
                                                        <a href="#">
                                                            <img
                                                                src="../assets/images/photos/photos-13.jpg"
                                                                alt="image"
                                                            />
                                                        </a>
                                                    </div>
                                                </div>
                                                <div className="col-lg-3 col-sm-6">
                                                    <div className="single-photos-card">
                                                        <a href="#">
                                                            <img
                                                                src="../assets/images/photos/photos-14.jpg"
                                                                alt="image"
                                                            />
                                                        </a>
                                                    </div>
                                                </div>
                                                <div className="col-lg-3 col-sm-6">
                                                    <div className="single-photos-card">
                                                        <a href="#">
                                                            <img
                                                                src="../assets/images/photos/photos-15.jpg"
                                                                alt="image"
                                                            />
                                                        </a>
                                                    </div>
                                                </div>
                                                <div className="col-lg-3 col-sm-6">
                                                    <div className="single-photos-card">
                                                        <a href="#">
                                                            <img
                                                                src="../assets/images/photos/photos-16.jpg"
                                                                alt="image"
                                                            />
                                                        </a>
                                                    </div>
                                                </div>
                                            </div>

                                            <div className="load-more-posts-btn">
                                                <a href="#">
                                                    <i className="flaticon-loading"></i> Load More
                                                </a>
                                            </div>
                                        </div>

                                        <div
                                            className="tab-pane fade"
                                            id="photos-albums"
                                            role="tabpanel"
                                        >
                                            <div className="row justify-content-center">
                                                <div className="col-lg-3 col-sm-6">
                                                    <div className="single-photos-card">
                                                        <a href="#">
                                                            <img
                                                                src="../assets/images/photos/photos-16.jpg"
                                                                alt="image"
                                                            />
                                                        </a>
                                                    </div>
                                                </div>
                                                <div className="col-lg-3 col-sm-6">
                                                    <div className="single-photos-card">
                                                        <a href="#">
                                                            <img
                                                                src="../assets/images/photos/photos-15.jpg"
                                                                alt="image"
                                                            />
                                                        </a>
                                                    </div>
                                                </div>
                                                <div className="col-lg-3 col-sm-6">
                                                    <div className="single-photos-card">
                                                        <a href="#">
                                                            <img
                                                                src="../assets/images/photos/photos-14.jpg"
                                                                alt="image"
                                                            />
                                                        </a>
                                                    </div>
                                                </div>
                                                <div className="col-lg-3 col-sm-6">
                                                    <div className="single-photos-card">
                                                        <a href="#">
                                                            <img
                                                                src="../assets/images/photos/photos-13.jpg"
                                                                alt="image"
                                                            />
                                                        </a>
                                                    </div>
                                                </div>
                                                <div className="col-lg-3 col-sm-6">
                                                    <div className="single-photos-card">
                                                        <a href="#">
                                                            <img
                                                                src="../assets/images/photos/photos-12.jpg"
                                                                alt="image"
                                                            />
                                                        </a>
                                                    </div>
                                                </div>
                                                <div className="col-lg-3 col-sm-6">
                                                    <div className="single-photos-card">
                                                        <a href="#">
                                                            <img
                                                                src="../assets/images/photos/photos-11.jpg"
                                                                alt="image"
                                                            />
                                                        </a>
                                                    </div>
                                                </div>
                                                <div className="col-lg-3 col-sm-6">
                                                    <div className="single-photos-card">
                                                        <a href="#">
                                                            <img
                                                                src="../assets/images/photos/photos-10.jpg"
                                                                alt="image"
                                                            />
                                                        </a>
                                                    </div>
                                                </div>
                                                <div className="col-lg-3 col-sm-6">
                                                    <div className="single-photos-card">
                                                        <a href="#">
                                                            <img
                                                                src="../assets/images/photos/photos-9.jpg"
                                                                alt="image"
                                                            />
                                                        </a>
                                                    </div>
                                                </div>
                                                <div className="col-lg-3 col-sm-6">
                                                    <div className="single-photos-card">
                                                        <a href="#">
                                                            <img
                                                                src="../assets/images/photos/photos-8.jpg"
                                                                alt="image"
                                                            />
                                                        </a>
                                                    </div>
                                                </div>
                                                <div className="col-lg-3 col-sm-6">
                                                    <div className="single-photos-card">
                                                        <a href="#">
                                                            <img
                                                                src="../assets/images/photos/photos-7.jpg"
                                                                alt="image"
                                                            />
                                                        </a>
                                                    </div>
                                                </div>
                                                <div className="col-lg-3 col-sm-6">
                                                    <div className="single-photos-card">
                                                        <a href="#">
                                                            <img
                                                                src="../assets/images/photos/photos-6.jpg"
                                                                alt="image"
                                                            />
                                                        </a>
                                                    </div>
                                                </div>
                                                <div className="col-lg-3 col-sm-6">
                                                    <div className="single-photos-card">
                                                        <a href="#">
                                                            <img
                                                                src="../assets/images/photos/photos-5.jpg"
                                                                alt="image"
                                                            />
                                                        </a>
                                                    </div>
                                                </div>
                                                <div className="col-lg-3 col-sm-6">
                                                    <div className="single-photos-card">
                                                        <a href="#">
                                                            <img
                                                                src="../assets/images/photos/photos-4.jpg"
                                                                alt="image"
                                                            />
                                                        </a>
                                                    </div>
                                                </div>
                                                <div className="col-lg-3 col-sm-6">
                                                    <div className="single-photos-card">
                                                        <a href="#">
                                                            <img
                                                                src="../assets/images/photos/photos-3.jpg"
                                                                alt="image"
                                                            />
                                                        </a>
                                                    </div>
                                                </div>
                                                <div className="col-lg-3 col-sm-6">
                                                    <div className="single-photos-card">
                                                        <a href="#">
                                                            <img
                                                                src="../assets/images/photos/photos-2.jpg"
                                                                alt="image"
                                                            />
                                                        </a>
                                                    </div>
                                                </div>
                                                <div className="col-lg-3 col-sm-6">
                                                    <div className="single-photos-card">
                                                        <a href="#">
                                                            <img
                                                                src="../assets/images/photos/photos-1.jpg"
                                                                alt="image"
                                                            />
                                                        </a>
                                                    </div>
                                                </div>
                                            </div>

                                            <div className="load-more-posts-btn">
                                                <a href="#">
                                                    <i className="flaticon-loading"></i> Load More
                                                </a>
                                            </div>
                                        </div>
                                    </div>
                                </div> */}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <Dialog open={openBid} fullWidth maxWidth="xs">
                <DialogTitle>
                    Post Bidding{" "}
                    <IconButton className="float-end" onClick={closeBidPopup}>
                        <i className="material-icons-round">close</i>
                    </IconButton>
                </DialogTitle>
                <DialogContent>
                    <form onSubmit={handleBidAmountSubmit}>
                        <div className="from-group mb-3">
                            <label htmlFor="categoryName" className="form-label">
                                Bid Amount
                            </label>
                            <div className="input-group">
                                <div className="input-group-prepend">
                                    <span className="input-group-text">₹</span>
                                </div>
                                <input
                                    type="text"
                                    value={bidAmount}
                                    onChange={handleBidAmount}
                                    name="biddingAmount"
                                    className="form-control"
                                    placeholder="Bid Amount"
                                />
                            </div>
                            {!bidAmount && submit ? (
                                <span className="form-text text-danger">
                                    This field is required.
                                </span>
                            ) : null}
                        </div>
                        <div className="d-flex justify-content-end">
                            <button type="submit" className="btn-save me-3">
                                {" "}
                                Submit{" "}
                            </button>
                            <button
                                type="button"
                                className="btn-cancel"
                                onClick={resetBidAmount}
                            >
                                {" "}
                                Reset{" "}
                            </button>
                        </div>
                    </form>
                </DialogContent>
            </Dialog>
            <Dialog open={hide}>
                <DialogContent>
                    <div className="text-center m-4">
                        <h5 className="mb-4">
                            Are you sure you want to hide <br /> the selected Post ?
                        </h5>
                        <button
                            type="button"
                            className="btn btn-save mx-3"
                            onClick={handleHidePost}
                        >
                            Yes
                        </button>
                        <button
                            type="button"
                            className="btn btn-cancel "
                            onClick={closeHidePopup}
                        >
                            No
                        </button>
                    </div>
                </DialogContent>
            </Dialog>
            <Dialog open={block}>
                <DialogContent>
                    <div className="text-center m-4">
                        <h5 className="mb-4">
                            Are you sure you want to block <br /> the selected User ?
                        </h5>
                        <button
                            type="button"
                            className="btn btn-save mx-3"
                            onClick={handleBlockUser}
                        >
                            Yes
                        </button>
                        <button
                            type="button"
                            className="btn btn-cancel "
                            onClick={closeBlockPopup}
                        >
                            No
                        </button>
                    </div>
                </DialogContent>
            </Dialog>
            <Dialog open={blocking}>
                <DialogContent>
                    <div className="text-center m-4">
                        <h5 className="mb-4">
                            Are you sure you want to remove <br /> the block ?
                        </h5>
                        <button
                            type="button"
                            className="btn btn-save mx-3"
                            onClick={handleBlockingUser}
                        >
                            Yes
                        </button>
                        <button
                            type="button"
                            className="btn btn-cancel "
                            onClick={closeBlockingPopup}
                        >
                            No
                        </button>
                    </div>
                </DialogContent>
            </Dialog>
            <Dialog open={remove}>
                <DialogContent>
                    <div className="text-center m-4">
                        <h5 className="mb-4">
                            Are you sure you want to remove <br /> the selected follower ?
                        </h5>
                        <button
                            type="button"
                            className="btn btn-save mx-3"
                            onClick={handleDeleteFollower}
                        >
                            Yes
                        </button>
                        <button
                            type="button"
                            className="btn btn-cancel "
                            onClick={closeFollowerPopup}
                        >
                            No
                        </button>
                    </div>
                </DialogContent>
            </Dialog>
            <Dialog open={share} fullWidth maxWidth="xs">
                <DialogTitle className='text-secondary'>
                    Share On Social Media<IconButton className="float-end" onClick={closeSharePopup} >
                        <i className="material-icons-round">close</i>
                    </IconButton>
                </DialogTitle>
                <DialogContent>
                    <div className="p-4 d-flex justify-content-between">
                        <WhatsappShareButton url={shareUrl} title={content} >
                            <WhatsappIcon size={32} round={true} />
                        </WhatsappShareButton>
                        <FacebookShareButton url={shareUrl} title={content} >
                            <FacebookIcon size={32} round={true} />
                        </FacebookShareButton>
                        <TwitterShareButton url={shareUrl} title={content}>
                            <TwitterIcon size={32} round={true} />
                        </TwitterShareButton>
                        <TelegramShareButton url={shareUrl} title={content}>
                            <TelegramIcon size={32} round={true} />
                        </TelegramShareButton>
                        <LinkedinShareButton url={shareUrl} title={content}>
                            <LinkedinIcon size={32} round={true} />
                        </LinkedinShareButton>
                        <EmailShareButton url={shareUrl} title={content}>
                            <EmailIcon size={32} round={true} />
                        </EmailShareButton>
                    </div>
                </DialogContent>
            </Dialog>


            <Dialog open={edit} fullWidth maxWidth="sm">
                <DialogTitle >
                    Edit User Details<IconButton className="float-end" onClick={closeEditPopup} >
                        <i className="material-icons-round">close</i>
                    </IconButton>
                </DialogTitle>
                <DialogContent>
                    <div className="row">
                        <div className="col-lg-6 col-md-6 col-sm-12 mb-3">
                            <div className="form-group">
                                <label>User Name</label>
                                <input type="text" placeholder="User Name" className="form-control" name='userName' onChange={handleDetails} value={editDetails?.userName} />
                                {!editDetails?.userName && submitDetails ? <span className="form-text text-danger">
                                    This field is required.
                                </span> : null}
                            </div>
                        </div>
                        {/* <div className="col-lg-6 col-md-6 col-sm-12 mb-3">
                            <div className="form-group">
                                <label>Phone No</label>
                                <input type="text" id="mobile_code" className="form-control" onChange={handleDetails} value={editDetails?.phone} name="phone" />
                                {!editDetails?.phone && submitDetails ? (
                                    <span className="text-danger form-text">
                                        This field is required.
                                    </span>
                                ) : !isValidPhone(editDetails?.phone) && submitDetails ? (
                                    <span className="text-danger form-text">
                                        Enter valid Phone Number.
                                    </span>
                                ) : null}
                            </div>
                        </div> */}
                        <div className="col-lg-6 col-md-6 col-sm-12 mb-3">
                            <div className="form-group">
                                <label>Email Id</label>
                                <input type="text" placeholder="Email Id" className="form-control" onChange={handleDetails} name='email' value={editDetails?.email} />
                                {editDetails?.email ?
                                    (!isValidEmail(editDetails?.email) && submitDetails ? (
                                        <span className="text-danger form-text">
                                            Enter valid Email Id.
                                        </span>
                                    ) : null) : null}
                            </div>
                        </div>
                        <div className="col-lg-6 col-md-6 col-sm-12 mb-3">
                            <div className="form-group">
                                <label>DOB(Date of Birth)</label>
                                <input type="date" className="form-control" name='dob' onChange={handleDetails} value={editDetails?.dob} />
                            </div>
                        </div>
                        <div className="col-lg-6 col-md-6 col-sm-12 mb-3">
                            <div className="form-group">
                                <label>Gender</label>
                                <select className="form-select" onChange={handleDetails} value={editDetails?.gender} name='gender'>
                                    <option>Male</option>
                                    <option>Female</option>
                                    <option>Others</option>
                                </select>
                            </div>
                        </div>
                        <div className="col-lg-6 col-md-6 col-sm-12 mb-3">
                            <div className="form-group">
                                <label>Account Info</label>
                                <select className="form-select" onChange={handleDetails} value={editDetails?.isPrivate} name='isPrivate'>
                                    <option value={false}>Public</option>
                                    <option value={true}>Private</option>
                                </select>
                            </div>
                        </div>
                    </div>
                    <div className="d-flex justify-content-end">
                        <button type='button' onClick={updateUserDetails} className="btn btn-save mx-3">Update</button>
                        <Link onClick={closeEditPopup} className="btn btn-cancel">Cancel</Link>
                    </div>
                </DialogContent>
            </Dialog>

            <Dialog open={about} fullWidth maxWidth="sm">
                <DialogTitle >
                    Edit About Me<IconButton className="float-end" onClick={closeAboutPopup} >
                        <i className="material-icons-round">close</i>
                    </IconButton>
                </DialogTitle>
                <DialogContent>
                    <div className="row">
                        <div className="col-lg-12 col-md-12 col-sm-12">
                            <div className="form-group notes-form-group">
                                <label>About Me</label>
                                <textarea className="form-control" onChange={handleDetails} value={editDetails?.aboutMe} name='aboutMe' placeholder="About Me"></textarea>
                            </div>
                        </div>
                    </div>
                    <div className="d-flex justify-content-end mt-3">
                        <button type='button' onClick={updateUserDetails} className="btn btn-save mx-3">Update</button>
                        <Link onClick={closeAboutPopup} className="btn btn-cancel">Cancel</Link>
                    </div>
                </DialogContent>
            </Dialog>
            <Dialog open={openReport} fullWidth maxWidth="xs" >
                <DialogTitle>Report
                    <IconButton className="float-end" onClick={closeReportPopup} >
                        <i className="material-icons-round" >close</i>
                    </IconButton>
                </DialogTitle>
                <DialogContent>
                    <div className='report'>
                        <h6>Report this Post</h6>
                        <span>Help us understand what's happening with this post. How would you describe it?</span>
                        <ul className='mt-2'>
                            <li> <span onClick={() => openDescriptionPopup('Misleading or scam')}>Misleading or scam </span> </li>
                            <li> <span onClick={() => openDescriptionPopup('Sexually inappropriate')}> Sexually inappropriate </span></li>
                            <li><span onClick={() => openDescriptionPopup('Offensive')}>Offensive</span></li>
                            <li><span onClick={() => openDescriptionPopup('Violence')}>Violence</span></li>
                            <li><span onClick={() => openDescriptionPopup('Prohibited content')}>Prohibited content</span> </li>
                            <li><span onClick={() => openDescriptionPopup('Spam')}>Spam </span> </li>
                            <li><span onClick={() => openDescriptionPopup('False news')}>False news </span></li>
                            <li><span onClick={() => openDescriptionPopup('Political candidate or issue')}>Political candidate or issue </span> </li>
                            <li><span onClick={() => openDescriptionPopup('Other')}>Other</span></li>
                        </ul>
                    </div>
                </DialogContent>
            </Dialog>

            <Dialog open={openDescription} fullWidth maxWidth="sm" >
                <DialogTitle>Report
                    <IconButton className="float-end" onClick={closeDescriptionPopup} >
                        <i className="material-icons-round" >close</i>
                    </IconButton>
                </DialogTitle>
                <DialogContent>
                    <div className="row">
                        <div className="col-lg-12 col-md-12 col-sm-12">
                            <div className="form-group">
                                <label className='mb-2'>Description</label>
                                <textarea className="form-control" rows={4} onChange={handleDescription} name='description' placeholder="Description"></textarea>
                                {!report?.description && submitReport ? (
                                    <span className="form-text text-danger">
                                        This field is required.
                                    </span>
                                ) : null}
                            </div>
                        </div>
                    </div>
                    <div className="float-end mt-3">
                        <button type="button" className="btn btn-save mx-3" onClick={handleReport} >Submit</button>
                        <button type="button" className="btn btn-cancel " onClick={closeDescriptionPopup}>Cancel</button>
                    </div>
                </DialogContent>
            </Dialog>

            <Dialog open={reportResponse} fullWidth maxWidth="xs" >
                <DialogTitle className='p-0'>
                    <IconButton className="float-end m-2" onClick={closeReportResponsePopup} >
                        <i className="material-icons-round" >close</i>
                    </IconButton>
                </DialogTitle>
                <DialogContent className='responseModal'>
                    <span><i className="material-icons-round" >check_circle</i></span>
                    <h5>Thank you for submitting a report.</h5>
                    <div className='my-2'><Chip color="primary" variant="outlined" label={report?.type}></Chip></div>
                    <span>We'll let you know when there's an update on this report.</span>
                </DialogContent>
            </Dialog>

            <Dialog open={openDelete}>
                <DialogContent>
                    <div className="text-center m-4">
                        <h5 className="mb-4">Are you sure you want to Delete <br /> the selected Post ?</h5>
                        <button type="button" className="btn btn-save mx-3" onClick={deletePostDetails}>Yes</button>
                        <button type="button" className="btn btn-cancel " onClick={closeDeletePopup}>No</button>
                    </div>
                </DialogContent>
            </Dialog>
        </>
    );
}
export default Profile;
