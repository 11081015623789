
import { Link } from 'react-router-dom';
import Header from '../../components/Header';
import SideBar from '../../components/SideBar';
import { useEffect, useState } from 'react';
import { getFilterSuggestionUsersList } from '../../api/user';
import { acceptFollower, getAllFollowings, saveFollower } from '../../api/follow';
import { getUserId } from '../../utils/Storage';
import { s3Url } from '../../utils/FileUpload';

function SuggestionPeople() {
    const [suggestionPeople, setSuggestionPeople] = useState([]);
    const [count, setCount] = useState(0)
    const [next, setNext] = useState(0);
    const [reload, setReload] = useState(false);
    const [scroll, setScroll] = useState(false);
    const [allFollowing, setAllFollowing] = useState([])


    useEffect(() => {
        getSuggestionPeopleList();
    }, [next])

    useEffect(() => {
        getAllFollowing()
        window.addEventListener('scroll', handleScroll);
    }, [])

    const handleScroll = () => {
        const value = window.innerHeight + window.scrollY >=
            document.documentElement.scrollHeight - 200
        setScroll(value)
    };
    useEffect(() => {
        if (scroll) {
            loadMore();
        }
    }, [scroll])


    const loadMore = () => {
        let nextPeople = next;
        nextPeople = nextPeople + 9;
        if (count <= nextPeople) {
            setReload(true)
        }
        else {
            setNext(nextPeople);
        }
    };

    const getSuggestionPeopleList = () => {
        const data = {
            limit: 9,
            page: next,
        };
        getFilterSuggestionUsersList(data)
            .then((res) => {
                const peopleList = res?.data?.result?.suggestionUserList;
                setSuggestionPeople([...suggestionPeople, ...peopleList]);
                setCount(res?.data?.result?.suggestionUserCount)
            })
            .catch((err) => {
                console.log(err);
            });
    };

    const getAllFollowing = () => {
        const data = { id: getUserId() };
        getAllFollowings(data)
            .then((res) => {
                setAllFollowing(res?.data?.result);
            })
            .catch((err) => {
                console.log(err);
            });
    };

    const handleFollowers = (id, connect) => {
        const data = {
            follower: getUserId(),
            following: id,
            isConnect: connect === false ? true : false,
        };
        saveFollower(data)
            .then((res) => {
                getAllFollowing()
            })
            .catch((err) => {
                console.log(err);
            });

    }

    const handleRequest = (id) => {
        const data = { _id: id, isConnect: false };
        acceptFollower(data)
            .then((res) => {
                getAllFollowing()
            })
            .catch((err) => {
                console.log(err);
            });
    };


    return (
        <>

            <Header />
            <div className="content-page-box-area">
                <div className="row">
                    <SideBar />
                    <div className="col-lg-9 col-md-12">
                        <div className="content-page-box-area">
                            <div className="page-banner-box bg-5">
                                <h3>Suggestion People</h3>
                            </div>
                            <div className="row justify-content-center">
                                {suggestionPeople?.map((data, index) =>
                                    <div className="col-lg-4 col-sm-6" key={index}>
                                        <div className="single-friends-card">
                                            <div className="friends-content">
                                                <div className="friends-info">
                                                    <Link to={{ pathname: '/Profile', search: `id=${data?._id}` }} >
                                                        <img src={s3Url+data?.key} alt="suggestionUser" />
                                                    </Link>
                                                    <div className="text ms-3">
                                                        <h3><Link to={{ pathname: '/Profile', search: `id=${data?._id}` }} >{data?.userName}</Link></h3>
                                                        <span>{data?.userId}</span>
                                                    </div>
                                                </div>
                                                <ul className="statistics">
                                                    <li>
                                                        <Link >
                                                            <span className="item-number">{data?.postCount}</span>
                                                            <span className="item-text">Post</span>
                                                        </Link>
                                                    </li>
                                                    <li>
                                                        <Link >
                                                            <span className="item-number">{data?.followingCount}</span>
                                                            <span className="item-text">Following</span>
                                                        </Link>
                                                    </li>
                                                    <li>
                                                        <Link>
                                                            <span className="item-number">{data?.followersCount}</span>
                                                            <span className="item-text">Followers</span>
                                                        </Link>
                                                    </li>
                                                </ul>
                                                <div className="button-group people-list-item">
                                                    <div className="add-friend-btn">
                                                        {allFollowing.some(x => x?.following?._id === data?._id && !x.isConnect) ?
                                                            (
                                                                allFollowing.map(x => {
                                                                    if (x?.following?._id === data?._id && !x.isConnect) {
                                                                        return (
                                                                            <button className="request" onClick={() => handleRequest(x?._id)} type="button" >Requested</button>

                                                                        );
                                                                    }
                                                                    return null
                                                                })) :
                                                            allFollowing.some(x => x?.following?._id === data?._id && x.isConnect) ?
                                                                <button className="following" onClick={() =>
                                                                    handleFollowers(
                                                                        data?._id,
                                                                        data?.isPrivate
                                                                    )
                                                                } type="button" >Following</button> :
                                                                <button className="follow" onClick={() =>
                                                                    handleFollowers(
                                                                        data?._id,
                                                                        data?.isPrivate
                                                                    )
                                                                } type="button">Follow</button>}
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>)}
                            </div>
                            {reload ?
                                <div className='form-text text-danger text-center'>No Suggestion People</div> : null}
                        </div>
                    </div>
                </div>
            </div>

        </>
    );
}
export default SuggestionPeople;