
import { useEffect, useState } from 'react';
import Header from '../../components/Header'; 
import SideBar from '../../components/SideBar'; 
import SideBarRight from '../../components/SideBarRight';
import { getUserId } from '../../utils/Storage';
import { getFilterNotificationList } from '../../api/notification';
import { Link } from 'react-router-dom';
import { timeCal } from '../../utils/dateformat';
import { s3Url } from '../../utils/FileUpload';
function Notifications() { 
    const [notification, setNotification] = useState([])
    const [count,setCount]=useState('')
    const [scroll, setScroll] = useState(false);
    const [next,setNext]=useState(0);
    const [reload,setReload]=useState(false)

    useEffect(() => {
        getNotificationList()

    }, [next])

    useEffect(()=>{
        window.addEventListener('scroll', handleScroll);

    },[])
    const handleScroll = () => {
        const value = window.innerHeight + window.scrollY >=
            document.documentElement.scrollHeight - 200
        setScroll(value)
    };
    useEffect(() => {
        if (scroll) {
            loadMorePost();
        }
    }, [scroll])

    const getNotificationList = () => {
        const data = {
            limit: 10,
            page: next,
            toUser: getUserId()
        };
        getFilterNotificationList(data).then(res => {
            const notificationList = res?.data?.result?.notificationList
            setNotification([...notification, ...notificationList]);
            setCount(res?.data?.result?.notificationCount)
        }).catch(err => console.log(err))
    }

    const loadMorePost = () => {
        let nextNotification = next;
        nextNotification = nextNotification + 10;
        if (count <= nextNotification) {
            setReload(true)
        }
        else {
            setNext(nextNotification);
        }
    };

	return( 
	<>
    <Header />

        <div class="content-page-box-area">
            <div class="row">
                <SideBar/>
                
                <div class="col-lg-6 col-md-12">
                    <div class="content-page-box-area">
                        <div class="page-banner-box bg-5">
                            <h3>Notifications</h3>
                        </div>
                        <div class="all-notifications-body">
                            {notification?.map((data,index)=>
                            <div key={index} class="item d-flex justify-content-between align-items-center">
                                <div class="figure">
                                    <Link  to={{ pathname: '/Profile', search: `id=${data?.fromUser?._id}` }}><img src={s3Url+data?.fromUser?.key} class="rounded-circle" alt="user" /></Link>
                                </div>
                                <div class="text">
                                    <h4><Link to={{ pathname: '/Profile', search: `id=${data?.fromUser?._id}` }} >{data?.fromUser?.userName}</Link></h4>
                                    <span>{data?.description}</span>
                                    <span class="main-color">{timeCal(data?.createdOn)}</span>
                                </div>
                                {/* <div class="icon">
                                    <Link ><i class="flaticon-x-mark"></i></Link>
                                </div> */}
                            </div>)}
                            {reload ?
                                <div className='form-text text-danger text-center'>The notification has ended.</div> : null}
                        </div>
                    </div>                
                </div>                    
                <SideBarRight/>
            </div>
        </div>
        
            
	
  </> 
  ); 
  } 
  export default Notifications;