import React, { useEffect, useState } from 'react';
import { getAllBanner } from '../api/banner';
import { getFilterSuggestionUsersList } from '../api/user';
import { Link } from 'react-router-dom';
import Carousel from 'react-material-ui-carousel';
import { acceptFollower, getAllFollowings, saveFollower } from '../api/follow';
import { getUserId } from '../utils/Storage';
import { s3Url } from '../utils/FileUpload';
function SideBarRightProfile() {
    const [banner, setBanner] = useState([]);
    const [suggesstionUsers, setSeggestionUsers] = useState([])
    const [birthday, setBirthday] = useState([])
    const [allFollowing, setAllFollowing] = useState([])

    useEffect(() => {
        getBannerList()
        getSuggestionUsersList()
        getTodayBirthday()
        getAllFollowing()

    }, [])

    const getBannerList = () => {
        getAllBanner()
            .then((res) => {
                setBanner(res?.data?.result);
            })
            .catch((err) => {
                console.log(err);
            });
    };

    const getSuggestionUsersList = () => {
        getFilterSuggestionUsersList().then(res => {
            setSeggestionUsers(res?.data?.result?.suggestionUserList);
        }).catch(err => {
            console.log(err);
        })
    }
    const getAllFollowing = () => {
        const data = { id: getUserId() };
        getAllFollowings(data)
            .then((res) => {
                setAllFollowing(res?.data?.result);
            })
            .catch((err) => {
                console.log(err);
            });
    };
    const getTodayBirthday = () => {
        const data = { id: getUserId() };
        getAllFollowings(data)
            .then((res) => {
                const result = res?.data?.result
                const todayBirthday = result?.filter(x => new Date(x?.following?.dob).setHours(0, 0, 0, 0) === new Date().setHours(0, 0, 0, 0))
                setBirthday(todayBirthday)
            })
            .catch((err) => {
                console.log(err);
            });
    }

    const handleFollowers = (id, connect) => {
        const data = {
            follower: getUserId(),
            following: id,
            isConnect: connect === false ? true : false,
        };
        saveFollower(data)
            .then((res) => {
                getAllFollowing()
            })
            .catch((err) => {
                console.log(err);
            });

    }

    const handleRequest = (id) => {
        const data = { _id: id, isConnect: false };
        acceptFollower(data)
            .then((res) => {
                getAllFollowing()
            })
            .catch((err) => {
                console.log(err);
            });
    };


    return (
        <>

            <div class="col-lg-4 col-md-12">
                <aside class="widget-area">
                    <div class="widget widget-birthday">
                        <div class="widget-heading">
                            <h3 class="widget-title">Today Birthdays</h3>
                        </div>
                        {birthday?.length > 0 ?
                                    (birthday?.map((data, index) =>
                                        <article className="item" key={index}>
                                            <Link className="thumb" to={{ pathname: '/Profile', search: `id=${data?.following?._id}` }}>
                                                <img className='fullimage' src={s3Url+data?.following?.key} alt="user" />
                                            </Link>
                                            <div className="info">
                                                <h4 className="title">
                                                    <Link to={{ pathname: '/Profile', search: `id=${data?.following?._id}` }}>{data?.following?.userName}</Link>
                                                </h4>
                                            </div>
                                        </article>)) :
                                    <div className='form-text text-danger text-center'>There is no birthday today.</div>}
                    </div>
                    <div class="widget widget-explore-events">
                        <div class="widget-heading">
                            <h3 class="widget-title">Advertisement</h3>
                        </div>
                        <Carousel animation='slide' indicatorIconButtonProps={{ style: { display: 'none', }, }}>
                            {
                                banner?.map((item, index) => <div key={index}>
                                    <Link target='_blank' to={item?.link}> <img src={s3Url+item?.key} alt={`banner ${index}`} /></Link>
                                </div>)
                            }
                        </Carousel>
                    </div>
                    <div class="widget widget-who-following">
                        <div class="widget-heading">
                            <h3 class="widget-title">Suggestion</h3>
                            <span><Link to="/SuggestionPeople">See All</Link></span>
                        </div>
                        {suggesstionUsers?.slice(0, 5).map((data, index) =>
                                    <div key={index} className="following-item d-flex justify-content-between align-items-center">
                                        <Link to={{ pathname: '/Profile', search: `id=${data?._id}` }}><img src={s3Url+data?.key} className="rounded-circle" alt="user" /></Link>
                                        <h3 className="name ms-1"><Link to={{ pathname: '/Profile', search: `id=${data?._id}` }}>{data?.userName}</Link></h3>
                                        {allFollowing.some(x => x?.following?._id === data?._id && !x.isConnect) ?
                                            (
                                                allFollowing.map((x, i) => {
                                                    if (x?.following?._id === data?._id && !x.isConnect) {
                                                        return (
                                                            <span key={i} className="add-friend-request">
                                                                <Link onClick={() => handleRequest(x?._id)}>Requested</Link>
                                                            </span>
                                                        );
                                                    }
                                                    return null
                                                })) :
                                            allFollowing.some(x => x?.following?._id === data?._id && x.isConnect) ?
                                                <span className="add-friend-following">
                                                    <Link onClick={() =>
                                                        handleFollowers(
                                                            data?._id,
                                                            data?.isPrivate
                                                        )
                                                    }>Following</Link>
                                                </span> :
                                                <span className="add-friend">
                                                    <Link onClick={() =>
                                                        handleFollowers(
                                                            data?._id,
                                                            data?.isPrivate
                                                        )}>Follow</Link>
                                                </span>}
                                    </div>)}
                    </div>
                </aside>
            </div>


        </>
    );
}
export default SideBarRightProfile;