import React, { useEffect, useState } from 'react'; 
import { getAllBannerByLogOut } from '../api/banner';
import { getSuggestionUsersByLogOut } from '../api/user';
import { Link } from 'react-router-dom';
import Carousel from 'react-material-ui-carousel';
import { s3Url } from '../utils/FileUpload';
function SideBarRightLogin({onChildClick}) 
{ 
    const [banner, setBanner] = useState([]);
    const [suggesstionUsers, setSeggestionUsers] = useState([])

    useEffect(() => {
        getBannerList()
        getSuggestionUsersList()
    }, [])

    const getBannerList = () => {
        getAllBannerByLogOut()
            .then((res) => {
                setBanner(res?.data?.result);
            })
            .catch((err) => {
                console.log(err);
            });
    };

    const getSuggestionUsersList = () => {
        getSuggestionUsersByLogOut().then(res => {
            setSeggestionUsers(res?.data?.result);
        }).catch(err => {
            console.log(err);
        })
    }

    return( 
    <>    
        <div class="col-lg-3 col-md-3">
                <div className="sidemenu-area-1">
                    <div className="sidemenu-nav-1 ps-0 me-3 metisMenu h-100" id="sidemenu-nav" data-simplebar>
                        <aside class="widget-area mb-5" >
                            <div class="widget  widget-watch-video">
                                <div class="widget-heading">
                                    <h3 class="widget-title">Advertisement</h3>
                                </div>
                                 <Carousel animation='slide' indicatorIconButtonProps={{ style: { display: 'none', }, }}>
                                    {
                                        banner?.map((item, index) => <div key={index}>
                                           <Link target='_blank' to={item?.link}> <img  src={s3Url+item?.key} alt={`banner ${index}`} /></Link>
                                        </div>)
                                    }
                                </Carousel>
                            </div>
                            <div class="widget widget-who-following">
                                <div class="widget-heading mb-2">
                                    <h3 class="widget-title">Suggestion</h3>
                                    <span><Link onClick={onChildClick}>See All</Link></span>
                                </div>
                                {suggesstionUsers?.slice(0, 5).map((data, index) =>
                                    <div key={index} class="following-item d-flex justify-content-between align-items-center">
                                        <Link onClick={onChildClick}><img src={s3Url+data?.key} class="rounded-circle" alt="user" /></Link>
                                        <h3 class="name ms-1"><Link onClick={onChildClick}>{data?.userName}</Link></h3>
                                        <span class="add-friend"><Link onClick={onChildClick}><i class="material-icons-outlined">add</i>Follow</Link></span>
                                    </div>)}
                            </div>
                        </aside></div>
                </div>

            </div>
    </> 
); 
} 
export default SideBarRightLogin;