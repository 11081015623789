import React from 'react'
import './ReturnPolicy.css'


export default function ReturnPolicy() {
    return (
        <div className='ReturnPolicy_container'>
            <h5 className='text-white'>Return, Cancellation, and Refund Policy for D2C E-commerce:</h5>
            <div className='mt-4'>
                At Tripalive.Me Technology Private Limited (Pixalive Platforms), we strive to provide our customers with a seamless and satisfactory shopping experience. We understand that sometimes items may not meet your expectations or circumstances may change. To ensure your confidence in shopping with us, we have outlined our Return, Cancellation, and Refund Policy below:
            </div>
            <div className='mt-4'>
                <h5 className='text-white'>Returns:</h5>
                <ol className='lh-lg'>
                    <li>We accept returns within [2 days] of the purchase date. </li>
                    <li>To be eligible for a return, the item must be unused, in its original packaging, and in the same condition as received.</li>
                    <li>Items that are not eligible for return include [list any exceptions, such as perishable goods, personalized items, etc.].</li>
                    <li>To initiate a return, please contact our customer service team at [contact@pixalive.me, 8778584566] to obtain a return authorization.</li>
                    <li>Customers are responsible for return shipping costs unless the return is due to an error on our part.</li>
                    <li>Once we receive your returned item, we will inspect it and notify you of the status of your refund.</li>
                </ol>
            </div>
            <div className='mt-4'>
                <h5 className='text-white'>Cancellations:</h5>
                <ol className='lh-lg'>
                    <li>You may cancel your order within [8 hours] of placing it, as long as it has not yet been shipped.</li>
                    <li>To cancel an order, please contact our customer service team at [contact@pixalive.me, 8778584566] with your order details.</li>
                    <li>If your order has already been processed and shipped, you will need to initiate a return upon receipt of the item(s).</li>
                </ol>
            </div>
            <div className='mt-4'>
                <h5 className='text-white'>Refunds:</h5>
                <ol className='lh-lg'>
                    <li>Refunds will be issued to the original payment method used for the purchase.</li>
                    <li>Once your return is received and inspected, we will notify you of the approval or rejection of your refund.</li>
                    <li>If approved, your refund will be processed promptly, and a credit will automatically be applied to your original method of payment.</li>
                    <li>Please allow [2 weeks] for the refund to appear in your account, as processing times may vary depending on your financial institution.</li>
                </ol>
            </div>
            <div className='mt-4'>
                <h5 className='text-white'>Exchanges:</h5>
                <ol className='lh-lg'>
                    <li>We do not offer exchanges at this time. If you would like to exchange an item, please return the original item and place a new order for the desired item.</li>
                </ol>
            </div>
            <div className='mt-4'>
                <h5 className='text-white'>Damaged or Defective Items:</h5>
                <ol className='lh-lg'>
                    <li>If you receive a damaged or defective item, please contact us immediately upon receipt.</li>
                    <li>We may require photographic evidence of the damage or defect to expedite the resolution process.</li>
                    <li>Depending on the circumstances, we will offer a replacement, exchange, or refund for the damaged or defective item.</li>
                </ol>
            </div>
            <div className='mt-4'>
                <h5 className='text-white'>Exceptions:</h5>
                <ol className='lh-lg'>
                    <li>[List any specific exceptions or special cases that apply to your policy, such as clearance items, final sale items, etc.].</li>
                </ol>
            </div>
            <div className='mt-4'>
                <h5 className='text-white'>Contact Us:</h5>
                <p className='text-white'>If you have any questions or concerns about our Return, Cancellation, and Refund Policy, please don't hesitate to contact our customer service team at [contact@pixalive.me, 8778584566].</p>
                <h5 className='mt-4 text-white'>Note: </h5>
                <p className='text-white'>This policy is subject to change without prior notice. Please refer to the most recent version available on our website for updates.</p>
            </div>
        </div>
    )
}
