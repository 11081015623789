import { useEffect, useState } from 'react';
import Header from '../../components/Header';
import SideBar from '../../components/SideBar';
import { deletePlay, getFilterPlay, getSinglePlay, savePlayComments, savePlayLikes, updateBlockPlay, updatePlayReport } from '../../api/play';
import { Link } from 'react-router-dom';
import SideBarRight from '../../components/SideBarRight';
import { timeCal } from '../../utils/dateformat';
import { getUserId } from '../../utils/Storage';
import { getFilterBidding, saveBidding } from '../../api/bidding';
import { toast } from 'react-toastify';
import { Chip, Dialog, DialogContent, DialogTitle, IconButton, Tooltip } from '@mui/material';
import { acceptFollower, getAllFollowings, saveFollower } from '../../api/follow';
import { getSingleUser } from '../../api/user';
import { EmailIcon, EmailShareButton, FacebookIcon, FacebookShareButton, LinkedinIcon, LinkedinShareButton, TelegramIcon, TelegramShareButton, TwitterIcon, TwitterShareButton, WhatsappIcon, WhatsappShareButton } from 'react-share';
import { s3Url } from '../../utils/FileUpload';

function Plays() {
    const [play, setPlay] = useState([]);
    const [next, setNext] = useState(0);
    const [count, setCount] = useState(0);
    const [scroll, setScroll] = useState(false);
    const [reload, setReload] = useState(false);
    const [openBid, setOpenBid] = useState(false);
    const [bidAmount, setBidAmount] = useState('');
    const [submit, setSubmit] = useState(false);
    const [playId, setPlayId] = useState('');
    const [user, setUser] = useState()
    const [bidding, setBidding] = useState([])
    const [allFollowing, setAllFollowing] = useState([])
    const [playLikes, setPlayLikes] = useState('')
    const [coins, setCoins] = useState('')
    const [share, setShare] = useState(false);
    const [shareUrl, setShareUrl] = useState('');
    const [content, setContent] = useState('');
    const [openComments, setOpenComments] = useState(false);
    const [userDetails, setUserDetails] = useState('');
    const [input, setInput] = useState('');
    const [openReport, setOpenReport] = useState(false)
    const [report, setReport] = useState({});
    const [openDescription, setOpenDescription] = useState(false);
    const [submitReport, setSubmitReport] = useState(false);
    const [reportId, setReportId] = useState('');
    const [reportResponse,setReportResponse]=useState(false)
    const [hide, setHide] = useState(false);
    const [hideId, setHideId] = useState('')
    const [deleteId, setDeleteId] = useState('');
    const [openDelete, setOpenDelete] = useState(false);
    const [playDelete, setPlayDelete] = useState(false);


    const data = {
        limit: 5,
        page: next,
    };
    useEffect(() => {
        getPlayList();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [next]);

    useEffect(() => {
        const user = getUserId()
        setUser(user)
        getAllFollowing()
        getUserDetails()
        getFilterBiddingList();
        window.addEventListener('scroll', handleScroll);
    }, [])

    const handleScroll = () => {
        const value = window.innerHeight + window.scrollY >=
            document.documentElement.scrollHeight - 200
        setScroll(value)
    };
    useEffect(() => {
        if (scroll) {
            loadMore();
        }
    }, [scroll])

    const getUserDetails = () => {
        const data = getUserId()
        getSingleUser(data)
            .then((res) => {
                setCoins(res?.data?.result?.coins);
                setUserDetails(res?.data?.result);
            })
            .catch((err) => {
                console.log(err);
            });
    }

    const getPlayList = () => {
        getFilterPlay(data)
            .then((res) => {
                const playList = res?.data?.result?.playList;
                setCount(res?.data?.result?.playCount)
                setPlay([...play, ...playList]);
            })
            .catch((err) => {
                console.log(err);
            });
    };

    const loadMore = () => {
        let nextPlay = next;
        if (playDelete) {
            nextPlay = nextPlay + 4;
            setPlayDelete(false)
        }
        else {
            nextPlay = nextPlay + 5;
        }
        if (count <= nextPlay) {
            setReload(true)
        }
        else {
            setNext(nextPlay);
        }
    };

    const singlePlay = (playId) => {
        getSinglePlay(playId).then(res => {
            const result = res?.data?.result
            const data = play.map(item =>
                item._id === result?._id ? { ...item, likeCount: result?.likeCount, bidingCount: result?.bidingCount, likedUser: result?.likedUser, commentCount: result?.commentCount, comment: result?.comment } : item
            );
            setPlay(data)
        }).catch(err => {
            console.log(err);
        })
    }

    const openBidPopup = (id) => {
        setOpenBid(true);
        setSubmit(false);
        setBidAmount(' ')
        setPlayId(id)
    };
    const closeBidPopup = () => {
        setOpenBid(false);
    };

    const handleBidAmount = (event) => {
        setBidAmount(event?.target?.value)
    }

    const handleBidAmountSubmit = (event) => {
        event.preventDefault()
        setSubmit(true)
        if (bidAmount) {
            if (coins >= bidAmount) {
                const data = {
                    user: user,
                    play: playId,
                    type: "play",
                    bidCoins: bidAmount
                }
                saveBidding(data).then(res => {
                    toast.success('Successfully submitting a bid');
                    closeBidPopup()
                    getFilterBiddingList();
                    singlePlay(playId)
                }).catch(err => {
                    console.log(err);
                })
            }
            else {
                toast.warning('Your coin is less than the amount you have bid.')
            }
        }
    }

    const getFilterBiddingList = () => {
        const data = { type: 'play' }
        getFilterBidding(data).then(res => {
            setBidding(res?.data?.result)
        }).catch(err => {
            console.log(err);
        })
    }

    const resetBidAmount = () => {
        setBidAmount("")
    }
    const getAllFollowing = () => {
        const data = { id: getUserId() };
        getAllFollowings(data)
            .then((res) => {
                setAllFollowing(res?.data?.result);
            })
            .catch((err) => {
                console.log(err);
            });
    };

    const handleFollowers = (id, connect) => {
        const data = {
            follower: getUserId(),
            following: id,
            isConnect: connect === false ? true : false,
        };
        saveFollower(data)
            .then((res) => {
                getAllFollowing()
            })
            .catch((err) => {
                console.log(err);
            });

    }

    const handleRequest = (id) => {
        const data = { _id: id, isConnect: false };
        acceptFollower(data)
            .then((res) => {
                getAllFollowing()
            })
            .catch((err) => {
                console.log(err);
            });
    };

    const saveLikes = (playId, like) => {
        const user = getUserId()
        if (like === 'add') {
            setPlayLikes(playId)
        }
        else { setPlayLikes('') }
        const data = {
            _id: playId,
            user: user,
        }
        savePlayLikes(data).then(res => {
            singlePlay(playId)
        }).catch(err => {
            console.log(err);
        })
    }

    const openSharePopup = (url, content) => {
        setShareUrl(url);
        setContent(content)
        setShare(true)
    };
    const closeSharePopup = () => {
        setShare(false);
    };

    const getPlayCommentsList = (commentId) => {
        if (openComments === commentId) {
            setOpenComments('')
        }
        else {
            setOpenComments(commentId)
        }
    }

    const handleInputs = (event) => {
        setInput({ ...input, message: event.target.value })
    }

    const handleComments = (id) => {
        if (input?.message) {
            const data = {
                _id: id,
                comment: {
                    comments: input?.message,
                    user: userDetails?._id
                }
            }
            savePlayComments(data).then(res => {
                setInput({ ...input, message: '' })
                singlePlay(id)
            }).catch(err => { console.log(err) })
        }
        else {
            toast.warning('Please enter the comment')
        }
    }

    const openReportPopup = (data) => {
        setReportId(data)
        setOpenReport(true)
    }
    const closeReportPopup = () => {
        setOpenReport(false)
    }

    const openDescriptionPopup = (data) => {
        setReport({ ...report, type: data })
        setSubmitReport(false)
        closeReportPopup()
        setOpenDescription(true)
    }
    const closeDescriptionPopup = () => {
        setOpenDescription(false)
    }

    const openReportResponsePopup = (data) => {
       setReportResponse(true)
    }
    const closeReportResponsePopup = () => {
        setReportResponse(false)
    }

    const handleDescription = (event) => {
        const description = event?.target.value
        setReport({...report, description})
    }

    const handleReport = () => {
        setSubmitReport(true)
        if (report?.description) {
            const data = {
                _id: reportId,
                report: {
                    user: user,
                    type: report?.type,
                    description: report?.description
                }
            }
            updatePlayReport(data).then(res => {
                closeDescriptionPopup();
                openReportResponsePopup()
            }).catch(err => { console.log(err); })
        }

    }

    const handleHidePost = () => {
        const data = { _id: hideId, blockedUsers: user }
        updateBlockPlay(data).then(res => {
            closeHidePopup()
            toast.success(res?.data?.message)
            const newPlayList = play.filter(x => x._id !== hideId)
            setPlay(newPlayList)
        }).catch(err => {
            console.log(err);
        })
    }

    const openHidePopup = (hideId) => {
        setHideId(hideId)
        setHide(true);
    };
    const closeHidePopup = () => {
        setHide(false);
    };

    const openDeletePopup = (data) => {
        setDeleteId(data)
        setOpenDelete(true)
    }
    const closeDeletePopup = () => {
        setOpenDelete(false)
    }

    const deletePlayDetails = () => {
        deletePlay(deleteId).then(res => {
            closeDeletePopup()
            toast.success(res?.data?.message);
            const newPlayList = play.filter(x => x._id !== deleteId)
            setPlay(newPlayList)
            setPlayDelete(true)
        }).catch(err => { console.log(err); })
    }



    return (
        <>
            <Header />
            <div className="content-page-box-area">
                <div className="row">
                    <SideBar menu={'Plays'} />
                    <div className="col-lg-6 col-md-12">
                        {play?.map((data, index) => (
                            <div key={index} className="post-panel">
                                <div className="post-wrapper">
                                    <div className="profile">
                                        {data?.isBided ?
                                            <div className="media">
                                                <Link
                                                    className="popover-cls user-img bg-size blur-up lazyloaded"
                                                    data-bs-toggle="popover"
                                                    data-placement="right"
                                                    data-name="sufiya eliza"
                                                    title=""
                                                    to={{ pathname: '/Profile', search: `id=${data?.bidedUser?._id}` }}
                                                >
                                                    <img
                                                        src={
                                                            s3Url+data?.bidedUser?.key
                                                        }
                                                        className="img-fluid blur-up lazyload bg-img"
                                                        alt="user"
                                                    />
                                                </Link>
                                            </div>
                                            : null}
                                        <div className="media">
                                            <Link
                                                className="popover-cls user-img bg-size blur-up lazyloaded"
                                                data-bs-toggle="popover"
                                                data-placement="right"
                                                data-name="sufiya eliza"
                                                title=""
                                                to={{ pathname: '/Profile', search: `id=${data?.user?._id}` }}
                                            >
                                                <img
                                                    src={
                                                        s3Url+data?.user?.key
                                                    }
                                                    className="img-fluid blur-up lazyload bg-img"
                                                    alt="user"
                                                />
                                            </Link>
                                        </div>
                                    </div>

                                    <div className="post-conten-wrapper">
                                        <div className="post-title">
                                            {data?.isBided ?
                                                <div className="profile mt-2 mb-4">
                                                    <div className="media-body media-body-user">
                                                        <div>
                                                            <h5><Link to={{ pathname: '/Profile', search: `id=${data?.bidedUser?._id}` }}>{data?.bidedUser?.userName}  <span className='small-text'>{" "}Owned this content</span></Link></h5>

                                                        </div>
                                                    </div>
                                                </div> : null}
                                            <div className="profile">
                                                <div className="media-body media-body-user">
                                                    <div>
                                                        <h5><Link to={{ pathname: '/Profile', search: `id=${data?.user?._id}` }}>{data?.user?.userName}</Link></h5>
                                                        <h6>{timeCal(data?.createdOn)}</h6>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="post-details">
                                            <div className="img-wrapper post-video">
                                                <video src={s3Url+data?.key} type="video/mp4" width="auto" height="100%" controls>
                                                </video>
                                            </div>
                                            <div className="sidebar-items">
                                                <div className="sidebar">
                                                    <div className="feed">
                                                        <Link>
                                                            {playLikes === data?._id || data?.likedUser?.includes(user) ?
                                                                <i className="material-icons-round text-danger" onClick={() => saveLikes(data?._id, 'remove')} >favorite</i> :
                                                                <i className="feather-heart" onClick={() => saveLikes(data?._id, 'add')}></i>}
                                                            <div>
                                                                <p>{data?.likeCount}</p>
                                                            </div>
                                                        </Link>
                                                    </div>
                                                </div>
                                                <div className="sidebar">
                                                    <div className="feed">
                                                        <Link onClick={() => getPlayCommentsList(data?._id)}>
                                                            <i className="feather-message-circle"></i>
                                                            <div>
                                                                <p>{data?.commentCount}</p>
                                                            </div>
                                                        </Link>
                                                    </div>
                                                </div>
                                                <div className="sidebar">
                                                    <div className="feed">
                                                        <Link onClick={() => openSharePopup(s3Url+data?.key, data?.description)}>
                                                            <img
                                                                src="../assets/images/share.png"
                                                                alt="share"
                                                            />
                                                            {/* <div>
                                                                <p>{data?.shareCount}</p>
                                                            </div> */}
                                                        </Link>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="post-hashtag-list">
                                            <span>{data?.description}</span>
                                            {openComments === data?._id ?
                                            <div className="new-comments post-modal-popup">
                                                <div className="comments-area" data-simplebar style={data?.comment?.length > 0 ? { height: '150px' } : { height: '0px' }}>
                                                    <div className="post-comment-list">
                                                        {data?.comment?.map((value, index) =>
                                                            <div className="comment-list" key={index}>
                                                                <div className="comment-image">
                                                                    <Link to={{ pathname: '/Profile', search: `id=${value?.user?._id}` }}><img src={s3Url+value?.user?.key} className="rounded-circle" alt="user" /></Link>
                                                                </div>
                                                                <div className="comment-info">
                                                                    <h3>
                                                                        <Link to={{ pathname: '/Profile', search: `id=${value?.user?._id}` }}>{value?.user?.userName}</Link>
                                                                    </h3>
                                                                    <span>{timeCal(value?.createdOn)}</span>
                                                                    <p>{value?.comments}</p>
                                                                    {/* <ul className="comment-react">
                                                        <li><a href="#" className="like">1,351 likes</a></li>
                                                        <li><a href="#">Reply</a></li>
                                                    </ul> */}
                                                                </div>
                                                            </div>)}
                                                        {/* <div className="more-comments">
                                                <a href="#">More Comments+</a>
                                            </div> */}
                                                    </div>
                                                </div>

                                                <form className="post-footer">
                                                    <div className="footer-image">
                                                        <Link to={{ pathname: '/Profile', search: `id=${userDetails?._id}` }}><img src={s3Url+userDetails?.key} className="rounded-circle" alt="user" /></Link>
                                                    </div>
                                                    <div className="form-group">
                                                        <textarea name="message" value={input?.message}  onChange={handleInputs} className="form-control" placeholder="Write a comment..."></textarea>
                                                        <label><Link onClick={()=>handleComments(data?._id)} ><i className="feather-send"></i></Link></label>
                                                    </div>
                                                </form>
                                            </div>
                                            : null}
                                        </div>
                                       
                                    </div>
                                    <div className="profile">
                                        <div className="media-body media-body-user">
                                            {data?.isBided ?
                                                (data?.bidedUser?._id !== user ?
                                                    (allFollowing.some(x => x?.following?._id === data?.bidedUser?._id && !x.isConnect) ?
                                                        (
                                                            allFollowing.map(x => {
                                                                if (x?.following?._id === data?.bidedUser?._id && !x.isConnect) {
                                                                    return (
                                                                        <button className="btn-feed-request ms-auto" onClick={() => handleRequest(x?._id)} type="button" >Requested</button>

                                                                    );
                                                                }
                                                                return null
                                                            })) :
                                                        allFollowing.some(x => x?.following?._id === data?.bidedUser?._id && x.isConnect) ?
                                                            <button className="btn-feed-following ms-auto" onClick={() =>
                                                                handleFollowers(
                                                                    data?.bidedUser?._id,
                                                                    data?.bidedUser?.isPrivate
                                                                )
                                                            } type="button" >Following</button> :
                                                            <button className="btn-feed-follow ms-auto" onClick={() =>
                                                                handleFollowers(
                                                                    data?.bidedUser?._id,
                                                                    data?.bidedUser?.isPrivate
                                                                )
                                                            } type="button">Follow</button>) : null)
                                                : (data?.user?._id !== user ? (allFollowing.some(x => x?.following?._id === data?.user?._id && !x.isConnect) ?
                                                    (allFollowing.map(x => {
                                                        if (x?.following?._id === data?.user?._id && !x.isConnect) {
                                                            return (
                                                                <button className="btn-feed-request ms-auto " onClick={() => handleRequest(x?._id)} type="button" >Requested</button>

                                                            );
                                                        }
                                                        return null
                                                    })) :
                                                    allFollowing.some(x => x?.following?._id === data?.user?._id && x.isConnect) ?
                                                        <button className="btn-feed-following ms-auto" onClick={() =>
                                                            handleFollowers(
                                                                data?.user?._id,
                                                                data?.user?.isPrivate
                                                            )
                                                        } type="button" >Following</button> :
                                                        <button className="btn-feed-follow ms-auto" onClick={() =>
                                                            handleFollowers(
                                                                data?.user?._id,
                                                                data?.user?.isPrivate
                                                            )
                                                        } type="button">Follow</button>) : null)}

                                            {data?.isBid && !data?.isBided ?
                                                (data?.user?._id === user || bidding?.some(x => x?.user?._id === user && x?.play === data?._id) ?
                                                    <Tooltip title="Bid Count" className={data?.user?._id===user?'ms-auto':null}> <Chip  icon={<i className="material-icons-outlined" style={{ color: "gold", fontSize: "20px" }}>paid</i>} label={data?.bidingCount} size='small' color='success' /> </Tooltip>
                                                    : <div className="buy-button">
                                                        <button type="button" onClick={() => openBidPopup(data?._id)}><i className="material-icons-outlined">paid</i>Buy</button>
                                                    </div>)
                                                : null}
                                                <div className="dropdown ms-2">
                                                        <button
                                                            className="dropdown-toggle"
                                                            type="button"
                                                            data-bs-toggle="dropdown"
                                                            aria-haspopup="true"
                                                            aria-expanded="false"
                                                        >
                                                            <i className="feather-more-horizontal"></i>
                                                        </button>
                                                        <ul className="dropdown-menu">
                                                        {data?.user?._id !== user || data?.isBided ? (data?.bidedUser?._id !== user ?
                                                    <>
                                                    <li><Link className="dropdown-item d-flex align-items-center" onClick={() => openHidePopup(data?._id)}><i className="material-icons-outlined">hide_source</i> Hide</Link></li>
                                                        <li><Link className="dropdown-item d-flex align-items-center" onClick={() => openReportPopup(data?._id)} ><i className="material-icons-outlined">report</i> Report</Link></li></>
                                                    : <><li><Link className="dropdown-item d-flex align-items-center" to={{ pathname: '/EditPost', search: `playId=${data?._id}` }} ><i className="material-icons-outlined">edit</i> Edit</Link></li>
                                                    <li><Link className="dropdown-item d-flex align-items-center" onClick={() => openDeletePopup(data?._id)} ><i className="material-icons-outlined">delete</i> Delete</Link></li></>) :
                                                     <><li><Link className="dropdown-item d-flex align-items-center" to={{ pathname: '/EditPost', search: `playId=${data?._id}` }} ><i className="material-icons-outlined">edit</i> Edit</Link></li>
                                                     <li><Link className="dropdown-item d-flex align-items-center" onClick={() => openDeletePopup(data?._id)} ><i className="material-icons-outlined">delete</i> Delete</Link></li></>}
                                                        </ul>
                                                    </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        ))}
                        {reload ?
                            <div className='form-text text-danger text-center'>No Plays</div> : null}
                    </div>
                    <SideBarRight />
                </div>
            </div>
            <Dialog open={openBid} fullWidth maxWidth="xs">
                <DialogTitle>
                    Play Bidding					<IconButton className="float-end" onClick={closeBidPopup} >
                        <i className="material-icons-round">close</i>
                    </IconButton>
                </DialogTitle>
                <DialogContent>
                    <form onSubmit={handleBidAmountSubmit}>
                        <div className="from-group mb-3">
                            <label htmlFor="categoryName" className="form-label">
                                Bid Amount
                            </label>
                            <div className="input-group">
                                <div className="input-group-prepend">
                                    <span className="input-group-text">₹</span>
                                </div>
                                <input type="text" value={bidAmount} onChange={handleBidAmount} name='biddingAmount' className="form-control" placeholder="Bid Amount" />
                            </div>
                            {!bidAmount && submit ? (
                                <span className="form-text text-danger">
                                    This field is required.
                                </span>
                            ) : null}
                        </div>
                        <div className='d-flex justify-content-end'>
                            <button type='submit' className="btn-save me-3"> Submit </button>
                            <button type='button' className="btn-cancel" onClick={resetBidAmount}> Reset </button>
                        </div>
                    </form>
                </DialogContent>
            </Dialog>
            <Dialog open={share} fullWidth maxWidth="xs">
                <DialogTitle className='text-secondary'>
                    Share On Social Media<IconButton className="float-end" onClick={closeSharePopup} >
                        <i className="material-icons-round">close</i>
                    </IconButton>
                </DialogTitle>
                <DialogContent>
                    <div className="p-4 d-flex justify-content-between">
                        <WhatsappShareButton url={shareUrl} title={content} >
                            <WhatsappIcon size={32} round={true} />
                        </WhatsappShareButton>
                        <FacebookShareButton url={shareUrl} title={content} >
                            <FacebookIcon size={32} round={true} />
                        </FacebookShareButton>
                        <TwitterShareButton url={shareUrl} title={content}>
                            <TwitterIcon size={32} round={true} />
                        </TwitterShareButton>
                        <TelegramShareButton url={shareUrl} title={content}>
                            <TelegramIcon size={32} round={true} />
                        </TelegramShareButton>
                        <LinkedinShareButton url={shareUrl} title={content}>
                            <LinkedinIcon size={32} round={true} />
                        </LinkedinShareButton>
                        <EmailShareButton url={shareUrl} title={content}>
                            <EmailIcon size={32} round={true} />
                        </EmailShareButton>
                    </div>
                </DialogContent>
            </Dialog>

            <Dialog open={openReport} fullWidth maxWidth="xs" >
                <DialogTitle>Report
                    <IconButton className="float-end" onClick={closeReportPopup} >
                        <i className="material-icons-round" >close</i>
                    </IconButton>
                </DialogTitle>
                <DialogContent>
                    <div className='report'>
                        <h6>Report this Play</h6>
                        <span>Help us understand what's happening with this play. How would you describe it?</span>
                        <ul className='mt-2'>
                            <li> <span onClick={() => openDescriptionPopup('Misleading or scam')}>Misleading or scam </span> </li>
                            <li> <span onClick={() => openDescriptionPopup('Sexually inappropriate')}> Sexually inappropriate </span></li>
                            <li><span onClick={() => openDescriptionPopup('Offensive')}>Offensive</span></li>
                            <li><span onClick={() => openDescriptionPopup('Violence')}>Violence</span></li>
                            <li><span onClick={() => openDescriptionPopup('Prohibited content')}>Prohibited content</span> </li>
                            <li><span onClick={() => openDescriptionPopup('Spam')}>Spam </span> </li>
                            <li><span onClick={() => openDescriptionPopup('False news')}>False news </span></li>
                            <li><span onClick={() => openDescriptionPopup('Political candidate or issue')}>Political candidate or issue </span> </li>
                            <li><span onClick={() => openDescriptionPopup('Other')}>Other</span></li>
                        </ul>
                    </div>
                </DialogContent>
            </Dialog>

            <Dialog open={openDescription} fullWidth maxWidth="sm" >
                <DialogTitle>Report
                    <IconButton className="float-end" onClick={closeDescriptionPopup} >
                        <i className="material-icons-round" >close</i>
                    </IconButton>
                </DialogTitle>
                <DialogContent>
                    <div className="row">
                        <div className="col-lg-12 col-md-12 col-sm-12">
                            <div className="form-group">
                                <label className='mb-2'>Description</label>
                                <textarea className="form-control" rows={4} onChange={handleDescription} name='description' placeholder="Description"></textarea>
                                {!report?.description && submitReport ? (
                                    <span className="form-text text-danger">
                                        This field is required.
                                    </span>
                                ) : null}
                            </div>
                        </div>
                    </div>
                    <div className="float-end mt-3">
                        <button type="button" className="btn btn-save mx-3" onClick={handleReport} >Submit</button>
                        <button type="button" className="btn btn-cancel " onClick={closeDescriptionPopup}>Cancel</button>
                    </div>
                </DialogContent>
            </Dialog>

            <Dialog open={reportResponse} fullWidth maxWidth="xs" >
                <DialogTitle className='p-0'>
                    <IconButton className="float-end m-2" onClick={closeReportResponsePopup} >
                        <i className="material-icons-round" >close</i>
                    </IconButton>
                </DialogTitle>
                <DialogContent className='responseModal'>
                <span><i className="material-icons-round" >check_circle</i></span>
                <h5>Thank you for submitting a report.</h5>
                <div className='my-2'><Chip color="primary" variant="outlined"  label={report?.type}></Chip></div>
                <span>We'll let you know when there's an update on this report.</span>
                </DialogContent>
            </Dialog>
            <Dialog open={hide}>
                <DialogContent>
                    <div className="text-center m-4">
                        <h5 className="mb-4">Are you sure you want to hide <br /> the selected Play ?</h5>
                        <button type="button" className="btn btn-save mx-3" onClick={handleHidePost}>Yes</button>
                        <button type="button" className="btn btn-cancel " onClick={closeHidePopup}>No</button>
                    </div>
                </DialogContent>
            </Dialog>

            <Dialog open={openDelete}>
                <DialogContent>
                    <div className="text-center m-4">
                        <h5 className="mb-4">Are you sure you want to Delete <br /> the selected Play ?</h5>
                        <button type="button" className="btn btn-save mx-3" onClick={deletePlayDetails}>Yes</button>
                        <button type="button" className="btn btn-cancel " onClick={closeDeletePopup}>No</button>
                    </div>
                </DialogContent>
            </Dialog>
        </>
    );
}
export default Plays;