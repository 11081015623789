
import { Link, useLocation, useNavigate } from 'react-router-dom';
import { useEffect, useState } from 'react';
import {  Checkbox, Dialog, DialogContent, DialogTitle, IconButton } from '@mui/material';
import {  saveToken } from '../../utils/Storage';
import { toast } from 'react-toastify';
import { timeCal } from '../../utils/dateformat';
import HeaderLogin from '../../components/HeaderLogin';
import SideBarLogin from '../../components/SideBarLogin';
import { isValidPhone } from '../../utils/validation';
import { loginUser, resendOTP, sinupUser, verifyOTP } from '../../api/login';
import { isAuthenticated } from '../../utils/Auth';
import SideBarRightLogin from '../../components/SideBarRightLogin';
import { getSinglePlayForShare } from '../../api/play';
import { s3Url } from '../../utils/FileUpload';


function SinglePlay() {
    const [play, setPlay] = useState('');
    const [openComments, setOpenComments] = useState(false);
const location = useLocation()
const playId = new URLSearchParams(location.search).get('id')
const initialState = {
    _id: "",
    phone: "",
    otp: "",
    userName: "",
}
const initialStateErrors = {
    phone: { required: false, valid: false },
    otp: { required: false },
    userName: { required: false }
}
const [open, setOpen] = useState(false);
const [inputs, setInputs] = useState(initialState)
const [errors, setErrors] = useState(initialStateErrors)
const [submitted, setSubmitted] = useState(false);
const [openOTP, setOpenOTP] = useState(false);
const [openSinup, setOpenSinup] = useState(false);
const [back, setBack] = useState(false);
const navigate = useNavigate()

    useEffect(() => {
        singlePlay()
    }, [])

    const getPostCommentsList = (commentId) => {
        if (openComments === commentId) {
            setOpenComments('')
        }
        else {
            setOpenComments(commentId)
        }
    }

    const singlePlay = () => {
        getSinglePlayForShare(playId).then(res => {
            const result = res?.data?.result
            setPlay(result)
            updateMetaTags(result)
        }).catch(err => {
            console.log(err);
        })
    }


    const openPopup = (event) => {
        event?.preventDefault()
        setOpen(true);
        setBack(false);
        setSubmitted(false);
        setErrors(initialStateErrors);
    };
    const closePopup = () => {
        setOpen(false);
    };

    const openSinupPopup = () => {
        setOpenSinup(true);
        setSubmitted(false);
        setBack(true);
        closePopup();
        setErrors(initialStateErrors);
    };
    const closeSinupPopup = () => {
        setOpenSinup(false);
    };


    const openOTPPopup = () => {
        setOpenOTP(true);
        setSubmitted(false);
        const otp = { required: false }
        setErrors({ ...errors, otp });
    };
    const closeOTPPopup = () => {
        setOpenOTP(false);
    };

    const handleValidation = (data) => {
        let error = initialStateErrors;
        if (data.phone === "") {
            error.phone.required = true;
        }
        if (data.otp === "") {
            error.otp.required = true;
        }
        if (data.userName === "") {
            error.userName.required = true;
        }
        if (!isValidPhone(data.phone)) {
            error.phone.valid = true;
        }
        return error
    }

    const handleInput = (event) => {
        setInputs({ ...inputs, [event.target.name]: event.target.value });
        if (submitted) {
            const newError = handleValidation({ ...inputs, [event.target.name]: event.target.value })
            setErrors(newError)
        }
    }

    const handleLogin = (event) => {
        event.preventDefault();
        const newError = handleValidation(inputs)
        setErrors(newError)
        setSubmitted(true)
        const allInputsValid = !newError?.phone?.required && !newError?.phone?.valid
        if (allInputsValid) {
            const data = { phone: inputs?.phone }
            loginUser(data)
                .then((res) => {
                    const _id = res?.data?.result?._id
                    setInputs({ ...inputs, _id })
                    closePopup()
                    openOTPPopup()
                    toast.success(res?.data?.message);
                })
                .catch((err) => {
                    toast.error(err?.response?.data?.message);
                });
        }
    };

    const handleRegistration = (event) => {
        event.preventDefault();
        const newError = handleValidation(inputs)
        setErrors(newError)
        setSubmitted(true)
        const allInputsValid = !newError?.phone?.required && !newError?.phone?.valid && !newError?.userName?.required
        if (allInputsValid) {
            const data = { phone: inputs?.phone, userName: inputs?.userName }
            sinupUser(data).then(res => {
                const _id = res?.data?.result?._id
                setInputs({ ...inputs, _id })
                closeSinupPopup()
                openOTPPopup()
                toast.success(res?.data?.message);
            })
                .catch((err) => {
                    toast.error(err?.response?.data?.message);
                });
        }
    }

    const handleSubmit = (event) => {
        event.preventDefault();
        const newError = handleValidation(inputs)
        setErrors(newError)
        setSubmitted(true)
        if (!newError?.otp?.required) {
            const data = { otp: inputs?.otp, _id: inputs?._id }
            verifyOTP(data)
                .then((res) => {
                    closeOTPPopup()
                    let token = res?.data?.result?.token;
                    let userId = res?.data?.result?._id;
                    let category = res?.data?.result?.category;
                    let data = {
                        token: token, _id: userId, category: category
                    }
                    saveToken(data);
                    if (isAuthenticated()) {
                        navigate("/Home");
                    }
                    toast.success(res?.data?.message);

                })
                .catch((err) => {
                    toast.error(err?.response?.data?.message);
                });
        }
    };

    const resendOtp = () => {
        const data = { _id: inputs?._id }
        resendOTP(data).then(res => {
            toast.success(res?.data?.message);
        })
            .catch((err) => {
                toast.error(err?.response?.data?.message);
            })
    }

    const handleBack = () => {
        closeOTPPopup();
        if (back) {
            openSinupPopup()
        }
        else {
            openPopup();
        }
    }


        const updateMetaTags = (data) => {
          const descriptionMeta = document.querySelector('meta[property="og:description"]');
          const urlMeta = document.querySelector('meta[property="og:url"]');
          const imageMeta = document.querySelector('meta[property="og:image"]');
          descriptionMeta.setAttribute('content', data?.textContent??"Our Social Network");
          urlMeta.setAttribute('content',`https://pixalive.me/singleplay?id=${playId}` );
          imageMeta.setAttribute('content', encodeURI(s3Url+data?.key)??'');
        };



    return (
        <>
         {/* <Helmet>
                <meta property="og:title" content='Pixalive- Our Nation; Our Social Network' />
                <meta property="og:description" content={post?.textContent ?? 'Our Social Network'} />
                <meta property="og:image" content={encodeURI(post?.url)} />
                <meta property="og:url" content={`https://pixalive.me/singlepost?id=${postId}`} />
            </Helmet> */}
            <HeaderLogin onChildClick={openPopup}/>
            <div className="content-page-box-area">
                <div className="row">
                    <SideBarLogin onChildClick={openPopup} />
                    <div className="col-lg-6 col-md-6">
                        <div className="news-feed-area">
                                <div className="news-feed news-feed-post">
                                    {play?.isBided ?
                                        <div className="post-header d-flex justify-content-between align-items-center">
                                            <div className="image">
                                                <Link onClick={openPopup}> <img src={s3Url+play?.bidedUser?.key} alt="user" /></Link>
                                            </div>
                                            <div className="info">
                                                <span className="name"><Link onClick={openPopup}>{play?.bidedUser?.userName}</Link></span>
                                                <span className="small-text-bid">{" "}Owned this content</span>
                                            </div>
                                        </div> : null}
                                    <div className="post-header d-flex justify-content-between align-items-center">
                                        <div className="image">
                                            <Link onClick={openPopup}> <img src={s3Url+play?.user?.key} alt="user" /></Link>
                                        </div>
                                        <div className="info">
                                            <span className="name"><Link onClick={openPopup}>{play?.user?.userName}</Link></span>
                                            <span className="small-text"><i className="material-icons-outlined">public</i>{play?.playType === 1 ? 'Public' : 'Private'}<span> {timeCal(play?.createdOn)}</span></span>
                                        </div>
                                    </div>

                                    <div className="post-body">
                                        <div className="post-image">
                                            {play?.fileType === 1 ?
                                                <img
                                                    src={s3Url+play?.key}
                                                    alt="Post"
                                                /> : <video src={s3Url+play?.key} width="100%" height="auto" controls>
                                                </video>}
                                        </div>
                                        <div className="post-content-hashtag"> <p>{play?.description}</p></div>

                                        <ul className="post-meta-wrap d-flex justify-content-between align-items-center">
                                            <li className="post-react">
                                            <Link>
                                                 <i className="feather-heart" onClick={openPopup}></i>
                                                    <span className="number">{play?.likeCount} </span></Link>
                                            </li>
                                            <li className="post-comment">
                                                <Link onClick={() => getPostCommentsList(play?._id)}><i className="feather-message-circle"></i><span className="number">{play?.commentCount}</span></Link>
                                            </li>
                                            <li className="post-share">
                                                <Link onClick={openPopup}><img src="../assets/images/share.png" alt="share" />
                                                    {/* <span className="number">{data?.shareCount} </span> */}
                                                </Link>
                                            </li>
                                        </ul>
                                        {/* <form className="post-footer">
                                            <div className="footer-image">
                                                <Link to="#"><img src="../assets/images/user/user-2.jpg" className="rounded-circle" alt="user" /></Link>
                                            </div>
                                            <div className="form-group">
                                                <textarea name="message" className="form-control" placeholder="Write a comment..."></textarea>
                                                <label><Link to="#"><i className="feather-send"></i></Link></label>
                                            </div>
                                        </form> */}
                                        {openComments === play?._id ?
                                            <div className="new-comments post-modal-popup">
                                                <div className="comments-area" data-simplebar style={play?.comment?.length > 0 ? { height: '150px' } : { height: '0px' }}>
                                                    <div className="post-comment-list">
                                                        {play?.comment?.map((value, index) =>
                                                            <div className="comment-list" key={index}>
                                                                <div className="comment-image">
                                                                    <Link onClick={openPopup}><img src={s3Url+value?.user?.key} className="rounded-circle" alt="user" /></Link>
                                                                </div>
                                                                <div className="comment-info">
                                                                    <h3>
                                                                        <Link onClick={openPopup}>{value?.user?.userName}</Link>
                                                                    </h3>
                                                                    <span>{timeCal(value?.createdOn)}</span>
                                                                    <p>{value?.comments}</p>
                                                                    {/* <ul className="comment-react">
                                                        <li><a href="#" className="like">1,351 likes</a></li>
                                                        <li><a href="#">Reply</a></li>
                                                    </ul> */}
                                                                </div>
                                                            </div>)}
                                                    </div>
                                                </div>

                                                <form className="post-footer">
                                                    <div className="footer-image">
                                                        <Link onClick={openPopup}><img src={`${s3Url}empty_profile.png`} className="rounded-circle" alt="user" /></Link>
                                                    </div>
                                                    <div className="form-group">
                                                        <textarea name="message"  onClick={openPopup} className="form-control" placeholder="Write a comment..."></textarea>
                                                        <label><Link onClick={openPopup} ><i className="feather-send"></i></Link></label>
                                                    </div>
                                                </form>
                                            </div>
                                            : null}
                                    </div>
                                </div>
                        </div>
                    </div>
                    <SideBarRightLogin onChildClick={openPopup} />
                </div>
            </div>


            <Dialog open={open} fullWidth maxWidth="sm">
                <DialogTitle>
                    Login
                    <IconButton className="float-end" onClick={closePopup} >
                        <i className="material-icons-round">close</i>
                    </IconButton>
                </DialogTitle>
                <DialogContent className="login-form">
                    <form onSubmit={handleLogin}>
                        <div className="form-group">
                            <label className="mb-2">Phone Number</label>
                            <input type="text" name="phone" onChange={handleInput} placeholder='Enter phone number' className="form-control" />
                            {errors.phone.required ? (
                                <span className="text-danger form-text">
                                    This field is required.
                                </span>
                            ) : errors.phone.valid ? (
                                <span className="text-danger form-text">
                                    Enter valid Phone Number.
                                </span>
                            ) : null}
                        </div>
                        <div className="mt-3 privacy">
                                <Checkbox defaultChecked />
                            <small className="ms-2">
                                I agree to the <Link target="_blank" to={{ pathname: '/TermsAndCondition', search: '?item=Terms'}} >Terms And Conditions</Link>, <Link target="_blank" to={{ pathname: '/TermsAndCondition', search: '?item=Privacy'}}>Privacy Policy</Link>, and <Link target="_blank" to={{ pathname: '/TermsAndCondition',search: '?item=Coin'}} > Coin Policy</Link></small>
                        </div>
                        <button type="submit" className="default-btn">Login</button>
                        <div className="signup-text-info">Not a member yet? <Link onClick={openSinupPopup}> Sign Up</Link>.</div>

                    </form>
                </DialogContent>
            </Dialog>
            <Dialog open={openSinup} fullWidth maxWidth="sm">
                <DialogTitle>
                    Register
                    <IconButton className="float-end" onClick={closeSinupPopup} >
                        <i className="material-icons-round">close</i>
                    </IconButton>
                </DialogTitle>
                <DialogContent className="login-form">
                    <form onSubmit={handleRegistration}>
                        <div className="form-group">
                            <label className="mb-2">User Name</label>
                            <input type="text" name="userName" onChange={handleInput} placeholder='Enter user name' className="form-control" />
                            {errors.userName.required ?
                                <span className="text-danger form-text">
                                    This field is required.
                                </span> : null}
                        </div>
                        <div className="form-group">
                            <label className="mb-2">Phone Number</label>
                            <input type="text" name="phone" onChange={handleInput} placeholder='Enter phone number' className="form-control" />
                            {errors.phone.required ? (
                                <span className="text-danger form-text">
                                    This field is required.
                                </span>
                            ) : errors.phone.valid ? (
                                <span className="text-danger form-text">
                                    Enter valid Phone Number.
                                </span>
                            ) : null}
                        </div>
                        <div className="mt-3 privacy">
                                <Checkbox defaultChecked />
                            <small className="ms-2">
                                I agree to the <Link target="_blank" to={{ pathname: '/TermsAndCondition', search: '?item=Terms'}} >Terms And Conditions</Link>, <Link target="_blank" to={{ pathname: '/TermsAndCondition', search: '?item=Privacy'}}>Privacy Policy</Link>, and <Link target="_blank" to={{ pathname: '/TermsAndCondition',search: '?item=Coin'}} > Coin Policy</Link></small>
                        </div>
                        <button type="submit" className="default-btn">Register</button>
                    </form>
                </DialogContent>
            </Dialog>
            <Dialog open={openOTP} fullWidth maxWidth="sm">
                <DialogTitle>
                    Verify Your Account
                    <IconButton className="float-end" onClick={closeOTPPopup} >
                        <i className="material-icons-round">close</i>
                    </IconButton>
                </DialogTitle>
                <DialogContent className="login-form">
                    <div className="text-center">
                        <p>An OTP has been sent to your registered phone number.</p>
                        <p>Please check your message.</p>
                    </div>
                    <form onSubmit={handleSubmit}>
                        <div className="form-group mt-3">
                            <label>OTP</label>
                            <input type="text" placeholder='Enter OTP' name="otp" onChange={handleInput} className="form-control" />
                            {errors.otp.required ?
                                <span className="text-danger form-text">
                                    This field is required.
                                </span> : null}
                        </div>
                        <div className="remember-me-wrap d-flex justify-content-between align-items-center">
                            <div className="lost-your-password-wrap text-center">
                                <Link onClick={handleBack} className="back-to-login">
                                    Back
                                </Link>
                            </div>
                            <div className="lost-your-password-wrap">
                                <Link data-toggle="modal" data-target="#Forgotpassword" onClick={resendOtp} className="lost-your-password">Resend OTP</Link>
                            </div>
                        </div>
                        <button type="submit" className="default-btn">Submit</button>
                    </form>
                </DialogContent>
            </Dialog>


        </>
    );
}
export default SinglePlay;