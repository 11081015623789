import React, { useEffect, useState } from 'react';
import { getAllBanner } from '../api/banner';
import { getFilterSuggestionUsersList } from '../api/user';
import { Link } from 'react-router-dom';
import Carousel from 'react-material-ui-carousel';
import { getUserId } from '../utils/Storage';
import { acceptFollower, getAllFollowings, saveFollower } from '../api/follow';
import { s3Url } from '../utils/FileUpload';
function SideBarRight() {
    const [banner, setBanner] = useState([]);
    const [suggesstionUsers, setSeggestionUsers] = useState([])
    const [allFollowing, setAllFollowing] = useState([])
    const [birthday, setBirthday] = useState([])

    useEffect(() => {
        getBannerList()
        getAllFollowing()
        getSuggestionUsersList()
        getTodayBirthday()
    }, [])

    const getBannerList = () => {
        getAllBanner()
            .then((res) => {
                setBanner(res?.data?.result);
            })
            .catch((err) => {
                console.log(err);
            });
    };

    const getSuggestionUsersList = () => {
        getFilterSuggestionUsersList().then(res => {
            setSeggestionUsers(res?.data?.result?.suggestionUserList);
        }).catch(err => {
            console.log(err);
        })
    }

    const getAllFollowing = () => {
        const data = { id: getUserId() };
        getAllFollowings(data)
            .then((res) => {
                setAllFollowing(res?.data?.result);
            })
            .catch((err) => {
                console.log(err);
            });
    };

    const getTodayBirthday = () => {
        const data = { id: getUserId() };
        getAllFollowings(data)
            .then((res) => {
                const result = res?.data?.result
                const todayBirthday = result?.filter(x => new Date(x?.following?.dob).setHours(0, 0, 0, 0) === new Date().setHours(0, 0, 0, 0))
                setBirthday(todayBirthday)
            })
            .catch((err) => {
                console.log(err);
            });
    }

    const handleFollowers = (id, connect) => {
        const data = {
            follower: getUserId(),
            following: id,
            isConnect: connect === false ? true : false,
        };
        saveFollower(data)
            .then((res) => {
                getAllFollowing()
            })
            .catch((err) => {
                console.log(err);
            });

    }

    const handleRequest = (id) => {
        const data = { _id: id, isConnect: false };
        acceptFollower(data)
            .then((res) => {
                getAllFollowing()
            })
            .catch((err) => {
                console.log(err);
            });
    };



    return (
        <>
            <div className="col-lg-3 col-md-3">
                <div className="sidemenu-area-1">
                    <div className="sidemenu-nav-1 ps-0 me-3 metisMenu h-100" id="sidemenu-nav" data-simplebar>
                        <aside className="widget-area mb-5" >
                            <div className="widget widget-birthday">
                                <div className="widget-heading">
                                    <h3 className="widget-title">Today Birthdays</h3>
                                </div>
                                {birthday?.length > 0 ?
                                    (birthday?.map((data, index) =>
                                        <article className="item" key={index}>
                                            <Link className="thumb" to={{ pathname: '/Profile', search: `id=${data?.following?._id}` }}>
                                                <img className='fullimage' src={s3Url+data?.following?.key} alt="user" />
                                            </Link>
                                            <div className="info">
                                                <h4 className="title">
                                                    <Link to={{ pathname: '/Profile', search: `id=${data?.following?._id}` }}>{data?.following?.userName}</Link>
                                                </h4>
                                            </div>
                                        </article>)) :
                                    <div className='form-text text-danger text-center'>There is no birthday today.</div>}

                            </div>
                            <div className="widget widget-watch-video">
                                <div className="widget-heading">
                                    <h3 className="widget-title">Advertisement</h3>
                                </div>
                                <Carousel animation='slide' indicatorIconButtonProps={{ style: { display: 'none', }, }}>
                                    {
                                        banner?.map((item, index) => <div key={index}>
                                            <Link target='_blank' to={item?.link}> <img src={s3Url+item?.key} alt={`banner ${index}`} /></Link>
                                        </div>)
                                    }
                                </Carousel>
                            </div>
                            <div className="widget widget-who-following">
                                <div className="widget-heading mb-2">
                                    <h3 className="widget-title">Suggestion</h3>
                                    <span><Link to="/SuggestionPeople" >See All</Link></span>
                                </div>
                                {suggesstionUsers?.slice(0, 5).map((data, index) =>
                                    <div key={index} className="following-item d-flex justify-content-between align-items-center">
                                        <Link to={{ pathname: '/Profile', search: `id=${data?._id}` }}><img src={s3Url+data?.key} className="rounded-circle" alt="user" /></Link>
                                        <h3 className="name ms-1"><Link to={{ pathname: '/Profile', search: `id=${data?._id}` }}>{data?.userName}</Link></h3>
                                        {allFollowing.some(x => x?.following?._id === data?._id && !x.isConnect) ?
                                            (
                                                allFollowing.map((x, i) => {
                                                    if (x?.following?._id === data?._id && !x.isConnect) {
                                                        return (
                                                            <span key={i} className="add-friend-request">
                                                                <Link onClick={() => handleRequest(x?._id)}>Requested</Link>
                                                            </span>
                                                        );
                                                    }
                                                    return null
                                                })) :
                                            allFollowing.some(x => x?.following?._id === data?._id && x.isConnect) ?
                                                <span className="add-friend-following">
                                                    <Link onClick={() =>
                                                        handleFollowers(
                                                            data?._id,
                                                            data?.isPrivate
                                                        )
                                                    }>Following</Link>
                                                </span> :
                                                <span className="add-friend">
                                                    <Link onClick={() =>
                                                        handleFollowers(
                                                            data?._id,
                                                            data?.isPrivate
                                                        )}>Follow</Link>
                                                </span>}
                                    </div>)}
                            </div>
                        </aside></div>
                </div>

            </div>
        </>
    );
}
export default SideBarRight;