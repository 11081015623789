export const Play = 'play';
export const User ='user';
export const Login ='login';
export const Category = 'category';
export const Post ='post';
export const Story ='story';
export const Notification = 'notification';
export const Likes = 'likes';
export const Slider = 'slider';
export const Bidding = 'bidding';
export const Follow ='follow'
