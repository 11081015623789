import Header from '../../components/HeaderLogin'; 
import SideBar from '../../components/SideBar'; 
import SideBarRight from '../../components/SideBarRight';
function Birthday() { 
return( 
<>
<Header />
    <div class="content-page-box-area">
        <div class="row">
            <SideBar/>
            <div class="col-lg-6 col-md-12">
            <div class="page-banner-box bg-9">
                    <h3>Birthday</h3>
                </div>
                
                <div class="all-birthday-body">
                    <div class="birthday-header">
                        <h3>Today Birthday</h3>
                    </div>

                    <div class="birthday-send-box">
                        <div class="send-item">
                            <a href="/Profile"><img src="assets/images/user/user-41.jpg" class="rounded-circle" alt="image" /></a>

                            <div class="send-content">
                                <h3>
                                    <a href="/Profile">James Vanwin</a>
                                </h3>
                                <span>10 August, 2023</span>

                                <form class="d-flex align-items-center">
                                    <input type="text" class="form-control" placeholder="Write someting"/>
                                    <button type="submit" class="send-btn d-inline-block">Send</button>
                                </form>
                            </div>
                        </div>
                        <div class="send-item">
                            <a href="/Profile"><img src="assets/images/user/user-42.jpg" class="rounded-circle" alt="image" /></a>

                            <div class="send-content">
                                <h3>
                                    <a href="/Profile">William Garcia</a>
                                </h3>
                                <span>10 August, 2023</span>

                                <form class="d-flex align-items-center">
                                    <input type="text" class="form-control" placeholder="Write someting"/>
                                    <button type="submit" class="send-btn d-inline-block">Send</button>
                                </form>
                            </div>
                        </div>
                        <div class="send-item">
                            <a href="/Profile"><img src="assets/images/user/user-43.jpg" class="rounded-circle" alt="image" /></a>

                            <div class="send-content">
                                <h3>
                                    <a href="/Profile">Annie Ferrier</a>
                                </h3>
                                <span>10 August, 2023</span>

                                <form class="d-flex align-items-center">
                                    <input type="text" class="form-control" placeholder="Write someting"/>
                                    <button type="submit" class="send-btn d-inline-block">Send</button>
                                </form>
                            </div>
                        </div>
                    </div>

                    <div class="birthday-list-box">
                        <div class="birthday-header">
                            <h3>Upcoming Birthday</h3>
                        </div>

                        <div class="list-item">
                            <div class="row justify-content-center">
                                <div class="col-lg-4 col-md-4">
                                    <div class="list-content">
                                        <a href="/Profile"><img src="assets/images/user/user-1.jpg" class="rounded-circle" alt="image" /></a>
                                        <div class="text">
                                            <h3>
                                                <a href="/Profile">Vanwin</a>
                                            </h3>
                                            <span>11 August, 2023</span>
                                        </div>
                                    </div>
                                </div>
                                <div class="col-lg-4 col-md-4">
                                    <div class="list-content">
                                        <a href="/Profile"><img src="assets/images/user/user-2.jpg" class="rounded-circle" alt="image" /></a>
                                        <div class="text">
                                            <h3>
                                                <a href="/Profile">Reynolds</a>
                                            </h3>
                                            <span>12 August, 2023</span>
                                        </div>
                                    </div>
                                </div>
                                <div class="col-lg-4 col-md-4">
                                    <div class="list-content">
                                        <a href="/Profile"><img src="assets/images/user/user-3.jpg" class="rounded-circle" alt="image" /></a>
                                        <div class="text">
                                            <h3>
                                                <a href="/Profile">Warren</a>
                                            </h3>
                                            <span>12 August, 2023</span>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="list-item">
                            <div class="row justify-content-center">
                                <div class="col-lg-4 col-md-4">
                                    <div class="list-content">
                                        <a href="/Profile"><img src="assets/images/user/user-4.jpg" class="rounded-circle" alt="image" /></a>
                                        <div class="text">
                                            <h3>
                                                <a href="/Profile">Heriberto</a>
                                            </h3>
                                            <span>13 August, 2023</span>
                                        </div>
                                    </div>
                                </div>
                                <div class="col-lg-4 col-md-4">
                                    <div class="list-content">
                                        <a href="/Profile"><img src="assets/images/user/user-5.jpg" class="rounded-circle" alt="image" /></a>
                                        <div class="text">
                                            <h3>
                                                <a href="/Profile">James</a>
                                            </h3>
                                            <span>14 August, 2023</span>
                                        </div>
                                    </div>
                                </div>
                                <div class="col-lg-4 col-md-4">
                                    <div class="list-content">
                                        <a href="/Profile"><img src="assets/images/user/user-6.jpg" class="rounded-circle" alt="image" /></a>
                                        <div class="text">
                                            <h3>
                                                <a href="/Profile">David</a>
                                            </h3>
                                            <span>15 August, 2023</span>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="list-item">
                            <div class="row justify-content-center">
                                <div class="col-lg-4 col-md-4">
                                    <div class="list-content">
                                        <a href="/Profile"><img src="assets/images/user/user-7.jpg" class="rounded-circle" alt="image" /></a>
                                        <div class="text">
                                            <h3>
                                                <a href="/Profile">Willie</a>
                                            </h3>
                                            <span>18 August, 2023</span>
                                        </div>
                                    </div>
                                </div>
                                <div class="col-lg-4 col-md-4">
                                    <div class="list-content">
                                        <a href="/Profile"><img src="assets/images/user/user-8.jpg" class="rounded-circle" alt="image" /></a>
                                        <div class="text">
                                            <h3>
                                                <a href="/Profile">Edith</a>
                                            </h3>
                                            <span>18 August, 2023</span>
                                        </div>
                                    </div>
                                </div>
                                <div class="col-lg-4 col-md-4">
                                    <div class="list-content">
                                        <a href="/Profile"><img src="assets/images/user/user-9.jpg" class="rounded-circle" alt="image" /></a>
                                        <div class="text">
                                            <h3>
                                                <a href="/Profile">Thompson</a>
                                            </h3>
                                            <span>21 August, 2023</span>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="list-item">
                            <div class="row justify-content-center">
                                <div class="col-lg-4 col-md-4">
                                    <div class="list-content">
                                        <a href="/Profile"><img src="assets/images/user/user-10.jpg" class="rounded-circle" alt="image" /></a>
                                        <div class="text">
                                            <h3>
                                                <a href="/Profile">Torres</a>
                                            </h3>
                                            <span>20 August, 2023</span>
                                        </div>
                                    </div>
                                </div>
                                <div class="col-lg-4 col-md-4">
                                    <div class="list-content">
                                        <a href="/Profile"><img src="assets/images/user/user-11.jpg" class="rounded-circle" alt="image" /></a>
                                        <div class="text">
                                            <h3>
                                                <a href="/Profile">Calvin</a>
                                            </h3>
                                            <span>12 August, 2023</span>
                                        </div>
                                    </div>
                                </div>
                                <div class="col-lg-4 col-md-4">
                                    <div class="list-content">
                                        <a href="/Profile"><img src="assets/images/user/user-12.jpg" class="rounded-circle" alt="image" /></a>
                                        <div class="text">
                                            <h3>
                                                <a href="/Profile">Christine</a>
                                            </h3>
                                            <span>25 August, 2023</span>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                
            </div>
            
            <SideBarRight/>
        </div>
    </div>
</> 
); 
} 
export default Birthday;