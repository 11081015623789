import React from 'react';
import './Privacypolicy.css'

const PrivacyPolicy = () => {
    return (
        <div className='PrivacyPolicy_container'>
            <b className='PrivacyPolicy_container_bold'>Earn Pixalive Rewards</b>

            <br></br>Pixalive Rewards are tokens of appreciation for our wonderful user community. To help you earn more rewards, we have come up with some simple ways to achieve your goals. You can earn these rewards by uploading videos, watching content, and inviting friends to join Pixalive.
            Earn Pixalive Rewards by uploading videos, watching content, and inviting friends to join the community. Maximize your benefits by engaging in these activities more frequently!

            <br></br><br></br>Earn rewards by posting your videos, watching other videos, and inviting friends!
            <br></br><br></br>o	Refer friends to join Pixalive using your unique referral link or code, and earn Rs 5.
            <br></br><br></br>o	Download the Pixalive application from the Play Store or App Store and log in to instantly receive 500 coins.
            <br></br><br></br>o	Apply a referral code during the Pixalive app installation and login process to receive an instant 1000 coins in your wallet.
            <br></br><br></br>o	Earn 25 Pixalive Coins by posting a video.
            <br></br><br></br>o	Watch a full video posted by another user in Pixalive Plays to earn 5 Pixalive Coins.
            <br></br><br></br>o	For each view of your full video by other users, you will be rewarded with 5 coins.


            <br></br><br></br>Keep watching, posting, and inviting friends to earn rewards!

            <br></br><br></br><div className='PrivacyPolicy_container_bold'>Frequently Asked Questions</div>

            <br></br><br></br>1. How do I earn the coins?
            <br></br><br></br>By posting a video & watching a full video posted by another user, you will receive coins in your wallet. If other users watch your full video, you will receive a reward & also by refer a friend.

            <br></br><br></br>2. What are all the ways to earn coin?
            <br></br><br></br>Video Upload Reward: You can earn coins by posting your own videos on Pixalive. The more videos you upload, the more coins you can earn.
            Watch Video Reward: You receive coins for watching videos posted by other users. Watching a full video typically qualifies you for this reward. Similarly, if other users watch a full video that you have posted, you will also receive coins as a reward.
            Invite Friends Reward: Earn coins by referring your friends to join Pixalive using your unique referral link or code. You can earn coins when they sign up and start using the platform.

            <br></br><br></br>3. What is the benefit of using referral code/link?
            <br></br><br></br>If you apply a referral code during the Pixalive app installation and login process, you will instantly receive 1000 coins in your wallet. Otherwise, you will receive only 500 coins by directly installing the app from the Play Store or App Store.

            <br></br><br></br>4. What is the benefit of sending the referral link?
            <br></br><br></br>You can earn 2500 coins by referring your friends to join Pixalive using your unique referral link or code. Additionally, your friend will also receive the benefit of receiving 1000 coins by using your referral code.

            <br></br><br></br>5. Where can I check my earned coins?
            <br></br><br></br>You can view your earned coins in Rewards section.

            <br></br><br></br>6. Can I redeem coins to my bank account?
            <br></br><br></br>Yes.

            <br></br><br></br>7. What is the conversion rate from coins to INR?
            <br></br><br></br>Currently, 500 Pixalive Coin = Rs 1.

            <br></br><br></br>8. How can I redeem my coins?
            <br></br><br></br>You can cash out the Pixalive Coins by clicking on the "Redeem" button. After clicking, you will see the 'Pixalive Redeem Card' with full details of the redeem amount and earned coins.

            <br></br><br></br>9. What are the criteria to redeem my coins?
            <br></br><br></br>To be eligible for redemption, you must have a minimum of 25,000 coins in your wallet. Once you reach this threshold, you can exchange your coins for rewards. The maximum coins you can redeem at a time is 25,000 coins.

            <br></br><br></br>10. How can I redeem more than 25,000 coins in my wallet?
            <br></br><br></br>If you have 35,000 coins in your wallet within 90 days, you can redeem 25,000 coins first.  The remaining 10,000 coins will stay in your wallet. If you earn an additional 15,000 coins after the redemption, your total will be 25,000 coins, which you can then redeem.

            <br></br><br></br>11.  What is the time limit for coin to expire?
            <br></br><br></br>Coin expiration time period is 3 months.
            <br></br><br></br>12.  What will happen to my coin after 3 months?
            <br></br><br></br>After 3 months, your coins will expire, and your wallet will be reset to zero. This means that any unused or remaining coins in your account will no longer be valid or available for use. It is essential to keep track of your coin balance and utilize them before they expire to maximize their benefits.

            <br></br><br></br>13.  Is there any option to increase the expire period of the coin?
            <br></br><br></br>Yes, there is an option to increase the expiration period of the coin. You can achieve this by purchasing the Pixalive Coin Booster from the store, which will extend its life expectancy.
            1500 Coins	3000 Coins	4500 Coins
            <br></br><br></br>15 Days	30 Days	45 Days

            <br></br><br></br>14. How many Coin Boosters can I used before coin expire?
            <br></br><br></br>You can use Coin Boosters from the store before your coins expire. There are three levels of boosters available, but you can only apply one at a time. Pick wisely to extend your coin expiry period.

            <br></br><br></br>15. How can I participate in Pixalive Rewards Program?
            Install the Pixalive Android app from the Google Play Store or the iOS app from the App Store.
            Sign up for an account by entering your mobile number. If you already have a Pixalive account, simply log in using your existing credentials on the Pixalive app.
            Once you have signed up or logged in, you will become eligible to start earning rewards.
            You can also participate using the Pixalive Web Application.

            <br></br><br></br>16. Where will I receive the money from redeeming my coins?
            <br></br><br></br>You can receive a money to your bank accounts, UPI, or PayPal id.

            <br></br><br></br>17. Where can I earn Pixalive Rewards on Pixalive website or app?
            <br></br><br></br>You can earn Pixalive Rewards on both the Pixalive website & app

            <br></br><br></br>18. How can I keep track of daily rewarded coins I have earned?
            <br></br><br></br>You can track in reward section.

            <br></br><br></br>19. How much time does it take to receive the amount into bank account?
            <br></br><br></br>You will receive the cashed-out rewarded coins within 15 working days, transferred to your given UPI or other payment method.


            <br></br><br></br>New Methods
            <br></br><br></br>For Posting Videos/Plays	 =	25 Coins
            <br></br><br></br>For Watching Videos/Plays	 =	5 Coins to video creator and viewer
            <br></br><br></br>For successful Referral Code install	 =	2500 Coins
            <br></br><br></br>Install directly from Play Store/App Store	=	500 Coins
            <br></br><br></br>Install using referral code	=	1000 Coins



            The company has the right to alter the program at any time without requiring anyone's approval. Additionally, the company is allowed to make changes to the payment process.

        </div>
    )
}

export default PrivacyPolicy
