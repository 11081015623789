
import { Link, useLocation } from 'react-router-dom';
import Header from '../../components/Header';
import SideBar from '../../components/SideBar';
import { Chip, Dialog, DialogContent, DialogTitle, IconButton, Tooltip } from '@mui/material';
import { useEffect, useState } from 'react';
import { deletePost, getCategoryBasedPostCount, getFilterPost, getSinglePost, savePostComments, updateBlockPost, updatePostReport } from '../../api/post';
import { timeCal } from '../../utils/dateformat';
import { getUserId } from '../../utils/Storage';
import { getSingleUser, updateUserCategory } from '../../api/user';
import { toast } from 'react-toastify';
import { getSingleCategory } from '../../api/category';
import { EmailIcon, EmailShareButton, FacebookIcon, FacebookShareButton, LinkedinIcon, LinkedinShareButton, TelegramIcon, TelegramShareButton, TwitterIcon, TwitterShareButton, WhatsappIcon, WhatsappShareButton } from 'react-share';
import { getFilterLikes, saveLikes } from '../../api/likes';
import { getFilterBidding, saveBidding } from '../../api/bidding';
import { acceptFollower, getAllFollowings, saveFollower } from '../../api/follow';

function SingleCategory() {

    const [post, setPost] = useState([]);
    const [open, setOpen] = useState(false);
    const [postDetails, setPostDetails] = useState()
    const [next, setNext] = useState(0);
    const [count, setCount] = useState(0);
    const [scroll, setScroll] = useState(false);
    const location = useLocation();
    const [followingCategory, setFollowingCategory] = useState([]);
    const [followCategory, setFollowCategory] = useState([]);
    const categoryId = new URLSearchParams(location.search).get('id');
    const [stateChanged, setStateChanged] = useState(false);
    const [categoryCount, setCategoryCount] = useState()
    const [changeCategory, setChangeCategory] = useState(false);
    const [reload, setReload] = useState(false);
    const [isMounted, setIsMounted] = useState(false)
    const [category, setCategory] = useState('')
    const [openBid, setOpenBid] = useState(false);
    const [bidAmount, setBidAmount] = useState('');
    const [submit, setSubmit] = useState(false);
    const [postId, setPostId] = useState('');
    const [bidding, setBidding] = useState([]);
    const [hide, setHide] = useState(false);
    const [hideId, setHideId] = useState('')
    const [postLikes, setPostLikes] = useState('')
    const [coins, setCoins] = useState('');
    const [share, setShare] = useState(false);
    const [shareUrl, setShareUrl] = useState('');
    const [content, setContent] = useState('')
    const [allFollowing, setAllFollowing] = useState([])
    const [user, setUser] = useState()
    const [likes, setLikes] = useState([])
    const [userDetails, setUserDetails] = useState('');
    const [input, setInput] = useState('');
    const [openReport, setOpenReport] = useState(false)
    const [report, setReport] = useState({});
    const [openDescription, setOpenDescription] = useState(false);
    const [submitReport, setSubmitReport] = useState(false);
    const [reportId, setReportId] = useState('');
    const [reportResponse,setReportResponse]=useState(false)
    const [deleteId, setDeleteId] = useState('');
    const [openDelete, setOpenDelete] = useState(false)
    const [postDelete, setPostDelete] = useState(false);


    useEffect(() => {
        getSingleCategoryBasePost()
    }, [next])

    useEffect(() => {
        if (changeCategory) {
            getSingleCategoryBasePost()
            setChangeCategory(false)
        }
    }, [changeCategory])

    useEffect(() => {
        if (isMounted) {
            if (next === 0) { setChangeCategory(true) }
            else { setNext(0); }
            setPost([])
        }
        else {
            setIsMounted(true)
        }
    }, [categoryId])


    useEffect(() => {
        const user = getUserId()
        setUser(user)
        categoryBasedPostAndUser();
        getSingleCategoryDetails();
        getUserDetails();
        getAllFollowing()
        getUserLikedPost();
        getFilterBiddingList();
        window.addEventListener('scroll', handleScroll);
    }, [categoryId])

    const handleScroll = () => {
        const value = window.innerHeight + window.scrollY >=
            document.documentElement.scrollHeight - 200
        setScroll(value)
    };
    useEffect(() => {
        if (scroll) {
            loadMore();
        }
    }, [scroll])

    useEffect(() => {
        if (stateChanged) {
            handleSubmit()
            setStateChanged(false)
        }
    }, [followCategory])


    const getSingleCategoryBasePost = () => {
        const data = {
            limit: 8,
            page: next,
            category: categoryId
        }
        getFilterPost(data)
            .then((res) => {
                const postList = res?.data?.result?.postList;
                setCount(res?.data?.result?.postCount)
                setPost([...post, ...postList]);
            })
            .catch((err) => {
                console.log(err);
            });
    };

    const getSingleCategoryDetails = () => {
        const data = { _id: categoryId }
        getSingleCategory(data).then(res => {
            setCategory(res?.data?.result)
        }).catch(err => {
            console.log(err);
        })
    }

    const loadMore = () => {
        let nextPost = next;
        if (postDelete) {
            nextPost = nextPost + 7;
            setPostDelete(false)
        }
        else {
            nextPost = nextPost + 8;
        }
        if (count <= nextPost) {
            setReload(true)
        }
        else {
            setNext(nextPost);
        }
    };

    const openPopup = (data) => {
        setOpen(true);
        setPostDetails(data)
    };
    const closePopup = () => {
        setOpen(false);
    };

    const handleFollowCategory = (data) => {
        const newFollowCategory = [...followingCategory, data]
        setFollowCategory(newFollowCategory)
        setStateChanged(true)
    }
    const handleFollowingCategory = (data) => {
        if (followingCategory.length > 5) {
            const filterFollowCategory = followingCategory.filter(x => x !== data)
            setFollowCategory(filterFollowCategory)
            setStateChanged(true)
        }
        else {
            toast.warning('Please follow atleast five Categories')
        }
    }

    const handleSubmit = () => {
        const data = { _id: getUserId(), category: followCategory }
        updateUserCategory(data).then(res => {
            toast.success(res?.data?.message)
            getUserDetails();
            categoryBasedPostAndUser();
        }).catch((err) => {
            console.log(err);
        })

    }

    const getUserDetails = () => {
        const userId = getUserId()
        getSingleUser(userId).then(res => {
            const data = res?.data?.result?.category
            const categoryArray = data.map(x => x._id);
            setCoins(res?.data?.result?.coins);
            setUserDetails(res?.data?.result);
            setFollowingCategory(categoryArray);
        }).catch(err => {
            console.log(err);
        })
    }

    const categoryBasedPostAndUser = () => {
        const data = { category: categoryId }
        getCategoryBasedPostCount(data).then(res => {
            setCategoryCount(res?.data?.result);
        }).catch(err => {
            console.log(err);
        })
    }

    const savePostLikes = (postId, like) => {
        const user = getUserId()
        if (like === 'add') {
            setPostLikes(postId)
        }
        else { setPostLikes('') }
        const data = {
            user: user,
            post: postId
        }
        saveLikes(data).then(res => {
            singlePost(postId)
            getUserLikedPost()
        }).catch(err => {
            console.log(err);
        })
    }

    const singlePost = (postId) => {
        getSinglePost(postId).then(res => {
            const result = res?.data?.result
            const data = { ...postDetails, likeCount: result?.likeCount, bidingCount: result?.bidingCount, commentCount: result?.commentCount, comment: result?.comment }
            setPostDetails(data)
            const newPost = post.map(item =>
                item._id === result?._id ? { ...item, likeCount: result?.likeCount, bidingCount: result?.bidingCount,commentCount:result?.commentCount,comment:result?.comment}: item
            );
            setPost(newPost)
        }).catch(err => {
            console.log(err);
        })
    }

    const getUserLikedPost = () => {
        const data = { user: getUserId() }
        getFilterLikes(data).then(res => {
            setLikes(res?.data?.result)
        }).catch(err => {
            console.log(err);
        })
    }

    const openBidPopup = (id) => {
        setBidAmount(' ')
        setOpenBid(true);
        setSubmit(false);
        setPostId(id)
    };
    const closeBidPopup = () => {
        setOpenBid(false);
    };

    const handleBidAmount = (event) => {
        setBidAmount(event?.target?.value)
    }

    const handleBidAmountSubmit = (event) => {
        event.preventDefault()
        setSubmit(true)
        if (bidAmount) {
            if (coins >= bidAmount) {
                const data = {
                    user: user,
                    post: postId,
                    type: "post",
                    bidCoins: bidAmount
                }
                saveBidding(data).then(res => {
                    toast.success('Successfully submitting a bid');
                    closeBidPopup();
                    getFilterBiddingList();
                    singlePost(postId)
                }).catch(err => {
                    console.log(err);
                })
            }
            else {
                toast.warning('Your coin is less than the amount you have bid.')
            }
        }
    }

    const resetBidAmount = () => {
        setBidAmount("")
    }

    const getFilterBiddingList = () => {
        const data = { type: 'post' }
        getFilterBidding(data).then(res => {
            setBidding(res?.data?.result)
        }).catch(err => {
            console.log(err);
        })
    }

    const handleHidePost = () => {
        const data = { _id: hideId, blockedUsers: user }
        updateBlockPost(data).then(res => {
            closeHidePopup();
            closePopup();
            toast.success(res?.data?.message)
            const newPostList = post.filter(x => x._id !== hideId)
            setPost(newPostList)
        }).catch(err => {
            console.log(err);
        })
    }

    const openHidePopup = (hideId) => {
        setHideId(hideId)
        setHide(true);
    };
    const closeHidePopup = () => {
        setHide(false);
    };

    const openSharePopup = (url, content) => {
        setShareUrl(url);
        setContent(content)
        setShare(true)
    };
    const closeSharePopup = () => {
        setShare(false);
    };

    const getAllFollowing = () => {
        const data = { id: getUserId() };
        getAllFollowings(data)
            .then((res) => {
                setAllFollowing(res?.data?.result);
            })
            .catch((err) => {
                console.log(err);
            });
    };
    const handleFollowers = (id, connect) => {
        const data = {
            follower: getUserId(),
            following: id,
            isConnect: connect === false ? true : false,
        };
        saveFollower(data)
            .then((res) => {
                getAllFollowing()
            })
            .catch((err) => {
                console.log(err);
            });

    }

    const handleRequest = (id) => {
        const data = { _id: id, isConnect: false };
        acceptFollower(data)
            .then((res) => {
                getAllFollowing()
            })
            .catch((err) => {
                console.log(err);
            });
    };


    const handleInputs = (event) => {
        setInput({ ...input, message: event.target.value })
    }

    const handleComments = (id) => {
        if (input?.message) {
            const data = {
                _id: id,
                comment: {
                    comments: input?.message,
                    user: userDetails?._id
                }
            }
            savePostComments(data).then(res => {
                setInput({ ...input, message: '' })
                singlePost(id)
            }).catch(err => { console.log(err) })
        }
        else {
            toast.warning('Please enter the comment')
        }
    }

    const openReportPopup = (data) => {
        setReportId(data)
        setOpenReport(true)
    }
    const closeReportPopup = () => {
        setOpenReport(false)
    }

    const openDescriptionPopup = (data) => {
        setReport({ ...report, type: data })
        setSubmitReport(false)
        closeReportPopup()
        setOpenDescription(true)
    }
    const closeDescriptionPopup = () => {
        setOpenDescription(false)
    }

    const openReportResponsePopup = (data) => {
       setReportResponse(true)
    }
    const closeReportResponsePopup = () => {
        setReportResponse(false)
    }

    const handleDescription = (event) => {
        const description = event?.target.value
        setReport({...report, description})
    }

    const handleReport = () => {
        setSubmitReport(true)
        if (report?.description) {
            const data = {
                _id: reportId,
                report: {
                    user: user,
                    type: report?.type,
                    description: report?.description
                }
            }
            updatePostReport(data).then(res => {
                closeDescriptionPopup();
                openReportResponsePopup()
            }).catch(err => { console.log(err); })
        }

    }

    const openDeletePopup = (data) => {
        setDeleteId(data)
        setOpenDelete(true)
    }
    const closeDeletePopup = () => {
        setOpenDelete(false)
    }

    const deletePostDetails = () => {
        deletePost(deleteId).then(res => {
            closeDeletePopup()
            closePopup()
            toast.success(res?.data?.message);
            const newPostList = post.filter(x => x._id !== deleteId)
            setPost(newPostList)
            setCategoryCount({...categoryCount,postCount:categoryCount?.postCount-1})
            setPostDelete(true)
        }).catch(err => { console.log(err); })
    }


    return (
        <>

            <Header />
            <div className="content-page-box-area">
                <div className="row">
                    <SideBar />

                    <div className="col-lg-9 col-md-12">

                        <div className="content-page-box-area">
                            <div className="my-profile-inner-box">
                                <div className="profile-info-box">
                                    <div className="inner-info-box">
                                        <div className="info-profile-image">
                                            <div className="info-image">
                                                
                                                    <img src={category?.image} alt="category" />
                                                
                                            </div>
                                            <div className="info-text ms-3">
                                                <h3>{category?.category}</h3>
                                                {followingCategory?.includes(categoryId) ?
                                                    <div className='category-following-btn'> <button onClick={() => handleFollowingCategory(categoryId)} type="button"><i className="material-icons-outlined">check</i>Following</button></div> :
                                                    <div className='category-follow-btn'> <button onClick={() => handleFollowCategory(categoryId)} type="button"><i className="material-icons-outlined">add</i>Follow</button></div>}
                                            </div>
                                        </div>

                                        <ul className="statistics category-statistics">
                                            <li>

                                                <span className="item-number">{categoryCount?.postCount}</span>
                                                <span className="item-text">Posts</span>

                                            </li>
                                            <li>

                                                <span className="item-number">{categoryCount?.userCount}</span>
                                                <span className="item-text">Followers</span>

                                            </li>
                                        </ul>
                                    </div>

                                </div>
                            </div>

                            <div className="gallery -mt-16 trending-video">
                                {post?.map((data, index) =>
                                    <div key={index} className="gallery__card" >
                                        <div onClick={(event) => { openPopup(data); event.preventDefault() }}>
                                            <div className="card__image">

                                                {data?.fileType === 1 ?
                                                    <img
                                                        src={data?.url}
                                                        alt="post"
                                                    /> :
                                                    <video src={data?.url} type="video/mp4" width="auto" height="100%">
                                                    </video>}
                                                <div className="add-icon">
                                                    <Link to="#"> <i className="feather-heart"></i><span>{data?.likeCount}</span></Link>
                                                    <Link to="#"> <i className="feather-message-circle"></i><span>{data?.commentCount}</span></Link>
                                                </div>
                                            </div>
                                        </div>
                                    </div>)}
                            </div>
                            {reload ?
                                <div className='form-text text-danger text-center mt-3'>No Post</div> : null}
                        </div>
                    </div>
                </div>
            </div>



            <Dialog open={open} fullWidth maxWidth="md">
                <DialogTitle>
                    <IconButton className="float-end" onClick={closePopup} >
                        <i className="material-icons-round ">close</i>
                    </IconButton>
                </DialogTitle>
                <DialogContent className="login-form">
                    <div className="row">
                        <div className="col-lg-6">
                            <div className="pop-image">
                                <div className="pop-item">
                                    <figure>
                                        <div className="video-model">
                                            {postDetails?.fileType === 1 ?
                                                <img
                                                    src={postDetails?.url}
                                                    alt="post"
                                                /> :
                                                <video controls>
                                                    <source src={postDetails?.url} type="video/mp4" />
                                                </video>}
                                        </div>
                                    </figure>

                                </div>
                            </div>
                        </div>
                        <div className="col-lg-6">
                            <div className="video_card_modal commentbar">
                                <div className='user'>
                                    {postDetails?.isBided ?
                                        <div>
                                            <figure><img src={postDetails?.bidedUser?.imageUrl} alt="" /></figure>
                                            <div className="user-information">
                                                <h4><Link to={{ pathname: '/Profile', search: `id=${postDetails?.bidedUser?._id}` }} title="">{postDetails?.bidedUser?.userName} <span className='small-text'>{" "}Owned this content</span></Link></h4>
                                            </div>
                                        </div> : null}
                                </div>
                                <div className="user">
                                    <div>
                                        <figure><img src={postDetails?.user?.imageUrl} alt="" /></figure>
                                        <div className="user-information">
                                            <h4><Link to={{ pathname: '/Profile', search: `id=${postDetails?.user?._id}` }} >{postDetails?.user?.userName}</Link></h4>
                                            <span>{timeCal(postDetails?.createdOn)}</span>
                                        </div>
                                    </div>
                                    <div className="more-follow-option">
                                        {postDetails?.isBided ?
                                            (postDetails?.bidedUser?._id !== user ?
                                                (allFollowing.some(x => x?.following?._id === postDetails?.bidedUser?._id && !x.isConnect) ?
                                                    (
                                                        allFollowing.map(x => {
                                                            if (x?.following?._id === postDetails?.bidedUser?._id && !x.isConnect) {
                                                                return (
                                                                    <button className="btn-feed-request" onClick={() => handleRequest(x?._id)} type="button" >Requested</button>

                                                                );
                                                            }
                                                            return null
                                                        })) :
                                                    allFollowing.some(x => x?.following?._id === postDetails?.bidedUser?._id && x.isConnect) ?
                                                        <button className="btn-feed-following" onClick={() =>
                                                            handleFollowers(
                                                                postDetails?.bidedUser?._id,
                                                                postDetails?.bidedUser?.isPrivate
                                                            )
                                                        } type="button" >Following</button> :
                                                        <button className="btn-feed-follow" onClick={() =>
                                                            handleFollowers(
                                                                postDetails?.bidedUser?._id,
                                                                postDetails?.bidedUser?.isPrivate
                                                            )
                                                        } type="button">Follow</button>) : null)
                                            : (postDetails?.user?._id !== user ? (allFollowing.some(x => x?.following?._id === postDetails?.user?._id && !x.isConnect) ?
                                                (allFollowing.map(x => {
                                                    if (x?.following?._id === postDetails?.user?._id && !x.isConnect) {
                                                        return (
                                                            <button className="btn-feed-request" onClick={() => handleRequest(x?._id)} type="button" >Requested</button>

                                                        );
                                                    }
                                                    return null
                                                })) :
                                                allFollowing.some(x => x?.following?._id === postDetails?.user?._id && x.isConnect) ?
                                                    <button className="btn-feed-following" onClick={() =>
                                                        handleFollowers(
                                                            postDetails?.user?._id,
                                                            postDetails?.user?.isPrivate
                                                        )
                                                    } type="button" >Following</button> :
                                                    <button className="btn-feed-follow" onClick={() =>
                                                        handleFollowers(
                                                            postDetails?.user?._id,
                                                            postDetails?.user?.isPrivate
                                                        )
                                                    } type="button">Follow</button>) : null)}
                                        {
                                            postDetails?.isBid && !postDetails?.isBided ?
                                                (postDetails?.user?._id === user || bidding?.some(x => x?.user?._id === user && x?.post === postDetails?._id) ?
                                                    <Tooltip title="Bid Count"> <Chip className='me-2' icon={<i className="material-icons-outlined" style={{ color: "gold", fontSize: "20px" }}>paid</i>} label={postDetails?.bidingCount} size='small' color='success' /> </Tooltip>
                                                    : <div className="buy-button">
                                                        <button type="button" onClick={() => openBidPopup(postDetails?._id)}><i className="material-icons-outlined">paid</i>Buy</button>
                                                    </div>)
                                                : null}
                                        <div className="dropdown ms-2">
                                            <button className="dropdown-toggle" type="button" data-bs-toggle="dropdown" aria-haspopup="true" aria-expanded="false"><i className="feather-more-horizontal"></i></button>
                                            <ul className="dropdown-menu">
                                            {postDetails?.user?._id !== user || postDetails?.isBided ? (postDetails?.bidedUser?._id !== user ?
                                                    <><li><Link className="dropdown-item d-flex align-items-center" onClick={(event) =>{event?.preventDefault(); openHidePopup(postDetails?._id)}}><i className="material-icons-outlined">hide_source</i> Hide</Link></li>
                                                        <li><Link className="dropdown-item d-flex align-items-center" onClick={(event) =>{event?.preventDefault(); openReportPopup(postDetails?._id)}} ><i className="material-icons-outlined">report</i> Report</Link></li></>
                                                    : <><li><Link className="dropdown-item d-flex align-items-center" to={{ pathname: '/EditPost', search: `postId=${postDetails?._id}` }} ><i className="material-icons-outlined">edit</i> Edit</Link></li>
                                                    <li><Link className="dropdown-item d-flex align-items-center" onClick={(event) => {event?.preventDefault();openDeletePopup(postDetails?._id)}} ><i className="material-icons-outlined">delete</i> Delete</Link></li></>) : 
                                                    <><li><Link className="dropdown-item d-flex align-items-center" to={{ pathname: '/EditPost', search: `postId=${postDetails?._id}` }} ><i className="material-icons-outlined">edit</i> Edit</Link></li>
                                                    <li><Link className="dropdown-item d-flex align-items-center" onClick={(event) =>{event?.preventDefault(); openDeletePopup(postDetails?._id)}} ><i className="material-icons-outlined">delete</i> Delete</Link></li></>}                                           
                                                     </ul>
                                        </div>
                                    </div>
                                </div>
                                <div className="we-video-info">
                                    <ul className="we-video-info-meta-wrap">
                                        <li className="post-react">
                                            <Link >
                                                {postLikes === postDetails?._id || likes?.some(x => x.post?._id === postDetails?._id) ?
                                                    <i className="material-icons-round text-danger" onClick={(event) => { event.preventDefault(); savePostLikes(postDetails?._id, 'remove') }} >favorite</i> :
                                                    <i className="feather-heart" onClick={(event) => { event.preventDefault(); savePostLikes(postDetails?._id, 'add') }}></i>}
                                                <span className="number">{postDetails?.likeCount} </span></Link>
                                        </li>
                                        <li className="post-comment">
                                            <Link><i className="feather-message-circle"></i><span className="number">{postDetails?.commentCount} </span></Link>
                                        </li>
                                        <li className="post-share">
                                            <Link onClick={(event) => { event.preventDefault(); openSharePopup(postDetails?.url, postDetails?.textContent) }} ><img src="../assets/images/share.png" alt="share" />
                                            {/* <span className="number">{postDetails?.shareCount} </span> */}
                                            </Link>
                                        </li>
                                    </ul>
                                </div>

                                <div className="new-comments post-modal-popup">
                                    <div className="comments-area" data-simplebar>
                                        <div className="post-comment-list">
                                            {postDetails?.comment?.map((value, index) =>
                                                <div className="comment-list" key={index}>
                                                    <div className="comment-image">
                                                        <Link to={{ pathname: '/Profile', search: `id=${value?.user?._id}` }}><img src={value?.user?.imageUrl} className="rounded-circle" alt="user" /></Link>
                                                    </div>
                                                    <div className="comment-info">
                                                        <h3>
                                                            <Link to={{ pathname: '/Profile', search: `id=${value?.user?._id}` }}>{value?.user?.userName}</Link>
                                                        </h3>
                                                        <span>{timeCal(value?.createdOn)}</span>
                                                        <p>{value?.comments}</p>
                                                        {/* <ul className="comment-react">
                                                        <li><a href="#" className="like">1,351 likes</a></li>
                                                        <li><a href="#">Reply</a></li>
                                                    </ul> */}
                                                    </div>
                                                </div>)}
                                            {/* <div className="more-comments">
                                                <a href="#">More Comments+</a>
                                            </div> */}
                                        </div>
                                    </div>

                                    <form className="post-footer">
                                        <div className="footer-image">
                                            <Link to={{ pathname: '/Profile', search: `id=${userDetails?._id}` }}><img src={userDetails?.imageUrl} className="rounded-circle" alt="user" /></Link>
                                        </div>
                                        <div className="form-group">
                                            <textarea name="message" value={input?.message} onChange={handleInputs} className="form-control" placeholder="Write a comment..."></textarea>
                                            <label><Link onClick={(event) => { event?.preventDefault(); handleComments(postDetails?._id) }} ><i className="feather-send"></i></Link></label>
                                        </div>
                                    </form>
                                </div>


                            </div>
                        </div>
                    </div>
                </DialogContent>
            </Dialog>

            <Dialog open={openBid} fullWidth maxWidth="xs">
                <DialogTitle>
                    Post Bidding					<IconButton className="float-end" onClick={closeBidPopup} >
                        <i className="material-icons-round">close</i>
                    </IconButton>
                </DialogTitle>
                <DialogContent>
                    <form onSubmit={handleBidAmountSubmit}>
                        <div className="from-group mb-3">
                            <label htmlFor="categoryName" className="form-label">
                                Bid Amount
                            </label>
                            <div className="input-group">
                                <div className="input-group-prepend">
                                    <span className="input-group-text">₹</span>
                                </div>
                                <input type="text" value={bidAmount} onChange={handleBidAmount} name='biddingAmount' className="form-control" placeholder="Bid Amount" />
                            </div>
                            {!bidAmount && submit ? (
                                <span className="form-text text-danger">
                                    This field is required.
                                </span>
                            ) : null}
                        </div>
                        <div className='d-flex justify-content-end'>
                            <button type='submit' className="btn-save me-3"> Submit </button>
                            <button type='button' className="btn-cancel" onClick={resetBidAmount}> Reset </button>
                        </div>
                    </form>
                </DialogContent>
            </Dialog>

            <Dialog open={hide}>
                <DialogContent>
                    <div className="text-center m-4">
                        <h5 className="mb-4">Are you sure you want to hide <br /> the selected Post ?</h5>
                        <button type="button" className="btn btn-save mx-3" onClick={handleHidePost}>Yes</button>
                        <button type="button" className="btn btn-cancel " onClick={closeHidePopup}>No</button>
                    </div>
                </DialogContent>
            </Dialog>

            <Dialog open={share} fullWidth maxWidth="xs">
                <DialogTitle className='text-secondary'>
                    Share On Social Media<IconButton className="float-end" onClick={closeSharePopup} >
                        <i className="material-icons-round">close</i>
                    </IconButton>
                </DialogTitle>
                <DialogContent>
                    <div className="p-4 d-flex justify-content-between">
                        <WhatsappShareButton url={shareUrl} title={content} >
                            <WhatsappIcon size={32} round={true} />
                        </WhatsappShareButton>
                        <FacebookShareButton url={shareUrl} title={content} >
                            <FacebookIcon size={32} round={true} />
                        </FacebookShareButton>
                        <TwitterShareButton url={shareUrl} title={content}>
                            <TwitterIcon size={32} round={true} />
                        </TwitterShareButton>
                        <TelegramShareButton url={shareUrl} title={content}>
                            <TelegramIcon size={32} round={true} />
                        </TelegramShareButton>
                        <LinkedinShareButton url={shareUrl} title={content}>
                            <LinkedinIcon size={32} round={true} />
                        </LinkedinShareButton>
                        <EmailShareButton url={shareUrl} title={content}>
                            <EmailIcon size={32} round={true} />
                        </EmailShareButton>
                    </div>
                </DialogContent>
            </Dialog>

            <Dialog open={openReport} fullWidth maxWidth="xs" >
                <DialogTitle>Report
                    <IconButton className="float-end" onClick={closeReportPopup} >
                        <i className="material-icons-round" >close</i>
                    </IconButton>
                </DialogTitle>
                <DialogContent>
                    <div className='report'>
                        <h6>Report this Post</h6>
                        <span>Help us understand what's happening with this post. How would you describe it?</span>
                        <ul className='mt-2'>
                            <li> <span onClick={() => openDescriptionPopup('Misleading or scam')}>Misleading or scam </span> </li>
                            <li> <span onClick={() => openDescriptionPopup('Sexually inappropriate')}> Sexually inappropriate </span></li>
                            <li><span onClick={() => openDescriptionPopup('Offensive')}>Offensive</span></li>
                            <li><span onClick={() => openDescriptionPopup('Violence')}>Violence</span></li>
                            <li><span onClick={() => openDescriptionPopup('Prohibited content')}>Prohibited content</span> </li>
                            <li><span onClick={() => openDescriptionPopup('Spam')}>Spam </span> </li>
                            <li><span onClick={() => openDescriptionPopup('False news')}>False news </span></li>
                            <li><span onClick={() => openDescriptionPopup('Political candidate or issue')}>Political candidate or issue </span> </li>
                            <li><span onClick={() => openDescriptionPopup('Other')}>Other</span></li>
                        </ul>
                    </div>
                </DialogContent>
            </Dialog>

            <Dialog open={openDescription} fullWidth maxWidth="sm" >
                <DialogTitle>Report
                    <IconButton className="float-end" onClick={closeDescriptionPopup} >
                        <i className="material-icons-round" >close</i>
                    </IconButton>
                </DialogTitle>
                <DialogContent>
                    <div className="row">
                        <div className="col-lg-12 col-md-12 col-sm-12">
                            <div className="form-group">
                                <label className='mb-2'>Description</label>
                                <textarea className="form-control" rows={4} onChange={handleDescription} name='description' placeholder="Description"></textarea>
                                {!report?.description && submitReport ? (
                                    <span className="form-text text-danger">
                                        This field is required.
                                    </span>
                                ) : null}
                            </div>
                        </div>
                    </div>
                    <div className="float-end mt-3">
                        <button type="button" className="btn btn-save mx-3" onClick={handleReport} >Submit</button>
                        <button type="button" className="btn btn-cancel " onClick={closeDescriptionPopup}>Cancel</button>
                    </div>
                </DialogContent>
            </Dialog>

            <Dialog open={reportResponse} fullWidth maxWidth="xs" >
                <DialogTitle className='p-0'>
                    <IconButton className="float-end m-2" onClick={closeReportResponsePopup} >
                        <i className="material-icons-round" >close</i>
                    </IconButton>
                </DialogTitle>
                <DialogContent className='responseModal'>
                <span><i className="material-icons-round" >check_circle</i></span>
                <h5>Thank you for submitting a report.</h5>
                <div className='my-2'><Chip color="primary" variant="outlined"  label={report?.type}></Chip></div>
                <span>We'll let you know when there's an update on this report.</span>
                </DialogContent>
            </Dialog>

            <Dialog open={openDelete}>
          <DialogContent>
            <div className="text-center m-4">
              <h5 className="mb-4">Are you sure you want to Delete <br /> the selected Post ?</h5>
              <button type="button" className="btn btn-save mx-3" onClick={deletePostDetails}>Yes</button>
              <button type="button" className="btn btn-cancel " onClick={closeDeletePopup}>No</button>
            </div>
          </DialogContent>
        </Dialog>
        </>
    );
}
export default SingleCategory;