import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import Home from "./pages/Home/Home";
import Plays from "./pages/Plays/Plays";
import Category from "./pages/Category/Category";
import Profile from "./pages/Profile/Profile";
import Birthday from "./pages/Birthday/Birthday";
import HomeLogin from "./pages/Home/HomeLogin";
import Stories from "./pages/Stories/Stories";
import TrendingPeople from "./pages/TrendingPeople/TrendingPeople";
import TrendingVideo from "./pages/TrendingVideo/TrendingVideo";
import Notifications from "./pages/Notifications/Notifications";
import { ToastContainer } from "react-toastify";
import 'react-toastify/dist/ReactToastify.css';
import SingleCategory from "./pages/Category/SingleCategory";
import CreatePost from "./pages/Home/CreatePost";
import SuggestionPeople from "./pages/SuggestionPeople/SuggestionPeople";
import SearchPeople from "./pages/Search/UserSearch";
import EditPost from "./pages/Home/EditPost";
import SinglePost from "./pages/Home/SinglePost";
import { useEffect } from "react";
import SinglePlay from "./pages/Plays/SinglePlay";
import ProfileShare from "./pages/Profile/ProfileShare";
import Termsofuse from "./pages/TermsAndConditions/Termsofuse/Termsofuse";
import PrivacyPolicy from "./pages/TermsAndConditions/Privacypollicy/PrivacyPolicy";
import Contentpolicy from "./pages/TermsAndConditions/Contentpolicy/Contentpolicy";
import Aboutus from "./pages/TermsAndConditions/Aboutus/Aboutus";
import { Amplify } from 'aws-amplify';
import awsExports from './amplifyconfiguration.json';
import ReturnPolicy from "./pages/TermsAndConditions/ReturnPolicy/ReturnPolicy";
Amplify.configure({
  Auth: {
    identityPoolId: 'ap-south-1:d4f2adeb-0668-4817-8218-f583501a44f4', //REQUIRED - Amazon Cognito Identity Pool ID
    region: 'ap-south-1', // REQUIRED - Amazon Cognito Region
    userPoolId: 'ap-south-1_Ikp75X2Au', //OPTIONAL - Amazon Cognito User Pool ID
    userPoolWebClientId: '1ovr8aph6edahr0s5t56ljbo2l', //OPTIONAL - Amazon Cognito Web Client ID
  },
  Storage: {
    AWSS3: {
      bucket: 'pixalive-textbucket163241-dev', //REQUIRED -  Amazon S3 bucket name
      region: 'ap-south-1', //OPTIONAL -  Amazon service region
      isObjectLockEnabled: true //OPTIONAl - Object Lock parameter
    }
  }
});
const routes = (
  <Router>    
    <Routes>
      <Route path='/Home' element={<Home/>} />
      <Route path='/' element={<HomeLogin/>} />
      <Route path='/Plays' element={<Plays/>} />
      <Route path='/Category' element={<Category/>} />
      <Route path='/Profile' element={<Profile/>} />
      <Route path='/Birthday' element={<Birthday/>} />
      <Route path='/Stories' element={<Stories/>} />
      <Route path='/TrendingPeople' element={<TrendingPeople/>} />
      <Route path='/TrendingVideo' element={<TrendingVideo/>} />
      <Route path='/Notifications' element={<Notifications/>} />
      <Route path='/CreatePost' element={<CreatePost/>} />
      <Route path='/SingleCategory' element={<SingleCategory/>} />
      <Route path='/SuggestionPeople' element={<SuggestionPeople/>} />
      <Route path='/SearchPeople' element={<SearchPeople/>} />
      <Route path='/EditPost' element={<EditPost/>} />
      <Route path='/singlepost' element={<SinglePost/>} />
      <Route path='/singleplay' element={<SinglePlay/>} />
      <Route path='/profileshare' element={<ProfileShare/>} />
      <Route path='/termsandconditions' element={<Termsofuse/>} />
      <Route path='/coinpolicy' element={<PrivacyPolicy/>} />
      <Route path='/privacypolicy' element={<Contentpolicy/>} />
      <Route path='/aboutus' element={<Aboutus/>} />
      <Route path='/returnpolicy' element={<ReturnPolicy/>} />

    </Routes>
 </Router> )
function App() {

  return (
    
<div className="main-content-wrapper d-flex flex-column">
        {routes}  
        <ToastContainer/> 
    </div>
  );
}

export default App;
