
import { Link } from 'react-router-dom';
import Header from '../../components/Header';
import SideBar from '../../components/SideBar';
import { useEffect, useState } from 'react';
import { getAllUsersForSearch } from '../../api/user';
import { s3Url } from '../../utils/FileUpload';


function SearchPeople() {
    const [user, setUser] = useState([])
    const [search, setSearch] = useState([])

    useEffect(() => {
        getAllUsers()
    }, [])

    
    const getAllUsers = () => {
        getAllUsersForSearch().then(res => {
            setUser(res?.data?.result)
            setSearch(res?.data?.result)
        }).catch(err => { console.log(err); })
    }

    const handleChildValueChange = (newValue) => {
        var value = newValue?.toLowerCase();
        if (value) {
            var searchData = search?.filter((x) => {
                return x?.userName.toLowerCase().indexOf(value) !== -1 || x?.userId.toLowerCase().indexOf(value) !== -1 
            })
            setUser(searchData)
        }
        else {
            setUser(search)
        }
    }

    return (
        <>

            <Header onChildValueChange={handleChildValueChange} />
            <div className="content-page-box-area">
                <div className="row">
                    <SideBar />
                    <div className="col-lg-9 col-md-12">
                        <div className="content-page-box-area">
                            <div className="row justify-content-center">
                                {user?.map((data, index) =>
                                    <div className="col-lg-4 col-sm-6" key={index}>
                                        <div className="single-friends-card">
                                            <div className="friends-content">
                                                <div className="friends-info">
                                                    <Link to={{ pathname: '/Profile', search: `id=${data?._id}` }} >
                                                        <img src={s3Url+data?.key} alt="suggestionUser" />
                                                    </Link>
                                                    <div className="text ms-3">
                                                        <h3><Link to={{ pathname: '/Profile', search: `id=${data?._id}` }} >{data?.userName}</Link></h3>
                                                        <span>{data?.userId}</span>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>)}
                                  {user?.length===0?  <div className='form-text text-danger text-center mt-5'>User not found. Please check the username or user ID and try again.</div> : null}
                            </div>
                        </div>
                    </div>
                </div>
            </div>

        </>
    );
}
export default SearchPeople;