import React from 'react';
import './Contentpolicy.css'

const Contentpolicy = () => {
    return (<>
        <div className='Contentpolicy_container'>
            <h3 className='Contentpolicy_container_h3'>PIXALIVE PRIVACY POLICY</h3>

            <br></br>INTRODUCTION

            <br></br><br></br>We at Tripalive.Me Technology Private Limited are committed to safeguarding the privacy of our website and/or mobile application visitors; this policy sets out how we will treat your personal information.

            <br></br><br></br>A. WHAT DO WE COLLECT?
            <br></br><br></br>We may collect, store, and use the following kinds of personal data upon user consent:
            <br></br><br></br>Registration and Profile Information:
            <br></br><br></br>When you sign up for an account or use our services, we will ask you for the following information:
            <br></br><br></br>a)	Profile Photo
            <br></br><br></br>b)	Your Name
            <br></br><br></br>c)	Username
            <br></br><br></br>d)	Mobile Number
            <br></br><br></br>e)	Email ID
            <br></br><br></br>f)	DOB
            <br></br><br></br>g)	Gender
            <br></br><br></br>h)	Any other relevant information as required from time to time collected upon user consent.
            Sensitive information is stored in encrypted format.

            <br></br><br></br>Communications:
            <br></br><br></br>If you contact us directly, we may receive additional information about you. For example, when you contact us for support, we will receive your name, email address, phone number and contents of your support issue along with any other information you chose to provide.

            <br></br><br></br>Device Information:
            <br></br><br></br>We receive information about the device and software you use to access our Services, including IP address, type of Web Browser, Operating System Version, Phone Carrier and Manufacturer, application installations, device identifiers and push notification tokens.



            <br></br><br></br>Usage Information:

            <br></br><br></br>To help us understand how you use our Services and to help us improve them, we automatically receive information about your interactions with them, like the pages or other content you view. The information you upload, the length of time you spend on a page, objects such as hyperlinks you click on, and the dates and times of your visits.

            <br></br><br></br>Contacts Information:
            <br></br><br></br>The users' contact information is collected only for the following purpose - to enable users to communicate and share content with their friends (who are in contact only). Our app functions in a way to build friends network, who are in the contacts.
            <br></br><br></br>This will provide a good user experience for the user, where they are easily able to connect with their contacts. The above data is collected only after every individual user's approval. Without the user's consent, our app won't collect any contact data from the user.

            <br></br><br></br>Post Upload:
            <br></br><br></br>When you use our services, you trust us with your information to post photos, videos, and locations. While uploading the post, we are collecting post related images, videos, and locations. Users can change access to private or public posts.

            <br></br><br></br>B. HOW DO WE USE YOUR PERSONAL DATA?

            <br></br><br></br>Personal Data submitted on our Platform will be used for the purposes specified in this Privacy Policy or in relevant parts of our Website/ Mobile Application.

            <br></br><br></br>We may use your Personal Information:
            <br></br><br></br>•	To provide, maintain, improve, and enhance our Services;
            <br></br><br></br>•	To understand and analyse how you use our Services and develop new products, services, features, and functionality;
            <br></br><br></br>•	To communicate with you, provide you with updates and other information relating to our Services, provide information that you request, respond to comments and questions, and otherwise provide customer support;
            <br></br><br></br>•	To generate de-identified or aggregated data for our business purposes;
            <br></br><br></br>•	To send you to push notifications and text messages;
            <br></br><br></br>•	To find and prevent fraud, and respond to trust and safety issues that may arise;
            <br></br><br></br>•	For compliance purposes, including enforcing our Terms of Service or other legal rights, as may be required by applicable laws and regulations, or as requested by any judicial process or governmental agency; and
            <br></br><br></br>•	For other purposes for which we provide specific notice at the time the information is collected.

            <br></br><br></br>C. HOW DO WE SHARE THE INFORMATION WE COLLECT?

            <br></br><br></br>We do not share or otherwise disclose the information we collect from or about you except as described below or otherwise disclosed to you at the time of the collection:

            <br></br><br></br>User Content:

            <br></br><br></br>Our services are social networking services in which you can view and share content. Your name, user name and other profile information will be viewable by other users. The content you share on the services will be displayed on the services and viewable by other users to whom you have given access to view such content. Our platform will not be responsible for the other users' usage of your content, so you should carefully consider whether and what to share and how you identify yourself on the platform.
            Pixalive is a social network app, where users can post and share their text, images, and videos with their friends & followers through the platform's content feed. Our app enables the user to build their friends & followers' networks and make them view others' content & share the user's own content (text, images & videos), only after their consent and approval. In this way, the app functions as a social networking platform for the end-users. Without the user's consent, our app will not collect any content data from the users. This content data will be stored with us, and we assure you that we will not share this data with any other 3rd parties.

            <br></br><br></br>Analytics Partners:

            <br></br><br></br>We may use analytics services such as Google Analytics to collect and process certain analytics data. These services may also collect information about your use of other websites, apps, and online resources.
            <br></br><br></br>As required by Law and Similar Disclosures-
            <br></br><br></br>We may access, preserve, and disclose your information if we believe doing so is required or appropriate to:
            <br></br><br></br>I.	Comply with law enforcement requests and legal processes, such as a Court Order or subpoena;
            <br></br><br></br>II.	Respond to your requests; or
            <br></br><br></br>III.	Protect your, our, or others’ rights, property, or safety.

            <br></br><br></br>or the avoidance of doubt, the disclosure of your information may occur if you send any objectionable content on or through our services.


            <br></br><br></br>Merger, Sale, or Other Asset Transfer
            <br></br><br></br>We may transfer your information to service providers, advisors, potential transactional partners, or other third parties in connection with the consideration, negotiation, or completion of a corporate transaction in which we are acquired by or merged with another company or we sell, liquidate, or transfer all or a portion of our assets.

            <br></br><br></br>Consent:
            <br></br><br></br>We may also disclose your information with your permission.

            <br></br><br></br>D. WHERE WE STORE YOUR PERSONAL INFORMATION
            <br></br><br></br>We store your data with Amazon Web Services, on its servers located in India and abroad. The Web Service implement the requisite Security measures to protect the loss, misuse and alteration of the information, details of which are available at Amazon Web Services.

            <br></br><br></br>E. YOUR CHOICES

            <br></br><br></br>Sharing Preferences
            <br></br><br></br>We provide you with settings to allow you to set your sharing preferences for content you share on our services. Certain information may always be publicly available to others, and other information is publicly viewable, you can adjust the settings in your account.

            <br></br><br></br>Additional Information:
            <br></br><br></br>Additional information regarding privacy settings can be found here: support@pixalive.me

            <br></br><br></br>Do Not Track:
            <br></br><br></br>There is no accepted standard on how to respond to Do Not Track signals, and we do not respond to such signals. If you choose not to provide us with the information we collect, some features of our services may not work as intended.

            <br></br><br></br>F. THIRD PARTIES

            <br></br><br></br>Our Services may contain links to other websites, apps, products, or services that we do not own or operate. We are not responsible for the privacy practices of these third parties. We encourage you to read their privacy policies before providing any information to them.

            <br></br><br></br>G. SECURITY

            <br></br><br></br>We make reasonable efforts to protect your information by using physical and electronic safeguards designed to improve the security of the information we maintain. However, as no electronic transmission or storage of information can be entirely secure, we can make no guarantees as to the security or privacy of your information.

            <br></br><br></br>H. CHILDREN’S PRIVACY

            <br></br><br></br>We do not knowingly collect, maintain, or use personal information from children under 13 years of age, and no part of our Services are directed to children. If you learn that a child has provided us with personal information in violation of this Privacy Policy, you may alert us at support@pixalive.me

            <br></br><br></br>I. UPDATE YOUR INFORMATION OR POSE A QUESTION

            <br></br><br></br>You can update your account and profile information through your profile settings. If you have questions about your privacy on our Services or this privacy policy, please contact us at support@pixalive.me

            <br></br><br></br>J. CHANGES TO THIS PRIVACY POLICY

            <br></br><br></br>We will post any adjustments to the Privacy Policy on this page, and the revised version will be effective when it is posted. If we materially change the ways in which we use or share personal information previously collected from you through our Services, we will attempt to notify you through our Services, by email, or other means.

            <br></br><br></br>K. GRIEVANCE POLICY

            <br></br><br></br>We at Pixalive, always thrive to deliver the best values to our users and give the best experience under all circumstances. To offer this seamlessly, we have created this Grievance policy to solve all our customer's grievance-related problems in a very efficient way. In some rare circumstances, due to random human-related or technical errors, chances are there for issues to happen for our users. To address this kind of issue, our grievance policy will be surely helpful for our end users.

            <br></br><br></br>If the user still wants to communicate with us, they can reach us via help@Pixalive.me where our executives will communicate and clarify the user's queries and provide the right solution via chat/call.

        </div></>
    )
}

export default Contentpolicy
